import React, { useEffect } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Route, Routes, useParams } from 'react-router-dom';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import ProcureOverview from 'src/components/project/procure/Overview';
import ProjectProcureWorkflow from 'src/components/project/procure/ProjectProcureWorkflow';
import SingleProjectTds from 'src/components/project/procure/SingleProjectTds';
import ComplianceStep from 'src/pages/project/components/buyer-testing-setup/ComplianceStep';
import NotFound from 'src/components/error/NotFound';
import ProjectContractTermsOverview from 'src/pages/project/ProjectContractTermsOverview';
import ContractTermsSupplier from 'src/pages/project/components/buyer-testing-setup/ContractTermsSupplier';
import ProjectTdsTestOverview from 'src/pages/project/ProjectTdsTestOverview';
import TdsTestSupplier from 'src/pages/project/components/buyer-testing-setup/TdsTestSupplier';

export default function ProcureRoutes() {
  return (
    <Routes>
      <Route path="/:projectId/*" element={<ProcureSubroutes />} />
    </Routes>
  );
}

function ProcureSubroutes() {
  const { projectId } = useParams();
  const {
    getProjectbyId,
    setActiveProjectId,
    activeProject,
    isLoadingProjects
  } = useSidebarContext();

  const project = getProjectbyId(projectId);

  useEffect(() => {
    setActiveProjectId(projectId);
  }, [projectId]);

  if (isLoadingProjects) return <Skeleton active />;

  if (!project || project.uuid !== projectId || !activeProject)
    return <NotFound title="Project not found" />;

  return (
    <div className="project-source">
      <Row wrap={false} className="individually-scrolling-cols">
        <Col className="project-source--sidebar">
          <ProjectProcureWorkflow projectId={projectId} />
        </Col>
        <Col className="project-source--content">
          <Routes>
            <Route path="/" element={<ProcureOverview />} />
            <Route
              path="/testing"
              exact
              element={<ProjectTdsTestOverview project={project} />}
            />
            <Route
              path="/testing/:projectTdsId"
              element={<TdsTestSupplier project={project} />}
            />
            <Route
              path="/compliance"
              element={<ComplianceStep project={project} />}
            />
            <Route
              path="/contract-terms/:projectTdsId"
              element={<ContractTermsSupplier project={project} />}
            />
            <Route
              path="/contract-terms"
              exact
              element={<ProjectContractTermsOverview project={project} />}
            />
            <Route
              path="/:projectTdsId"
              element={<SingleProjectTds project={project} />}
            />
          </Routes>
        </Col>
      </Row>
    </div>
  );
}
