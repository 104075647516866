import React, { useMemo, useState } from 'react';
import { Button, Form, Modal, Space } from 'antd';
import IndexSelect from 'src/components/form/IndexSelect';

const { useForm } = Form;

export function ColoredCircle({ color = 'blue', solid = true, size }) {
  const circleStyle = {
    width: size,
    height: size,
    borderRadius: '50%',
    border: `2px solid ${color}`
  };

  if (solid) {
    circleStyle.backgroundColor = color;
  } else {
    circleStyle.backgroundColor = 'transparent';
  }

  return <div style={circleStyle} />;
}

export default function useLineChartSettings(
  lineSpecs,
  filters,
  indices,
  priceSettings,
  onChange,
  exclude
) {
  const [form] = useForm();
  const [open, setOpen] = useState(false);

  const filteredLineSpec = useMemo(
    () => lineSpecs.filter((o) => !(exclude || []).includes(o.type)),
    [lineSpecs, exclude]
  );
  const filtersPerLineSpecs = useMemo(
    () =>
      filteredLineSpec.map((lineSpec) => ({
        ...lineSpec.filters,
        type_code__in: filters?.type_code__in
      })),
    [filteredLineSpec, filters]
  );

  const submit = (values) => {
    onChange(values);
    setOpen(false);
  };

  const ModalComponent = indices !== undefined && (
    <Modal
      className="line-chart-settings-modal"
      title="Chart Settings"
      width={600}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Form
        name="chart-settings"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={submit}
        autoComplete="off"
        form={form}
      >
        {filteredLineSpec.map((lineSpec, index) => (
          <Form.Item
            key={lineSpec.type}
            label={
              <>
                <ColoredCircle
                  size={6}
                  color={lineSpec.color}
                  solid={lineSpec.solid}
                />
                &nbsp;{lineSpec.name}
              </>
            }
            name={lineSpec.type}
            initialValue={
              priceSettings?.[lineSpec.type]?.ticker_id ||
              indices[lineSpec.type]
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <IndexSelect
              allowClear
              warehouse
              filters={filtersPerLineSpecs[index]}
            />
          </Form.Item>
        ))}
        <Space direction="horizontal">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button type="default" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );

  return {
    ModalComponent,
    openModal: () => setOpen(true)
  };
}
