import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Col,
  Divider,
  Empty,
  Flex,
  Row,
  Skeleton,
  Typography
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import emptyExplore from 'src/images/exploreEmpty.png';
import { useUser } from 'src/utils/authentication';
import { useTdsCohorts } from 'src/Query/useGetTds';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import PeriodSelect from 'src/components/form/PeriodSelect';
import MarketPriceByFormLineChart from 'src/components/analytics/MarketPriceByFormLineChart';
import ExploreSection from 'src/components/project/explore/ExploreSection';
import PriceInsight from 'src/components/supplier/PriceInsight';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { getTdsDetail } from 'src/Mutation';
import { LeftOutlined } from '@ant-design/icons';
import Spacer from 'src/components/common/Spacer';
import { useManageTdsModal } from 'src/pages/supplier/useManageTds';
import { useLogPricingModal } from 'src/components/supplier/LogPricingModal';
import HeatmapFromFilters from 'src/components/viz/HeatmapFromFilters';
import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import { convertTdsToFilters } from 'src/utils/filters';
import { parseJson } from 'src/utils/json';
import CohortSummaryRow from './CohortSummaryRow';
import { getLatestPriceLog, getTdsName } from './utils';
import CohortAveragePrice from './CohortAveragePrice';

export default function SupplierInsights({ tdsId, locationId }) {
  const [_, setExploreCtx] = useConciergeContextState(['explore']);
  const { data: user } = useUser();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const paramFilters = parseJson(params.get('filters'));

  const [months, setMonths] = useState(6);

  const { data: tds, isLoading: loadingTDSs } = useQuery(
    ['api', 'technical-data-sheet', tdsId],
    () => getTdsDetail(tdsId),
    { enabled: !!tdsId }
  );
  const { data: tdsCohorts, isLoading: loadingCohort } = useTdsCohorts(
    tds?.uuid,
    locationId
  );

  const { ManageTdsModal, openFunc: openManageTdsModal } = useManageTdsModal({
    additionalTdsParams: {
      send_email: true
    },
    tdsId
  });
  const { ModalComponent: LogPricingModal, openModal } =
    useLogPricingModal(locationId);

  const tdsFilters = useMemo(() => {
    if (!tds) return paramFilters;
    const filterFromTds = convertTdsToFilters(tds);
    return {
      ...filterFromTds,
      ...(paramFilters ?? {})
    };
  }, [tds, paramFilters]);

  const projectTdsFilters = {
    form_code__in: tdsFilters.form_code__in,
    type_code__in: tdsFilters.type_code__in,
    region_code__in: tdsFilters.region_code__in,
    all: 'true'
  };

  const cohort = tdsCohorts?.[0] ?? {};

  const { data: projectTdss } = useGetWarehouseTdss(
    {
      ...projectTdsFilters,
      all: 'true'
    },
    {}
  );

  const supplierCount = useMemo(() => {
    if (!projectTdss) return 0;
    const supplierIds = new Set(projectTdss.map((rTds) => rTds.company_id));
    return supplierIds.size;
  }, [projectTdss]);

  useEffect(() => {
    if (!tds) return;

    const kpis =
      tds.material_numerical_properties.reduce((kpisObj, kpi) => {
        const {
          property: { code, unit_type }
        } = kpi;

        return {
          ...kpisObj,
          [code]: {
            max: kpi.max,
            min: kpi.min,
            units: kpi.units,
            unit_type,
            isToggledOff: true // We don't want to filter TDSs based on the selected TDS' KPI ranges
          }
        };
      }, {}) || {};

    setExploreCtx((prevState) => ({
      ...prevState,
      filters: {
        ...(prevState.filters || {}),
        kpis
      },
      insights: {
        company: tds.company
      }
    }));
  }, [tds]);

  const handleClickViewTds = () => {
    const documents = tds?.documents ?? [];
    const validDocs = documents.filter((document) => !!document.url);
    if (validDocs.length) {
      window.open(validDocs[0].url, '_blank');
    }
  };

  const isLoading = loadingTDSs || loadingCohort;

  if (!user) return <ErrorSupplierInsights />;

  if (isLoading) return <Skeleton />;

  if (!tds) return <EmptySupplierInsights />;

  const locationPriceLog = getLatestPriceLog(
    tds?.locations?.[0]?.price_logs ?? []
  );

  return (
    <div className="supplier-page">
      {ManageTdsModal}
      {LogPricingModal}
      <Flex className="supplier-insights" vertical>
        <Flex align="center" justify="space-between">
          <Flex align="center">
            <LeftOutlined onClick={() => navigate('')} />
            <Typography.Title level={4} className="ml">
              {getTdsName(tds)}
            </Typography.Title>
          </Flex>
          <Flex align="center" className="gap-xs">
            <Button onClick={openManageTdsModal}>Edit SKU Details</Button>
            <Button
              disabled={!tds?.documents?.length}
              onClick={handleClickViewTds}
            >
              View TDS
            </Button>
            <Button onClick={openModal} type="primary">
              Add Pricing Change
            </Button>
          </Flex>
        </Flex>
        <CohortSummaryRow cohort={cohort} />
        <Divider className="mt" />
        <ExploreSection className="pricing-section mb-xl" title="Pricing">
          <Row gutter={16}>
            <Col span={9}>
              <Flex style={{ flexDirection: 'column' }} gap={19}>
                <PriceInsight
                  priceLog={locationPriceLog}
                  onClickViewHistory={openModal}
                />
                <CohortAveragePrice filters={tdsFilters} />
              </Flex>
            </Col>
            <Col span={15}>
              <ExplorePanel className="price-history-chart__panel" pad>
                <Typography.Title level={4} style={{ margin: '0 0 24px 0' }}>
                  Material Pricing History
                </Typography.Title>
                <PeriodSelect value={months} onChange={(v) => setMonths(v)} />
                <MarketPriceByFormLineChart
                  title="Material Price in Region"
                  months={months}
                  filters={tdsFilters}
                  priceSettings={
                    locationPriceLog?.price
                      ? {}
                      : {
                          cohort: undefined
                        }
                  }
                />
              </ExplorePanel>
            </Col>
          </Row>
        </ExploreSection>
        <Spacer size={48} />
        <ExploreSection
          title="Specifications"
          subTitle={`Showing ${
            projectTdss?.length ?? 0
          } Records from ${supplierCount} Suppliers`}
        >
          <HeatmapFromFilters defaultFilters={tdsFilters} />
        </ExploreSection>
      </Flex>
    </div>
  );
}

function EmptySupplierInsights() {
  return (
    <Empty image={emptyExplore} description="">
      <Typography.Title level={2}>Select a SKU/TDS</Typography.Title>
      <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
        View and compare material pricing data against other suppliers with
        similar material offerings
      </Typography.Paragraph>
    </Empty>
  );
}

function ErrorSupplierInsights() {
  return (
    <Empty image={emptyExplore} description="">
      <Typography.Title level={2}>Additional setup needed</Typography.Title>
      <Typography.Paragraph style={{ maxWidth: 354, margin: '0 auto' }}>
        Please chat to us to get properly setup to see this page
      </Typography.Paragraph>
    </Empty>
  );
}
