import { paramCase } from 'change-case';
import numeral from 'numeral';
// eslint-disable-next-line import/no-unresolved

const BIG_INT = 1_000_000_000_000;

export const compareProperties = (property1, property2) => {
  if (property1 && !property2) return -1;
  if (!property1 && property2) return 1;
  if (!property1 && !property2) return 0;
  if (property1.order && !property2.order) return -1;
  if (!property1.order && property2.order) return 1;
  if (!property1.order && !property2.order) {
    if (property1.name > property2.name) return 1;
    if (property1.name < property2.name) return -1;
    return 0;
  }
  return property1.order - property2.order;
};

export const normalizeNumericalProperty = (property) => ({
  uuid: property?.uuid,
  name: property?.property?.name,
  min: numeral(property.min).value()?.toString(),
  max: numeral(property.max).value()?.toString(),
  property_id: property?.property?.uuid,
  unit_type: property?.property?.unit_type,
  order: property?.property?.order,
  units: property?.units
});

export const normalizeNumericalProperties = (material_numerical_properties) => {
  const result = {};
  result.existing = material_numerical_properties.map(
    normalizeNumericalProperty
  );
  result.unused = [];
  return result;
};

export const normalizeProperties = (material_properties) => {
  const result = {};
  material_properties.forEach((o) => {
    // if there are multiple options set for the same property, then
    // we store them in an array, otherwise we store them as a primitive value
    // the select field can handle either and will adapt to multiple select if
    // passed an array as its value
    if (Array.isArray(result[paramCase(o.meta_property.code)])) {
      result[paramCase(o.meta_property.code)].push(o.uuid);
    } else if (result[paramCase(o.meta_property.code)]) {
      result[paramCase(o.meta_property.code)] = [
        result[paramCase(o.meta_property.code)],
        o.uuid
      ];
    } else {
      result[paramCase(o.meta_property.code)] = o.uuid;
    }
  });
  return result;
};
