import { useQuery } from '@tanstack/react-query';
import { getProjectShipments } from './shipments';

export default function useGetProjectShipment(projectId) {
  return useQuery(
    ['project/shipment', projectId],
    () => getProjectShipments(projectId),
    { enabled: !!projectId }
  );
}
