import React from 'react';

const addressParts = [
  'streetAddress',
  'street_one',
  'street_two',
  'city',
  'zip',
  'state',
  'country'
];

export const formatAddressString = (address) =>
  (address &&
    addressParts
      .map((key) => address[key])
      .filter((o) => !!o)
      .join(', ')) ||
  undefined;

export const formatAddressMultiline = (address) =>
  (address &&
    addressParts
      .map((key) => address[key])
      .filter((o) => !!o)
      .map((label, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>
          {label}
          <br />
        </React.Fragment>
      ))) ||
  undefined;
