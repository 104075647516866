import { useQuery } from '@tanstack/react-query';
import { getNumericalProperties } from 'src/Query';

// it is important to make this a constant or otherwise the hook returns a new instance of an empty array
// on every call
const DEFAULT_LOADING = [];

function useMetaNumericalProperty(codeOrUuid) {
  const { data: metaProperties = DEFAULT_LOADING } = useQuery({
    queryKey: ['numerical-properties'],
    queryFn: () => getNumericalProperties()
  });

  if (!codeOrUuid) return metaProperties || [];

  const property = metaProperties?.find(({ code, uuid }) =>
    [code, uuid].includes(codeOrUuid)
  );
  return property;
}

function useMetaNumericalPropertyLoading() {
  const { isLoading } = useQuery({
    queryKey: ['numerical-properties'],
    queryFn: () => getNumericalProperties()
  });
  return isLoading;
}

export default useMetaNumericalProperty;
export { useMetaNumericalPropertyLoading };
