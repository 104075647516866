import React from 'react';
import Sorter from './Sorter';

const EMPTY_OBJECT = {};

function isSortedColumn(dataIndex, sortColumn) {
  if (!sortColumn) return false;

  if (Array.isArray(dataIndex)) {
    if (!Array.isArray(sortColumn.dataIndex)) return false;

    return sortColumn.dataIndex.every(
      (dataIndexKey, i) => dataIndexKey === dataIndex[i]
    );
  }

  return dataIndex === sortColumn.dataIndex || dataIndex === sortColumn.sortKey;
}

export const sortableColumnTitle = (
  title,
  sortKey,
  spanProps = EMPTY_OBJECT
) => {
  function SortableColumnTitle({ sortOrder, sortColumn }) {
    return (
      <span {...spanProps}>
        {title}&nbsp;
        <Sorter
          sortOrder={
            isSortedColumn(sortKey, sortColumn) ? sortOrder : undefined
          }
        />
      </span>
    );
  }

  return SortableColumnTitle;
};
