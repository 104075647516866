import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Alert,
  Badge,
  Button,
  Col,
  Divider,
  Flex,
  message,
  Row,
  Select,
  Skeleton,
  Typography
} from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { colord } from 'colord';
import dayjs from 'dayjs';
import {
  getMarketPriceSeriesOld,
  getMarketPriceTickersWarehouseAnalytics,
  useMultipleMarketPriceSeries
} from 'src/Query/marketPrice';
import AnalyticsTickerLineChart, {
  priceMid
} from 'src/components/analytics/AnalyticsTickerLineChart';
import { ReactComponent as EmptyState } from 'src/components/icons/svg/EmptyState.svg';
import debounce from 'src/utils/debounce';
import { useTimeScaleControl } from 'src/pages/insights/market';
import { lineSpecs } from 'src/components/analytics/utils';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { currencyValuePrice } from 'src/utils/currency';
import AdvancedInsightsIcon from 'src/components/icons/AdvancedInsightsIcon';
import falsyNot0 from 'src/components/utils/falsyNot0';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import { updateProject } from 'src/Mutation';
import { captureException } from '@sentry/react';

/* eslint-disable react/no-unstable-nested-components */
export default function MarketsPlayground() {
  const [filters] = useConciergeContextState(['explore', 'filters']);

  const [tickersByType, setTickersByType] = useState(
    Array(lineSpecs.length).fill([])
  );

  const [higlightedTickerIndex, setHiglightedTickerIndex] = useState(null);

  const { scaleConfig, TimeScaleControl } = useTimeScaleControl();

  const flattenedTickers = useMemo(() => tickersByType.flat(), [tickersByType]);

  const { isLoading, seriesList } = useMultipleMarketPriceSeries(
    flattenedTickers.map((line, i) => ({
      ...line,
      ...scaleConfig
    })),
    true
  );
  const highlightedSeries = useMemo(
    () => seriesList[higlightedTickerIndex],
    [seriesList, higlightedTickerIndex]
  );

  const lineSpecsWithData = useMemo(() => {
    const seriesClone = [...seriesList];
    return lineSpecs.map((lineSpec, i) => {
      let isActiveGroup = false;
      const seriesInThisGroup = tickersByType[i].map(() => {
        const series = seriesClone.shift();
        if (series === highlightedSeries) isActiveGroup = true;
        return series;
      });
      return {
        ...lineSpec,
        series: seriesInThisGroup,
        isActiveGroup
      };
    });
  }, [seriesList, tickersByType]);

  useEffect(() => {
    if (!highlightedSeries && seriesList.filter((series) => !!series).length)
      setHiglightedTickerIndex(0);
  }, [seriesList]);

  return (
    <div className="markets-playground mt-md">
      <div className="markets-playground--header">
        {lineSpecs
          .filter(({ type }) => type !== 'tds')
          .map((line, i) => (
            <div key={i}>
              <EditableLine
                filters={{
                  ...line.filters,
                  type_code__in: line.relevantFilters?.includes('type_code__in')
                    ? filters?.type_code__in
                    : undefined,
                  region_code__in: line.relevantFilters?.includes(
                    'region_code__in'
                  )
                    ? filters?.region_code__in
                    : undefined,
                  grade_code__in: line.relevantFilters?.includes(
                    'grade_code__in'
                  )
                    ? filters?.properties?.grade
                    : undefined,
                  country_code__in: line.relevantFilters?.includes(
                    'country_code__in'
                  )
                    ? filters?.country_code__in
                    : undefined,
                  color_code__in: line.relevantFilters?.includes(
                    'color_code__in'
                  )
                    ? filters?.properties?.color
                    : undefined
                }}
                title={line.name}
                color={line.color}
                dashed={!line.solid}
                useInAverage={line.useInAverage}
                onChange={(lineSettings) =>
                  setTickersByType((oldLines) => {
                    const newLines = [...oldLines];
                    newLines[i] = lineSettings;
                    return newLines;
                  })
                }
              />
            </div>
          ))}
      </div>
      <Row className="markets-playground--body">
        <Col span={7}>
          <Select
            style={{ width: '100%' }}
            options={tickersByType
              .map((tickersGroup, i) => ({
                label: lineSpecs[i].name,
                options: tickersGroup.map((ticker) => ({
                  value: flattenedTickers.findIndex(
                    ({ uuid__in }) => uuid__in === ticker.uuid__in
                  ),
                  label: <Badge color={ticker.color} text={ticker.title} />
                }))
              }))
              .filter(({ options }) => options.length > 0)}
            value={higlightedTickerIndex}
            onChange={setHiglightedTickerIndex}
          />
          {isLoading ? (
            <Skeleton />
          ) : (
            <HighlightedSeriesInfo
              highlightedSeries={highlightedSeries}
              lineSpecsWithData={lineSpecsWithData}
            />
          )}
        </Col>
        <Col span={17}>
          {flattenedTickers?.length ? (
            <>
              <Row justify="space-between">
                <Typography.Text type="secondary">
                  Material Price in Region
                </Typography.Text>
                {TimeScaleControl}
              </Row>
              <AnalyticsTickerLineChart
                tickers={flattenedTickers.map((line, i) => ({
                  ...line,
                  ...scaleConfig,
                  borderWidth: i === higlightedTickerIndex ? 3 : 1
                }))}
                colorSeed={0}
                highlightIndex={higlightedTickerIndex}
                allowDownload={false}
                old
              />
            </>
          ) : (
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ margin: 40 }}
            >
              <EmptyState style={{ height: 85 }} />
              <Typography.Text className="heavy mb-sm">
                Choose an index to get started
              </Typography.Text>
              <Typography.Text
                type="secondary"
                className="mb-sm"
                style={{ maxWidth: 250, textAlign: 'center' }}
              >
                Select one or more indices to view market information.
              </Typography.Text>
            </Flex>
          )}
        </Col>
      </Row>
    </div>
  );
}

function EditableLine({
  onChange,
  filters,
  title: initialTitle,
  color,
  dashed,
  useInAverage
}) {
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [title, setTitle] = useState(initialTitle || 'Line title');
  const [search, setSearch] = useState('');

  const params = {
    ...filters,
    search,
    page_size: 50,
    order_by: 'source_name'
  };

  const { data: tickers, isLoading } = useQuery(['tickers', params], () =>
    getMarketPriceTickersWarehouseAnalytics(params)
  );

  useEffect(() => {
    onChange(
      selectedTickers?.map((singleTicker, i) => ({
        title: singleTicker.label,
        uuid__in: singleTicker.value,
        color: colord(color)
          .lighten(0.15 * Math.min(i, 2))
          .toHex(),
        dashed,
        useInAverage
      }))
    );
  }, [selectedTickers, title]);

  const canChangeTitle = !initialTitle;

  return (
    <div>
      <Badge
        color={color}
        text={
          <Typography.Text
            strong
            editable={
              canChangeTitle && {
                onChange: (value) => setTitle(value)
              }
            }
          >
            {initialTitle || title}
          </Typography.Text>
        }
      />
      <Select
        showSearch
        style={{ width: '100%' }}
        size="small"
        mode="multiple"
        onSearch={debounce(setSearch, 500)}
        filterOption={false}
        optionLabelProp="tag"
        maxTagCount="responsive"
        value={selectedTickers}
        labelInValue
        onChange={(value) => setSelectedTickers(value)}
        options={tickers?.results?.map((ticker) => ({
          value: ticker.market_price_ticker_id,
          label: `(${ticker.source.toUpperCase()} ${ticker.source_code}) ${
            ticker.source_name
          }`,
          tag: `${ticker.source.toUpperCase()} ${ticker.source_code?.toUpperCase()}`
        }))}
        suffixIcon={null}
        loading={isLoading}
        popupMatchSelectWidth={false}
      />
    </div>
  );
}
EditableLine.propTypes = {
  onChange: PropTypes.func
};

const latestSeriesValue = (series) =>
  priceMid(series.curve[series.curve.length - 1]);

function HighlightedSeriesInfo({ highlightedSeries, lineSpecsWithData }) {
  const highglightedLineSpec = useMemo(() =>
    lineSpecsWithData.find(({ isActiveGroup }) => isActiveGroup)
  );
  const latestValue = useMemo(
    () =>
      highlightedSeries
        ? priceMid(highlightedSeries.curve[highlightedSeries.curve.length - 1])
        : null,
    [highlightedSeries]
  );
  const higlightedTickerId = useMemo(
    () => highlightedSeries?.tickers?.[0]?.uuid,
    [highlightedSeries]
  );

  const monthlySeriesParams = useMemo(
    () => ({
      uuid__in: highlightedSeries?.tickers?.[0]?.uuid,
      start_date: dayjs().subtract(1, 'year').format('YYYY-MM-DD'), // 1 yr ago
      sample_units: 'days',
      sample_duration: 30
    }),
    [highlightedSeries]
  );
  const { data, isLoading, error } = useQuery(
    ['marketPriceSeries', monthlySeriesParams],
    () => getMarketPriceSeriesOld(monthlySeriesParams)
  );

  const monthlyChange = useMemo(() => {
    if (!data || data.curve.length < 2) return null;

    return latestValue - priceMid(data.curve[data.curve.length - 2]);
  }, [data]);
  const yearlyChange = useMemo(() => {
    if (!data || data.curve.length < 13) return null;

    return latestValue - priceMid(data.curve[0]);
  }, [data]);

  const activeSpecLatestValues = useMemo(
    () =>
      highglightedLineSpec?.series
        ? highglightedLineSpec.series.map(latestSeriesValue)
        : [],
    [highglightedLineSpec]
  );
  const specAvg = useMemo(
    () =>
      activeSpecLatestValues.reduce((sum, value) => sum + value, 0) /
      activeSpecLatestValues.length
  );
  const specMin = useMemo(() => Math.min(...activeSpecLatestValues));
  const specMax = useMemo(() => Math.max(...activeSpecLatestValues));

  const { activeProjectId, activeProject } = useSidebarContext();
  const [messageApi, contextHolder] = message.useMessage();
  const { mutate: updateProjectMutation, isLoading: updating } = useMutation({
    mutationFn: updateProject,
    // onSuccess: (response) => {
    //   queryClient.invalidateQueries(['project']);
    //   queryClient.invalidateQueries(['projects']);
    // },
    onError: (e) => {
      messageApi.error('Error updating project. Please try again.', 8);
      // Send error to Sentry
      captureException(e);
    }
  });

  const setProjectBenchmark = (benchmark) => {
    if (activeProject) {
      updateProjectMutation({
        uuid: activeProjectId,
        settings: {
          ...activeProject.settings,
          prices: {
            ...activeProject.settings,
            benchmark: {
              ticker_id: benchmark
            }
          }
        }
      });
    } else {
      messageApi.error('No active project');
    }
  };

  if (!highlightedSeries) return null;

  const { currency, units } = highlightedSeries;

  return (
    <div className="markets-playground--highlighted">
      {contextHolder}
      <Row justify="space-between" className="my-md" align="middle">
        <Col>
          <Typography.Paragraph className="font-size-7 my-0 poppins" strong>
            {currencyValuePrice(latestValue, currency)}{' '}
            <Typography.Text>/{units}</Typography.Text>
          </Typography.Paragraph>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => setProjectBenchmark(higlightedTickerId)}
          >
            Set benchmark
          </Button>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Row justify="space-between">
            <Typography.Text type="secondary" className="sm">
              Change mo/mo
            </Typography.Text>
            <Typography.Text className="sm" strong>
              {falsyNot0(monthlyChange)
                ? '--'
                : currencyValuePrice(monthlyChange, currency)}
            </Typography.Text>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="space-between">
            <Typography.Text type="secondary" className="sm">
              Change yr/yr
            </Typography.Text>
            <Typography.Text className="sm" strong>
              {falsyNot0(yearlyChange)
                ? '--'
                : currencyValuePrice(yearlyChange, currency)}
            </Typography.Text>
          </Row>
        </Col>
      </Row>
      {error ? (
        <Typography.Text className="sm" type="danger">
          Error fetching mo/mo and yr/yr change
        </Typography.Text>
      ) : null}
      <Divider className="my-md" />
      <Typography.Paragraph className="sm" strong>
        {highglightedLineSpec.name}
      </Typography.Paragraph>
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Typography.Text type="secondary" className="sm">
            Index Avg.
          </Typography.Text>
          <br />
          <Typography.Text className="sm" strong>
            {currencyValuePrice(specAvg, currency, units)}
          </Typography.Text>
        </Col>
        <Col span={8}>
          <Typography.Text type="secondary" className="sm">
            Lowest Index
          </Typography.Text>
          <br />
          <Typography.Text className="sm" strong>
            {currencyValuePrice(specMin, currency, units)}
          </Typography.Text>
        </Col>
        <Col span={8}>
          <Typography.Text type="secondary" className="sm">
            Highest Index
          </Typography.Text>
          <br />
          <Typography.Text className="sm" strong>
            {currencyValuePrice(specMax, currency, units)}
          </Typography.Text>
        </Col>
      </Row>
      {lineSpecsWithData.filter(
        (spec) => spec !== highglightedLineSpec && spec.series?.length
      ).length ? (
        <>
          <Typography.Paragraph className="sm mt-md" strong>
            Alternative Forms{' '}
            <Typography.Text type="secondary" className="sm">
              (highest values)
            </Typography.Text>
          </Typography.Paragraph>
          <Row gutter={[12, 12]}>
            {lineSpecsWithData
              .filter((spec) => spec !== highglightedLineSpec)
              .map((lineSpec) => {
                const latestValues = lineSpec.series.map(latestSeriesValue);
                const maxValue = Math.max(...latestValues);
                const ticker =
                  lineSpec.series[latestValues.indexOf(maxValue)]?.tickers[0];

                if (!ticker) return null;
                return (
                  <Col span={8} key={lineSpec.type}>
                    <Typography.Text className="sm" strong>
                      {currencyValuePrice(maxValue, currency)}
                    </Typography.Text>
                    <br />
                    <Typography.Text type="secondary" className="sm">
                      {lineSpec.name}
                    </Typography.Text>
                    <br />
                    <Typography.Text
                      type="secondary"
                      className="sm"
                      ellipsis={{
                        tooltip: `${ticker.source.toUpperCase()} ${ticker.source_code?.toUpperCase()}`
                      }}
                    >
                      {`${ticker.source.toUpperCase()} ${ticker.source_code?.toUpperCase()}`}
                    </Typography.Text>
                  </Col>
                );
              })}
          </Row>
        </>
      ) : null}
      <Alert
        className="mt-md p-xs"
        message={
          <Typography.Text strong>
            <AdvancedInsightsIcon /> Advanced Insights
          </Typography.Text>
        }
        description={
          <Typography.Text>
            Enable advanced insights like projections and custom cohorts.
            Contact sales for more details.
          </Typography.Text>
        }
      />
    </div>
  );
}
