import { Alert, Button, Col, Flex, Radio, Row, Spin, Typography } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import Logo from 'src/components/Logo';

import classNames from 'classnames';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getProject } from 'src/Query';
import { useUser } from 'src/utils/authentication';
import styles from './IntroducePage.module.less';

function IntroduceContainer() {
  const { projectId } = useParams();
  const {
    data: project,
    error,
    isLoading
  } = useQuery(['project', projectId], () => getProject(projectId), {
    enabled: !!projectId
  });
  const { data: user } = useUser();

  const navigate = useNavigate();

  const onClickStart = () => {
    navigate(`/digital-rfq/${projectId}/submit`);
  };

  if (error)
    return (
      <Alert
        type="error"
        description="Error fetching project"
        action={
          <Link to="https://circular.co">
            <Button>Go to homepage</Button>
          </Link>
        }
      />
    );

  if (isLoading) return <Spin spinning />;

  return (
    <div>
      <Flex className={styles.header} align="center" justify="center">
        <Logo />
      </Flex>
      <div>
        <Row>
          <Col
            lg={{ span: 10, offset: 7 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            align="center"
            justify="center"
            className="mt-2xxl"
          >
            <Typography className="weight-600 font-size-3a">
              {user?.company?.name}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 10, offset: 7 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            align="center"
            justify="center"
          >
            <Typography.Title level={1} className="text-center my-lg">
              {project?.company} Requests Your Quote
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 10, offset: 7 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            align="center"
            justify="center"
          >
            <Typography className="font-size-3a text-center">
              Your organization has been identified as a potential supplier for
              this project. Please submit a quote{' '}
              {project.decision_date ? (
                <>
                  by{' '}
                  <b>{dayjs(project.decision_date).format('MMMM D, YYYY')}</b>
                </>
              ) : (
                'ASAP'
              )}{' '}
              in order to be considered. Chat live with our team if you have
              questions.
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 8, offset: 8 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            className={classNames(
              styles.checkboxCard,
              'border-radius w-full mt-42'
            )}
          >
            <Flex align="start" className="gap">
              <Radio className={styles.radio} />
              <div>
                <Typography className="weight-600 font-size-3a">
                  Quote Details
                </Typography>
                <Typography.Text type="secondary" className="font-size-3">
                  Confirm the quote details you would like to submit for this
                  Digital RFQ.
                </Typography.Text>
              </div>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 8, offset: 8 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            className={classNames(
              styles.checkboxCard,
              'border-radius w-full mt'
            )}
          >
            <Flex align="start" className="gap">
              <Radio className={styles.radio} />
              <div>
                <Typography className="weight-600 font-size-3a">
                  Supplier Info
                </Typography>
                <Typography.Text type="secondary" className="font-size-3">
                  Add or confirm the information we have for your organization.
                </Typography.Text>
              </div>
            </Flex>
          </Col>
        </Row>
        <Flex align="center" justify="center">
          <Button
            type="primary"
            className={classNames(styles.button, 'mt-42')}
            size="large"
            onClick={onClickStart}
          >
            Start Your Quote
          </Button>
        </Flex>
      </div>
    </div>
  );
}

export default IntroduceContainer;
