import { ConfigProvider, Flex } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import CircularLogo from 'src/components/icons/CircularLogo';
import Spacer from 'src/components/common/Spacer';
import styles from './SignUpLayout.module.less';

function SignInLayout({ children }) {
  return (
    <div className={styles.signinContainer}>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 6,
            controlHeight: 40
          },
          components: {
            Radio: {
              radioSize: 24,
              dotSize: 12
            },
            Form: {
              labelColor: '#FFFFFF'
            },
            Input: {
              activeBg: '#D2D6DB4D',
              hoverBg: '#D2D6DB4D',
              activeBorderColor: '1e1e1e',
              hoverBorderColor: '1e1e1e'
            }
          }
        }}
      >
        <Flex align="center" justify="center">
          <div className={styles.logoContainer}>
            <CircularLogo />
          </div>
        </Flex>
        <Spacer size={150} />
        <Flex
          className={styles.contentContainer}
          align="center"
          justify="center"
        >
          {children}
        </Flex>
      </ConfigProvider>
    </div>
  );
}

SignInLayout.propTypes = {
  children: PropTypes.node
};

export default SignInLayout;
