import { Tag } from 'antd';
import React, { useMemo } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import CapacityFilter from 'src/components/project/explore/filters/CapacityFilter';
import CountryFilter from 'src/components/project/explore/filters/CountryFilter';
import IsCapabilityFilter from 'src/components/project/explore/filters/IsCapabilityFilter';
import KpiFilter, {
  SingleKpiFilter
} from 'src/components/project/explore/filters/KpiFilter';
import MaterialFilter from 'src/components/project/explore/filters/MaterialFilter';
import PropertyFilter from 'src/components/project/explore/filters/PropertyFilter';
import RegionFilter from 'src/components/project/explore/filters/RegionFilter';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import { useSecondaryKpis } from 'src/components/project/hooks/useSecondaryKpis';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { PROPERTY_RELATIONSHIP_TYPES } from 'src/constants';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import isEmpty from 'lodash/isEmpty';
import { useAvailableProperties } from './hooks/useAvailableProperties';

export default function useTdsFilterOptions() {
  const [filters] = useConciergeContextState(['explore', 'filters']);

  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);

  const [materialType] = useConciergeContextState([
    'explore',
    'filters',
    'type_code__in'
  ]);

  const primaryKpis = usePrimaryKpis();
  const secondaryKpis = useSecondaryKpis();

  return useMemo(
    () => ({
      material: () => ({
        label: 'Material',
        extra: materialType && (
          <Tag color="blue">
            <MetaPropertyName property="type" optionValue={materialType} />
          </Tag>
        ),
        children: <MaterialFilter />
      }),
      capacity: () => ({
        label: 'Quantity',
        extra: filters?.capacity__gte && (
          <Tag color="blue">
            &gt; {prettyNumberRound(filters.capacity__gte / 2204)} mt
          </Tag>
        ),
        children: <CapacityFilter />
      }),
      region: () => ({
        label: 'Region',
        extra: filters?.region_code__in?.length && (
          <Tag color="blue">{filters.region_code__in.length}</Tag>
        ),
        children: <RegionFilter />
      }),
      country: () => ({
        label: 'Country',
        extra: filters?.country_code__in?.length && (
          <Tag color="blue">{filters.country_code__in.length}</Tag>
        ),
        children: <CountryFilter />
      }),
      primary_specs: () => ({
        label: 'Primary Specifications',
        extra: primaryKpis?.length ? (
          <Tag color="blue">{primaryKpis.join(', ')}</Tag>
        ) : null,
        children: (
          <div className="kpi-filter">
            {primaryKpis.map((kpiCode) => (
              <SingleKpiFilter key={kpiCode} kpiCode={kpiCode} isPrimary />
            ))}
          </div>
        ),
        className: 'ghost'
      }),
      secondary_specs: () => ({
        label: 'Secondary Specifications',
        extra: primaryKpis?.length ? (
          <Tag style={{ textWrap: 'wrap' }} color="blue">
            {secondaryKpis.join(', ')}
          </Tag>
        ) : null,
        children: (
          <KpiFilter title="Secondary Specs" excludeKpis={primaryKpis} />
        ),
        className: 'ghost'
      }),
      bale_source: () => ({
        label: 'Bale Source',
        extra: filters?.properties?.bale_source?.length ? (
          <Tag color="blue">{filters.properties.bale_source.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="bale_source" />
      }),
      bale_form: () => ({
        label: 'Bale Form',
        extra: filters?.properties?.bale_form?.length ? (
          <Tag color="blue">{filters.properties.bale_form.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="bale_form" />
      }),
      bale_grade: () => ({
        label: 'Bale Grade',
        extra: filters?.properties?.bale_grade?.length ? (
          <Tag color="blue">{filters.properties.bale_grade.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="bale_grade" />
      }),
      color: () => ({
        label: 'Color',
        extra: filters?.properties?.color?.length ? (
          <Tag color="blue">{filters.properties.color.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="color" />
      }),
      verified: () => ({
        label: 'Verified Suppliers',
        extra:
          filters?.is_capability === false ? <Tag color="blue">Yes</Tag> : null,
        children: <IsCapabilityFilter />
      }),
      grade: () => ({
        label: 'Grade',
        extra: filters?.properties?.grade?.length ? (
          <Tag color="blue">{filters.properties.grade.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="grade" />
      }),
      'processing-method': () => ({
        label: 'Processing Method',
        extra: filters?.properties?.['processing-method']?.length ? (
          <Tag color="blue">
            {filters.properties['processing-method'].length}
          </Tag>
        ) : null,
        children: <PropertyFilter propertyCode="processing-method" />
      }),
      application: () => ({
        label: 'Application',
        extra: filters?.properties?.application?.length ? (
          <Tag color="blue">{filters.properties.application.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="application" />
      }),
      'source-type': () => ({
        label: 'Source Type',
        extra: filters?.properties?.['source-type']?.length ? (
          <Tag color="blue">{filters.properties['source-type'].length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="source-type" />
      }),
      additive: () => ({
        label: 'Additives / Fillers',
        extra: filters?.properties?.additive?.length ? (
          <Tag color="blue">{filters.properties.additive.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="additive" />
      }),
      'polymer-type': () => ({
        label: 'Polymer Type',
        extra: filters?.properties?.['polymer-type']?.length ? (
          <Tag color="blue">{filters.properties['polymer-type'].length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="polymer-type" />
      }),
      industry: () => ({
        label: 'Industry',
        extra: filters?.properties?.industry?.length ? (
          <Tag color="blue">{filters.properties.industry.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="industry" />
      }),
      certs: () => ({
        label: 'Certifications / Standards',
        extra: filters?.properties?.certs?.length ? (
          <Tag color="blue">{filters.properties.certs.length}</Tag>
        ) : null,
        children: (
          <PropertyFilter
            filterOptions={(opt) =>
              ![
                'efsa',
                'philippines_fda',
                'indonesia_fda',
                'fda_lno',
                'fda'
              ].includes(opt.code)
            }
            propertyCode="certs"
          />
        )
      }),
      flammability: () => ({
        label: 'Flammability Rating',
        extra: filters?.properties?.flammability?.length ? (
          <Tag color="blue">{filters.properties.flammability.length}</Tag>
        ) : null,
        children: <PropertyFilter propertyCode="flammability" />
      })
    }),
    [materialType, primaryKpis, filters, form]
  );
}

export function useTdsFilterActiveOptions(material_class) {
  const { availablePropertyCode } = useAvailableProperties(material_class);
  const primaryKpis = usePrimaryKpis();
  const secondaryKpis = useSecondaryKpis(true);

  return useMemo(() => {
    if (!material_class) return {};
    const secondaryProperty = {};
    const primaryProperty = {};
    availablePropertyCode.forEach((property) => {
      if (
        property.relationship !==
        PROPERTY_RELATIONSHIP_TYPES.IS_PRIMARY_PROPERTY
      ) {
        secondaryProperty[property.subject.code] = true;
      } else {
        primaryProperty[property.subject.code] = true;
      }
    });

    const data = {
      'Primary Specs': {
        // TODO: change the material key to type
        material: true,
        ...primaryProperty,
        // Not properties - Common for ALL material classes
        capacity: true,
        region: true,
        country: true,
        // Not properties - Have to check the primary numerical properties
        primary_specs: primaryKpis.length > 0
      },
      ...(secondaryKpis.length > 0 && {
        // Not properties - Have to check the secondary numerical properties
        'Secondary Specs': {
          secondary_specs: true,
          _spread: true
        }
      }),
      ...(!isEmpty(secondaryProperty) && {
        'Additional Info': {
          ...secondaryProperty
        }
      })
    };
    return data;
  }, [material_class, availablePropertyCode, primaryKpis, secondaryKpis]);
}
