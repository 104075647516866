import React from 'react';
import { Button, Flex, Image, Spin, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { getCompany } from 'src/Query';
import styles from './ProjectManage.module.less';

function SupplierViewContactCard({ projectTds }) {
  const { data, isLoading } = useQuery(
    ['company', projectTds.company_id],
    () => getCompany(projectTds.company_id),
    { enabled: !!projectTds.company_id }
  );

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <Spin spinning={isLoading}>
          <Flex direction="column" justify="space-between">
            <Flex className="gap-xs">
              <Image
                width={42}
                height={42}
                className={styles.supplierImage}
                preview={false}
                src={data?.avatar ?? data?.anonymized_logo}
              />
              <Flex vertical>
                <Typography.Title level={5}>
                  {projectTds.company_name}
                </Typography.Title>
                <Typography.Text className="text-sm" type="secondary">
                  {projectTds.region}
                </Typography.Text>
              </Flex>
            </Flex>
            <Button className={styles.viewContractButton} type="link">
              View Contract
            </Button>
          </Flex>
        </Spin>
      </div>
    </div>
  );
}

SupplierViewContactCard.propTypes = {
  projectTds: PropTypes.object.isRequired
};

export default SupplierViewContactCard;
