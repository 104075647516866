import React, { useEffect } from 'react';
import Typography from 'antd/es/typography/Typography';
import Spacer from 'src/components/common/Spacer';
import { Form, Input, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { forgotPassword } from 'src/Mutation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import parseApiError from 'src/utils/parseApiError';
import SignInLayout from './SignUpLayout';
import styles from './SignUpCompany.module.less';
import SignUpButton from './components/SignUpButton';

function SignUpCompany() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const email = params.get('email');

  const { mutate, isLoading } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: (response, variables) => {
      const redirectLink = params.get('redirect');
      const urlParams = new URLSearchParams({
        email: variables,
        ...(redirectLink && { redirect: redirectLink })
      });
      navigate(`/signup/otp-code?${urlParams.toString()}`);
    },
    onError: (e) => {
      const errors = parseApiError(e);
      messageApi.error(errors[0]);
    }
  });

  useEffect(() => {
    if (email) {
      onSubmitCompanyEmail({ email });
    }
  }, [email]);

  const onSubmitCompanyEmail = (values) => {
    mutate(values.email);
  };

  return (
    <SignInLayout>
      <div>
        <Typography.Title className={styles.whiteText} level={2}>
          Welcome to Circular
        </Typography.Title>
        <Typography.Text className={styles.descriptionText}>
          Add your company email to create your account
        </Typography.Text>
        <Spacer size={40} />
        {contextHolder}
        <Form
          onFinish={onSubmitCompanyEmail}
          initialValues={{ email: params.get('email') }}
          form={form}
          layout="vertical"
        >
          <Form.Item
            rules={[{ required: true }, { type: 'email' }]}
            label="Company Email"
            name="email"
          >
            <Input
              className={styles.companyInput}
              placeholder="Company Email"
            />
          </Form.Item>
          <Form.Item>
            <SignUpButton loading={isLoading} htmlType="submit">
              Create Account
            </SignUpButton>
          </Form.Item>
        </Form>
      </div>
    </SignInLayout>
  );
}

export default SignUpCompany;
