import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Image, Spin, Typography } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getCompany } from 'src/Query';
import classNames from 'classnames';
import { PROJECT_STAGE } from 'src/constants/project';
import styles from './ReviewShipmentModal.module.less';
import projectManageStyles from './ProjectManage.module.less';

function CompanyCard({ projectTds, onSelect, selectedCompanyId }) {
  const { data, isLoading } = useQuery(
    ['company', projectTds.company_id],
    () => getCompany(projectTds.company_id),
    { enabled: !!projectTds.company_id }
  );

  const badgedStatusClassname = classNames(styles.statusBadged, {
    [styles.statusOnboarded]:
      projectTds.project_status === PROJECT_STAGE.ONBOARDED,
    [styles.statusManage]: projectTds.project_status === PROJECT_STAGE.MANAGE,
    [styles.statusProcure]: projectTds.project_status === PROJECT_STAGE.PROCURE
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={() => onSelect(data)}
      className={projectManageStyles.cardWrapper}
      style={{
        border:
          selectedCompanyId === projectTds.company_id ? '2px solid #2F54EB' : ''
      }}
    >
      <div className={projectManageStyles.card}>
        <Spin spinning={isLoading}>
          <Flex direction="column" align="center" justify="space-between">
            <Flex className="gap-xs">
              <Image
                width={42}
                height={42}
                className={styles.supplierImage}
                preview={false}
                alt=""
                src={data?.avatar ?? data?.anonymized_logo}
                style={{ borderRadius: 8 }}
              />
              <Flex vertical>
                <Typography.Title level={5}>
                  {projectTds.company_name}
                </Typography.Title>
                <Typography.Text className="text-sm" type="secondary">
                  {data?.locations?.length} Locations
                </Typography.Text>
              </Flex>
            </Flex>
            <div className={badgedStatusClassname}>
              {projectTds.project_status}
            </div>
          </Flex>
        </Spin>
      </div>
    </div>
  );
}

CompanyCard.propTypes = {
  projectTds: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedCompanyId: PropTypes.string
};

export default CompanyCard;
