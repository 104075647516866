import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Empty,
  Form,
  Input,
  Popconfirm,
  Row,
  Select
} from 'antd';
import {
  CloseOutlined,
  FieldTimeOutlined,
  SendOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';

import { getProjectTdsActivity } from 'src/Query';
import LoadingErrorOrContent from 'src/components/utils/LoadingErrorOrContent';
import UploadDocumentsCompact from 'src/components/form/UploadDocumentsCompact';
import {
  createOrUpdateProjectTdsActivity,
  deleteProjectTdsActivity
} from 'src/Mutation';
import FileList from 'src/components/form/FileList';
import UploadDocuments from 'src/components/form/UploadDocuments';

export function useQuoteDetails({ onClose, tds, open }) {
  const {
    data: activity,
    isLoading,
    error
  } = useQuery(
    ['project-tds-activity', tds?.project_tds_id],
    () =>
      getProjectTdsActivity({ project_tds: tds?.project_tds_id, all: true }),
    { enabled: !!tds }
  );

  useEffect(() => {
    if (open) document.body.classList.add('hide-intercom');
    else document.body.classList.remove('hide-intercom');
  }, [open]);

  const [filter, setFilter] = useState('all');

  const filteredActivity = useMemo(() => {
    if (!activity?.length) return [];

    if (filter === 'attachments')
      return activity.filter(
        (activityItem) => !!activityItem.attachments?.length
      );

    return activity;
  }, [filter, activity]);
  const QuoteDetails = useMemo(
    () => (
      <Drawer
        open={open}
        className="project-tds-activity"
        title={
          <Row align="middle" wrap={false} gutter={[8, 0]}>
            <Col style={{ lineHeight: 1 }}>
              <FieldTimeOutlined style={{ fontSize: 18, color: '#AAB0BB' }} />
            </Col>
            <Col style={{ marginRight: 4 }}>
              {tds?.company_name || 'Project Activity'}
            </Col>
            <Col>
              <Select
                value={filter}
                onChange={setFilter}
                options={[
                  {
                    label: 'Show All Types',
                    value: 'all'
                  },
                  {
                    label: 'Show Attachments',
                    value: 'attachments'
                  }
                ]}
                size="small"
              />
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <button className="bare" type="button" onClick={onClose}>
                <CloseOutlined style={{ fontSize: 18 }} />
              </button>
            </Col>
          </Row>
        }
        onClose={onClose}
        closeIcon={false}
        footer={<ActivityItemForm project_tds_id={tds?.project_tds_id} />}
        width={450}
      >
        <LoadingErrorOrContent isLoading={isLoading} error={error}>
          {filteredActivity?.length ? (
            filteredActivity.map((activityItem, i) => (
              <ActivityItem
                key={activityItem.uuid}
                item={activityItem}
                scrollIntoView={i === filteredActivity.length - 1}
                project_tds_id={tds?.project_tds_id}
              />
            ))
          ) : (
            <Empty />
          )}
        </LoadingErrorOrContent>
        <div id="anchor" />
      </Drawer>
    ),
    [open, activity, tds]
  );
  return {
    filter,
    setFilter,
    QuoteDetails
  };
}

function ActivityItem({ item, scrollIntoView, project_tds_id }) {
  const ref = useRef();
  useEffect(() => {
    if (scrollIntoView && ref.current) ref.current.scrollIntoView();
  }, [scrollIntoView, ref?.current]);

  const [editing, setEditing] = useState(false);

  return (
    <div
      onDoubleClick={() => setEditing(true)}
      className="single-item mb-sm"
      ref={ref}
    >
      <Row align="middle" className="mb-xs">
        <Col>
          <Avatar size="small" alt="User avatar" src={item.created_by.avatar}>
            {item.created_by.name.slice(0, 1)}
          </Avatar>{' '}
        </Col>
        <Col className="font-size-2 ml-xs">{item.created_by.name}</Col>
        <Col
          className="font-size-2 single-item--date"
          style={{ marginLeft: 'auto' }}
        >
          {dayjs(item.created_at).fromNow()}
        </Col>
      </Row>
      {editing ? (
        <ActivityItemForm
          project_tds_id={project_tds_id}
          item={item}
          onSubmit={() => setEditing(false)}
        />
      ) : (
        <>
          <Row>
            <ReactMarkdown className="react-markdown">
              {item.text?.replace(/\n/g, '  \n')}
            </ReactMarkdown>
          </Row>
          {item.attachments?.length ? (
            <FileList value={item.attachments} />
          ) : null}
        </>
      )}
    </div>
  );
}
ActivityItem.propTypes = {
  item: PropTypes.object.isRequired,
  scrollIntoView: PropTypes.bool,
  project_tds_id: PropTypes.number.isRequired
};

function ActivityItemForm({ project_tds_id, item, onSubmit }) {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: createOrUpdateProjectTdsActivity,
    onSuccess: (response) => {
      queryClient.setQueryData(
        ['project-tds-activity', project_tds_id],
        (oldActivity) => {
          if (Array.isArray(oldActivity)) {
            const index = oldActivity.findIndex(
              (activity) => activity.uuid === response.uuid
            );

            if (index > -1) {
              const newActivity = [...oldActivity];
              newActivity[index] = response;
              return newActivity;
            }

            return [...oldActivity, response];
          }

          return [response];
        }
      );

      if (onSubmit) onSubmit();
      else form.resetFields();
    }
  });

  const { mutate: deleteAcitivtyItem } = useMutation({
    mutationFn: deleteProjectTdsActivity,
    onSuccess: () => {
      queryClient.setQueryData(
        ['project-tds-activity', project_tds_id],
        (oldActivity) => {
          const index = oldActivity.findIndex(
            (activity) => activity.uuid === item.uuid
          );

          const newActivity = [...oldActivity];
          newActivity.splice(index, 1);
          return newActivity;
        }
      );
    }
  });

  const isEditing = useMemo(() => !!item?.uuid, [item?.uuid]);

  if (!project_tds_id) return null;

  return (
    <Form
      form={form}
      layout={isEditing ? 'vertical' : 'inline'}
      disabled={isLoading}
      onFinish={(values) =>
        mutate({
          project_tds: project_tds_id,
          text: values.text,
          attachments_ids: values.attachments?.map(({ uuid }) => uuid),
          uuid: item?.uuid
        })
      }
      initialValues={item || {}}
    >
      <Form.Item name="text" style={{ flexGrow: 1 }}>
        <Input.TextArea
          autoSize
          placeholder="Add a note or attachment"
          onKeyDown={(e) => {
            if (e.metaKey && e.key === 'Enter') {
              form.submit();
            }
          }}
        />
      </Form.Item>
      <Form.Item name="attachments">
        {isEditing ? <UploadDocuments /> : <UploadDocumentsCompact />}
      </Form.Item>
      <Form.Item style={{ marginInlineEnd: 0, marginBottom: 0 }}>
        <Button type="primary" htmlType="submit">
          {isEditing ? <>Save changes</> : <SendOutlined />}
        </Button>
        {isEditing ? (
          <Popconfirm
            title="Delete item"
            description="Are you sure you want to delete this item?"
            onConfirm={() => deleteAcitivtyItem(item.uuid)}
            okText="Delete"
            cancelText="Cancel"
            okType="danger"
          >
            <Button
              className="ml-xs"
              type="primary"
              danger
              ghost
              htmlType="button"
            >
              Delete item
            </Button>
          </Popconfirm>
        ) : null}
      </Form.Item>
    </Form>
  );
}
ActivityItemForm.propTypes = {
  project_tds_id: PropTypes.number.isRequired,
  item: PropTypes.object,
  onSubmit: PropTypes.func
};
