import { Row, Col, Typography } from 'antd';
import React from 'react';

import './MarketsPlayground.less';
import Pricing from 'src/components/project/explore/Pricing2';
import FiltersSidebar from 'src/components/project/FiltersSidebar';
import ConciergeContextProvider from 'src/components/concierge/ConciergeContext';

/* eslint-disable react/no-unstable-nested-components */
export default function MarketsPlayground() {
  return (
    <ConciergeContextProvider>
      <Row id="markets-playground" gutter={[16, 0]}>
        <Col style={{ width: 300, maxWidth: 300 }}>
          <FiltersSidebar />
        </Col>
        <Col flex="1">
          <Typography.Title level={3}>Markets Playground</Typography.Title>
          <Pricing />
        </Col>
      </Row>
    </ConciergeContextProvider>
  );
}
