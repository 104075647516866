const { fetch } = require('src/utils/client');

export const updateShipment = (shipmentId, payload) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/project-material-shipment/change-event/${shipmentId}/`,
    {
      method: 'POST',
      body: JSON.stringify(payload)
    }
  );

export const createMaterialShipment = (data) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/material-shipment/`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
