export const isSupplier = (user) =>
  user?.groups?.find((group) => group.name === 'Supplier');

export const isBuyer = (user) =>
  user?.groups?.find((group) => group.name === 'Buyer');

export const isConcierge = (user) =>
  user?.groups?.find((group) => group.name === 'Concierge');

export const isDealPitch = (user) =>
  user?.groups?.find((group) => group.name === 'Deal Pitch');

export const isRestricted = (user) =>
  user?.groups?.find((group) => group.name === 'Restricted');

export const isOutdated = (user) =>
  user?.groups.length === 0 || user?.permissions.length === 0;

export const EXPLORE_PERMISSIONS = {
  read: 'warehouse.explore_read',
  add: 'warehouse.explore_add',
  change: 'warehouse.explore_change',
  delete: 'warehouse.explore_delete'
};

export const canReadExplore = (user) =>
  user?.user_permissions?.find((permission) =>
    [
      EXPLORE_PERMISSIONS.read,
      EXPLORE_PERMISSIONS.change,
      EXPLORE_PERMISSIONS.delete
    ].includes(permission)
  );
export const canAddExplore = (user) =>
  user?.user_permissions?.find((permission) =>
    [EXPLORE_PERMISSIONS.add].includes(permission)
  );
export const canChangeExplore = (user) =>
  user?.user_permissions?.find((permission) =>
    [EXPLORE_PERMISSIONS.change].includes(permission)
  );
export const canDeleteExplore = (user) =>
  user?.user_permissions?.find((permission) =>
    [EXPLORE_PERMISSIONS.delete].includes(permission)
  );

export const SOURCE_PERMISSIONS = {
  read: 'warehouse.source_read',
  add: 'warehouse.source_add',
  change: 'warehouse.source_change',
  delete: 'warehouse.source_delete'
};
export const canReadSource = (user) =>
  user?.user_permissions?.find((permission) =>
    [
      SOURCE_PERMISSIONS.read,
      SOURCE_PERMISSIONS.change,
      SOURCE_PERMISSIONS.delete
    ].includes(permission)
  );
export const canReadProcure = (user) =>
  user?.groups?.find(
    (group) => group.name === 'Procure' || group.name === 'Concierge'
  );
export const canReadManage = (user) =>
  user?.groups?.find(
    (group) => group.name === 'Manage' || group.name === 'Concierge'
  );
export const canAddSource = (user) =>
  user?.user_permissions?.find((permission) =>
    [SOURCE_PERMISSIONS.add].includes(permission)
  );
export const canChangeSource = (user) =>
  user?.user_permissions?.find((permission) =>
    [SOURCE_PERMISSIONS.change].includes(permission)
  );
export const canDeleteSource = (user) =>
  user?.user_permissions?.find((permission) =>
    [SOURCE_PERMISSIONS.delete].includes(permission)
  );

export const PORTFOLIO_PERMISSIONS = {
  read: 'api.view_portfolio',
  add: 'api.add_portfolio',
  change: 'api.chnage_portfolio',
  delete: 'api.delete_portfolio'
};

export const canReadPortfolio = (user) =>
  user?.user_permissions?.find((permission) =>
    [
      PORTFOLIO_PERMISSIONS.read,
      PORTFOLIO_PERMISSIONS.change,
      PORTFOLIO_PERMISSIONS.delete
    ].includes(permission)
  );
export const canAddPortfolio = (user) =>
  user?.user_permissions?.find((permission) =>
    [PORTFOLIO_PERMISSIONS.add].includes(permission)
  );
export const canChangePortfolio = (user) =>
  user?.user_permissions?.find((permission) =>
    [PORTFOLIO_PERMISSIONS.change].includes(permission)
  );
export const canDeletePortfolio = (user) =>
  user?.user_permissions?.find((permission) =>
    [PORTFOLIO_PERMISSIONS.delete].includes(permission)
  );
