import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Form, InputNumber, Select, Typography } from 'antd';
import PropTypes from 'prop-types';
import { PROJECT_STAGE } from 'src/constants/project';
import { useParams } from 'react-router-dom';
import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import Spacer from 'src/components/common/Spacer';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getProject } from 'src/Query';
import { formatLocation } from 'src/pages/project/ProjectData';
import { createMaterialShipment } from 'src/Mutation/shippment';
import styles from './ReviewShipmentModal.module.less';
import CompanyCard from './CompanyCard';

function InitialSpotBuySection({
  onSubmit,
  isIncludingShipping,
  onChangeStep,
  shipment
}) {
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const [selectedCompany, setSelectedCompany] = useState();

  useEffect(() => () => setSelectedCompany(), []);

  const { data: project } = useQuery(
    ['project', projectId],
    () => getProject(projectId),
    { enabled: !!projectId }
  );

  const { data: { results: projectTds } = { results: [] } } =
    useGetWarehouseTdss({
      project: {
        id: projectId,
        status: [
          PROJECT_STAGE.MANAGE,
          PROJECT_STAGE.ONBOARDED,
          PROJECT_STAGE.PROCURE
        ]
      },
      fields: ['company_name', 'tds_id', 'company_id', 'state']
    });

  const { mutate, isLoading } = useMutation({
    mutationFn: createMaterialShipment,
    onSuccess: () => {
      onSubmit();
    },
    onError: (error) => {
      // TODO: handle creating material shipment error
    }
  });

  const mappingProjectTdsWithCompany = new Map(
    projectTds.map((p) => [p.company_id, p])
  );

  const sortedProjectTds = useMemo(() => {
    if (!projectTds) return [];
    const includedCompanyIds = new Map();

    return (projectTds ?? [])
      .filter((item) => {
        if (includedCompanyIds.has(item.company_id)) {
          return false;
        }

        includedCompanyIds.set(item.company_id, true);
        return true;
      })
      .sort((a, b) => {
        const order = [
          PROJECT_STAGE.MANAGE,
          PROJECT_STAGE.ONBOARDED,
          PROJECT_STAGE.PROCURE
        ];

        return (
          order.indexOf(a.project_status) - order.indexOf(b.project_status)
        );
      });
  }, [projectTds]);

  const handleClickSelect = () => {
    if (!selectedCompany) return;

    onChangeStep();
  };

  const onFinish = (values) => {
    if (!selectedCompany) return;
    const selectedProjectTds = mappingProjectTdsWithCompany.get(
      selectedCompany.uuid
    );
    const creationMaterialShipmentData = {
      ...values,
      name: shipment.material_shipment.name,
      planned_delivery_date: shipment.material_shipment.planned_delivery_date,
      tds: selectedProjectTds.tds_id
    };
    mutate(creationMaterialShipmentData);
  };

  return (
    <div>
      {isIncludingShipping ? (
        <div style={{ width: '100%' }}>
          <Form
            initialValues={{
              quantity: Number.parseFloat(project?.capacity).toFixed(2),
              destination: project?.locations?.[0]?.uuid
            }}
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="quantity"
              label={`Volume (${project?.capacity_units})`}
              rules={[{ required: true, message: 'Please input volume' }]}
            >
              <InputNumber className={styles.inputVolume} />
            </Form.Item>
            <Form.Item name="destination" label="Select Destination">
              <Select
                options={(project?.locations ?? []).map((l) => ({
                  label: formatLocation(l),
                  value: l.uuid
                }))}
                style={{ height: 40 }}
                disabled
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className={styles.manageButton}
                type="primary"
                loading={isLoading}
                disabled={isLoading}
              >
                Request Spot Buy
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <>
          <Typography.Text className={styles.textSpot}>
            Select a supplier to request a spot buy
          </Typography.Text>
          <Spacer size={20} />
          <Flex style={{ flexDirection: 'column' }} className="gap mb">
            {sortedProjectTds?.map((t) => (
              <CompanyCard
                key={t.id}
                projectTds={t}
                onSelect={(company) => setSelectedCompany(company)}
                selectedCompanyId={selectedCompany?.uuid}
              />
            ))}
          </Flex>
          <Button
            className={styles.manageButton}
            onClick={handleClickSelect}
            type="primary"
          >
            Select Supplier
          </Button>
        </>
      )}
    </div>
  );
}

InitialSpotBuySection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  isIncludingShipping: PropTypes.bool.isRequired,
  shipment: PropTypes.object
};

export default InitialSpotBuySection;
