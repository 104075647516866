import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Typography } from 'antd';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';

import { currencyValuePrice } from 'src/utils/currency';
import Spacer from 'src/components/common/Spacer';

export default function PriceInsight({ priceLog, onClickViewHistory }) {
  return (
    <ExplorePanel className="tds-price-tile" pad>
      <Flex vertical>
        <Flex align="center" justify="space-between">
          <Typography.Text>Your Price</Typography.Text>
          <Typography.Link
            onClick={onClickViewHistory}
            style={{ color: '#2F54EB' }}
          >
            View Price History
          </Typography.Link>
        </Flex>
        <Spacer size={12} />
        <Typography.Text className="your-price">
          {priceLog?.price
            ? currencyValuePrice(
                priceLog?.price,
                priceLog?.currency,
                priceLog?.units
              )
            : 'N/A'}
        </Typography.Text>
      </Flex>
    </ExplorePanel>
  );
}

PriceInsight.propTypes = {
  priceLog: PropTypes.object,
  onClickViewHistory: PropTypes.func
};
