import { useMemo } from 'react';

import { PROPERTY_RELATIONSHIP_TYPES } from 'src/constants';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import useMaterialClass from 'src/components/project/explore/hooks/useMaterialClass';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';

export const primaryKpisByMaterial = {
  rpet: ['IV', 'D'],
  rpp: ['MFI', 'D'],
  rhdpe: ['MFI', 'D'],
  rldpe_rlldpe: ['MFI', 'D'],
  DEFAULT: ['MFI', 'D']
};

export const ALL_PRIMARY_KPI_KEYS = ['MFI', 'D', 'IV'];

const usePrimaryKpis = () => {
  const numericalProperties = useMetaNumericalProperty();

  const [type] = useConciergeContextState([
    'explore',
    'filters',
    'type_code__in'
  ]);
  const materialClass = useMaterialClass();

  return useMemo(() => {
    if (!numericalProperties) return [];

    return numericalProperties
      .filter(
        ({ subject_options }) =>
          // Should be primary for plastic type
          subject_options.some(
            (option) =>
              option.relationship ===
                PROPERTY_RELATIONSHIP_TYPES.IS_PRIMARY_PROPERTY &&
              option.object_option.code === type
          ) &&
          // May be primary or applies_to for material class
          subject_options.some(
            (option) =>
              (option.relationship ===
                PROPERTY_RELATIONSHIP_TYPES.IS_PRIMARY_PROPERTY ||
                option.relationship ===
                  PROPERTY_RELATIONSHIP_TYPES.APPLIES_TO) &&
              option.object_option.code === materialClass
          )
      )
      .map((property) => property.code);
  }, [numericalProperties, type, materialClass]);
};

export default usePrimaryKpis;
