import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Typography } from 'antd';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';

import { currencyValuePrice } from 'src/utils/currency';
import Spacer from 'src/components/common/Spacer';
import { useQuery } from '@tanstack/react-query';
import { getMarketPriceLatestPost } from 'src/Query/marketPrice';
import isEmpty from 'lodash/isEmpty';

export default function CohortAveragePrice({ filters }) {
  const filterCircular = {
    ...filters,
    circular: true,
    virgin: false
  };

  const { data: latestPrice = {} } = useQuery(
    ['market-price-latest', filterCircular],
    () => getMarketPriceLatestPost(filterCircular),
    {
      enabled: !isEmpty(filters)
    }
  );

  const averagePrice = (latestPrice.high + latestPrice.low) / 2;

  return (
    <ExplorePanel className="tds-price-tile" pad>
      <Flex vertical>
        <Typography.Text>Cohort Average Price</Typography.Text>
        <Spacer size={12} />
        <Typography.Text className="your-price ">
          {averagePrice
            ? currencyValuePrice(
                averagePrice,
                latestPrice?.currency,
                latestPrice?.units
              )
            : 'N/A'}
        </Typography.Text>
      </Flex>
    </ExplorePanel>
  );
}

CohortAveragePrice.propTypes = {
  filters: PropTypes.object
};
