import React from 'react';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

// Radial Strokes svg
import { ReactComponent as RadialStrokes } from 'src/images/RadialStrokes.svg';

import './Reports.less';
import { useMutation } from '@tanstack/react-query';
import { plasticsUncoveredLead } from 'src/Mutation';
import LandingHeader from '../landing/components/LandingHeader';

export default function PlasticsUncovered() {
  const mutation = useMutation({
    mutationFn: plasticsUncoveredLead
  });
  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="Plastics Uncovered: Optimizing the Recycled Plastic Value Chain"
        />
        <meta
          property="og:image"
          content="https://circular-branding.s3.amazonaws.com/landing-assets/logo-name.png"
        />
        <meta
          property="og:description"
          content="Moving the plastics industry from a linear to a circular economy is a shared goal across the global value chain, yet the path to success remains unclear. The journey requires skillful navigation through industry challenges, proactive understanding of tailwinds toward progress, and a strategic embrace of technology."
        />
        <meta property="og:url" content="https://circular.co/industrybrief" />
      </Helmet>
      <div className="gated-report">
        <LandingHeader
          itemsOverride={[
            {
              label: 'info@circular.co',
              href: 'mailto:info@circular.co',
              key: 'infomail'
            }
          ]}
          noCollapse
        />
        <div className="hero">
          <div className="background-svg-overlay"></div>
          <div className="background-svg">
            <RadialStrokes />
          </div>
          <Row justify="center">
            <Col xs={24} sm={20} md={16} lg={11}>
              <div className="pre-title">
                Industry Brief - Recycled Plastics
              </div>
              <h1>
                Plastics Uncovered: Optimizing the Recycled Plastic Value Chain
              </h1>
              <p>
                Moving the plastics industry from a linear to a circular economy
                is a shared goal across the global value chain, yet the path to
                success remains unclear. The journey requires skillful
                navigation through industry challenges, proactive understanding
                of tailwinds toward progress, and a strategic embrace of
                technology.
              </p>
            </Col>
            <Col xs={24} sm={20} md={16} lg={{ span: 11, offset: 2 }}>
              <FormCard mutation={mutation} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

function FormCard({ mutation }) {
  const { mutate, isLoading, isSuccess, isError } = mutation;

  const successContent = (
    <Alert
      type="success"
      showIcon
      description={
        <>
          Thanks for submitting. You will receive the report by email shortly.
        </>
      }
    />
  );

  const errorContent = (
    <Alert
      type="error"
      showIcon
      description="An error ocurred. Please try again."
    />
  );

  return (
    <Form
      layout="vertical"
      disabled={isLoading}
      onFinish={(values) => {
        mutate(values);
      }}
      requiredMark={false}
    >
      {isSuccess ? successContent : null}
      {isError ? errorContent : null}
      <Row gutter={[20, 0]}>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true, message: 'This field is required' }]}
            name="first-name"
            label="First Name"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true, message: 'This field is required' }]}
            name="last-name"
            label="Last Name"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        rules={[{ required: true, message: 'This field is required' }]}
        name="company"
        label="Company"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          { required: true, message: 'This field is required' },
          { type: 'email', message: 'Please enter a valid email' }
        ]}
        name="email"
        label="Email"
      >
        <Input type="email" />
      </Form.Item>
      <Button type="primary" size="large" htmlType="submit">
        Download Report
      </Button>
    </Form>
  );
}
FormCard.propTypes = {
  mutation: PropTypes.object
};
