import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

const useAllProperties = () => {
  const [allProperties] = useConciergeContextState([
    'explore',
    'filters',
    'properties'
  ]);
  return Object.keys(allProperties || {});
};

export default useAllProperties;
