import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetch } from 'src/utils/client';
import { ChatHeader } from 'src/components/project/source/ChatHeader';
import { ChatBody } from 'src/components/project/source/ChatBody';
import { ChatFooter } from 'src/components/project/source/ChatFooter';
import { getConversationKey } from 'src/Query/messenging';

import './chat.less';

const fetchConversation = async (id) =>
  fetch(`${process.env.REACT_APP_CIRCLE_API}/conversations/${id.toString()}`);

export function ChatBuilder({ onClose, conversationId, isOpen }) {
  const {
    data: activity,
    isLoading,
    error
  } = useQuery(
    getConversationKey(conversationId),
    () => fetchConversation(conversationId),
    {
      enabled: !!conversationId
    }
  );

  const queryClient = useQueryClient();

  const handleClose = () => {
    queryClient.invalidateQueries({
      queryKey: getConversationKey(conversationId)
    });

    onClose();
  };

  const title = isLoading ? 'Loading...' : activity?.title;

  return (
    <div className={`chat-window${isOpen ? ' open' : ''}`}>
      <div className="chat-window-inner">
        <ChatHeader title={title} subtitle="" onClose={handleClose} />
        <div className="scroll-area">
          <ChatBody conversation={activity} />
        </div>
        <ChatFooter conversationId={conversationId} />
      </div>
    </div>
  );
}

ChatBuilder.propTypes = {
  onClose: PropTypes.func.isRequired,
  conversationId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired
};
