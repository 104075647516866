import { Button, Divider, Empty, Flex, Skeleton, Typography } from 'antd';
import React from 'react';
import Spacer from 'src/components/common/Spacer';
import CircularLogo from 'src/components/icons/CircularLogo';
import { PaperClipOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from 'src/constants';
import { METADATA_PROPERTY_TYPE } from 'src/constants/project';
import useMetadataMap from 'src/pages/project/source/useMetadataMap';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getProject } from 'src/Query';
import { getTdsDetail } from 'src/Mutation';
import { formatQuantity } from 'src/components/formatQuantity';
import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import styles from './DigitalRfqEmailSummary.module.less';

function DigitalRfqEmailSummary() {
  const { projectId, tdsId } = useParams();
  const { data: project, isLoading } = useQuery(
    ['project', projectId],
    () => getProject(projectId),
    {
      enabled: !!projectId
    }
  );

  const { data: tdsDetail, isLoading: isLoadingTdsDetail } = useQuery(
    ['tds', tdsId],
    () => getTdsDetail(tdsId),
    {
      enabled: !!tdsId
    }
  );

  const { data: projectTdss } = useGetWarehouseTdss({
    project: {
      id: projectId
    },
    fields: ['tds_id'],
    all: 'true'
  });
  const projectTds = projectTdss?.find((tds) => tds.tds_id === tdsId);

  const {
    certOptions,
    colorOptions,
    processingMethodOptions,
    applicationOptions,
    sourceTypeOptions
  } = useMetadataMap();

  if (isLoadingTdsDetail || isLoading) return <Skeleton active />;

  if (!project || !tdsDetail) return <Empty />;

  const materialProperties = tdsDetail.material_properties ?? [];
  const tdsCertifications = materialProperties.filter(
    (item) =>
      item?.meta_property?.code === METADATA_PROPERTY_TYPE.CERTIFICATIONS
  );
  const tdsColors = materialProperties.filter(
    (item) => item?.meta_property?.code === METADATA_PROPERTY_TYPE.COLOR
  );
  const tdsProcessingMethods = materialProperties.filter(
    (item) =>
      item?.meta_property?.code === METADATA_PROPERTY_TYPE.PROCESSING_METHOD
  );
  const tdsApplications = materialProperties.filter(
    (item) => item?.meta_property?.code === METADATA_PROPERTY_TYPE.APPLICATION
  );
  const tdsSources = materialProperties.filter(
    (item) => item?.meta_property?.code === METADATA_PROPERTY_TYPE.SOURCE_TYPE
  );
  const tdsPriceLogs = tdsDetail?.locations?.[0]?.price_logs?.[0] ?? {};

  return (
    <Flex style={{ flexDirection: 'column' }} justify="center" align="center">
      <Spacer size={50} />
      <div className={styles.emailContainer}>
        <div className={styles.contentContainer}>
          <CircularLogo light />
          <Spacer size={40} />
          <Typography className={styles.thankText}>
            Thank you for <br />
            submitting a quote.
          </Typography>
          <Spacer size={24} />
          <Typography className={styles.normalText}>
            Here’s what to expect:
          </Typography>
          <Spacer size={20} />
          <div className={styles.textGroup}>
            <div className={styles.normalText}>1.</div>
            <Typography className={styles.normalText}>
              A representative from Circular will contact you within 7 days with
              any questions or to request additional information.
            </Typography>
          </div>
          <Spacer size={20} />
          <div className={styles.textGroup}>
            <div className={styles.normalText}>2.</div>
            <Typography className={styles.normalText}>
              You can edit your submission any time before the{' '}
              {dayjs(tdsDetail.created_at)
                .add(7, 'day')
                .format(DATETIME_FORMAT.FULL_DATE)}{' '}
              deadline.
            </Typography>
          </div>
          <Spacer size={20} />
          <div className={styles.textGroup}>
            <div className={styles.normalText}>3.</div>
            <Typography className={styles.normalText}>
              Once the buyer has reviewed all quotes, a representative from
              Circular will follow up with feedback and next steps.
            </Typography>
          </div>
          <Spacer size={32} />
          <div className={styles.digitalRfqContent}>
            <Typography.Title level={4}>Quote Summary</Typography.Title>
            <Spacer size={24} />
            <Typography className={styles.quoteTitle}>
              TDS & Documents
            </Typography>
            <Divider className="mt-xs mb-xs" />
            <Flex align="center" className="gap">
              <Button
                style={{ border: 'none', background: 'transparent' }}
                icon={<PaperClipOutlined />}
                onClick={() => {
                  window.open(tdsDetail?.documents[0]?.url, '_blank');
                }}
                disabled={!tdsDetail?.documents?.length}
              />
              <Typography className={styles.quoteText}>
                {tdsDetail?.documents?.length
                  ? `You uploaded ${tdsDetail?.documents?.length} document`
                  : 'No documents uploaded'}
              </Typography>
            </Flex>
            <Divider className="mt-xs mb-xs" />
            <Spacer size={12} />
            <Typography className={styles.quoteTitle}>
              Additional Information
            </Typography>
            <Divider className="mt-xs mb-xs" />
            <Flex align="start" justify="space-between" className="gap">
              <Typography className={styles.quoteText}>
                Certifications
              </Typography>
              <Flex
                style={{ flexDirection: 'column' }}
                justify="center"
                align="end"
              >
                {tdsCertifications.length
                  ? tdsCertifications.map((c) => (
                      <Typography key={c.uuid} className={styles.quoteTextBold}>
                        {certOptions.get(c.code)}
                      </Typography>
                    ))
                  : '--'}
              </Flex>
            </Flex>
            <Divider className="mt-xs mb-xs" />
            <Flex align="start" justify="space-between" className="gap">
              <Typography className={styles.quoteText}>Color</Typography>
              <Flex
                style={{ flexDirection: 'column' }}
                justify="center"
                align="end"
              >
                {tdsColors.length
                  ? tdsColors.map((c) => (
                      <Typography key={c.uuid} className={styles.quoteTextBold}>
                        {colorOptions.get(c.code)}
                      </Typography>
                    ))
                  : '--'}
              </Flex>
            </Flex>
            <Divider className="mt-xs mb-xs" />
            <Flex align="start" justify="space-between" className="gap">
              <Typography className={styles.quoteText}>
                Processing Method
              </Typography>
              <Flex
                style={{ flexDirection: 'column' }}
                justify="center"
                align="end"
              >
                {tdsProcessingMethods?.length
                  ? tdsProcessingMethods?.map((p) => (
                      <Typography key={p.uuid} className={styles.quoteTextBold}>
                        {processingMethodOptions.get(p.code)}
                      </Typography>
                    ))
                  : '--'}
              </Flex>
            </Flex>
            <Divider className="mt-xs mb-xs" />
            <Flex align="start" justify="space-between" className="gap">
              <Typography className={styles.quoteText}>Application</Typography>
              <Flex
                style={{ flexDirection: 'column' }}
                justify="center"
                align="end"
              >
                {tdsApplications.length
                  ? tdsApplications.map((a) => (
                      <Typography key={a.uuid} className={styles.quoteTextBold}>
                        {applicationOptions.get(a.code)}
                      </Typography>
                    ))
                  : '--'}
              </Flex>
            </Flex>
            <Divider className="mt-xs mb-xs" />
            <Flex align="start" justify="space-between" className="gap">
              <Typography className={styles.quoteText}>Source</Typography>
              <Flex
                style={{ flexDirection: 'column' }}
                justify="center"
                align="end"
              >
                {tdsSources.length
                  ? tdsSources.map((a) => (
                      <Typography key={a.uuid} className={styles.quoteTextBold}>
                        {sourceTypeOptions.get(a.code)}
                      </Typography>
                    ))
                  : '--'}
              </Flex>
            </Flex>
            <Divider className="mt-xs mb-xs" />
            <Spacer size={12} />
            <Typography className={styles.quoteTitle}>
              Quantity & Pricing Information
            </Typography>
            <Divider className="mt-xs mb-xs" />
            <Flex align="start" justify="space-between" className="gap">
              <Typography className={styles.quoteText}>Quantity</Typography>
              <Typography className={styles.quoteTextBold}>
                {formatQuantity(tdsDetail?.capacity, tdsDetail?.capacity_units)}
              </Typography>
            </Flex>
            <Divider className="mt-xs mb-xs" />
            <Flex align="start" justify="space-between" className="gap">
              <Typography className={styles.quoteText}>
                Raw Material Price
              </Typography>
              <Typography className={styles.quoteTextBold}>
                ${tdsPriceLogs?.original_price ?? '--'}
              </Typography>
            </Flex>
            <Divider className="mt-xs mb-xs" />
            <Flex align="start" justify="space-between" className="gap">
              <Typography className={styles.quoteText}>
                Estimated Freight Cost
              </Typography>
              <Typography className={styles.quoteTextBold}>
                ${projectTds?.project_freight ?? '--'}
              </Typography>
            </Flex>
            <Divider className="mt-xs mb-xs" />
            <Flex align="start" justify="space-between" className="gap">
              <Typography className={styles.quoteText}>
                Pricing Terms
              </Typography>
              <Typography className={styles.quoteTextBold}>
                {tdsPriceLogs?.terms ? tdsPriceLogs?.terms : '--'}
              </Typography>
            </Flex>
          </div>
          <Spacer size={40} />
          <Typography className={styles.infoTextEnd}>
            Circular is a platform designed to find cost savings and efficiency
            gains for buyers and suppliers.
          </Typography>
          <Spacer size={8} />
          <Typography className={styles.infoTextEnd}>
            Contact <a href="mailto:info@circular.co">info@circular.co</a> with
            questions.
          </Typography>
          <Typography className={styles.infoTextEnd}>(978)660-9804</Typography>
          <Spacer size={78} />
          <Divider />
          <Typography className={styles.infoTextEnd}>
            © 2024 Circular Exchange.
          </Typography>
          <Typography className={styles.infoTextEnd}>
            514 High Street
          </Typography>
          <Typography className={styles.infoTextEnd}>
            Palo Alto, CA 94301
          </Typography>
        </div>
      </div>
      <Spacer size={200} />
    </Flex>
  );
}

export default DigitalRfqEmailSummary;
