import { useMemo } from 'react';
import { useVisibility } from 'src/components/layout/Visible';
import { getTdsListBySupplier } from 'src/Query/warehouse';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import { useMetaNumericalPropertyLoading } from 'src/hooks/useMetaNumericalProperty';
import useExpandedFiltersForSupplierList from './useExpandedFilters';

const DEFAULT_PARAMS = {};
const EMPTY_SUPPLIERS = [];

export function useSupplierListFilters({
  page = 1,
  pageSize = 20,
  companySearch,
  resultIndexFilter,
  parseFilters,
  projectId,
  filterSupplierLabels,
  supplierLabels,
  extraKey
} = DEFAULT_PARAMS) {
  const { filterList, resultDetails } = useExpandedFiltersForSupplierList();

  const parsedFilterList = useMemo(
    () =>
      typeof parseFilters === 'function'
        ? filterList.map((filtersSet, i) => parseFilters(filtersSet, i))
        : filterList,
    [parseFilters, filterList]
  );

  const pagedFilters = useMemo(
    () => ({
      filters: parsedFilterList,
      pagination: page ? { page, page_size: pageSize } : undefined,
      supplier_labels: supplierLabels,
      should_update_project_settings: !!supplierLabels,
      project_id: projectId,
      view_settings: {
        filter_supplier_labels: filterSupplierLabels,
        search_company: companySearch,
        result_index__in: resultIndexFilter
      }
    }),
    [
      parsedFilterList,
      page,
      companySearch,
      filterSupplierLabels,
      supplierLabels
    ]
  );
  return [pagedFilters, resultDetails];
}

export default function useSupplierList(params = DEFAULT_PARAMS) {
  const { extraKey } = params;
  const [pagedFilters, resultDetails] = useSupplierListFilters(params);
  // supplier_labels is returned to us from the backend, so we do not need it as the key
  // we just need to know they have changed (extraKey.supplierLabelsKey)
  const pagedFilterKey = useMemo(
    () => ({ ...pagedFilters, extraKey, supplier_labels: undefined }),
    [pagedFilters]
  );

  const visible = useVisibility();
  const isLoadingNumericalProperties = useMetaNumericalPropertyLoading();
  const { data: suppliers, isFetching: isLoadingSuppliers } = useDebouncedQuery(
    {
      queryKey: ['warehouse', 'tds-supplier-list', pagedFilterKey],
      queryFn: () => getTdsListBySupplier(pagedFilters),
      keepPreviousData: true,
      enabled: visible && !isLoadingNumericalProperties
    }
  );
  return {
    suppliers,
    isLoadingSuppliers,
    resultDetails,
    results: suppliers?.results || EMPTY_SUPPLIERS
  };
}
