import React from 'react';
import { Flex, Form, Input, message, Typography } from 'antd';
import Spacer from 'src/components/common/Spacer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { forgotPassword, validateOtp } from 'src/Mutation';
import SignInLayout from './SignUpLayout';
import signInCompanyStyles from './SignUpCompany.module.less';
import SignInButton from './components/SignUpButton';
import styles from './SignUpOtpCode.module.less';

function SignUpOtp() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const email = params.get('email');
  const redirect = params.get('redirect');
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate } = useMutation({
    mutationFn: (data) => validateOtp(data),
    onSuccess: (response) => {
      const { token } = response;
      const redirectLink = params.get('redirect');
      const urlParams = new URLSearchParams({
        token,
        ...(redirectLink && { redirect: redirectLink })
      });
      navigate(`/signup/create-password?${urlParams.toString()}`);
    },
    onError: (e) => {
      messageApi.error(e.message);
    }
  });

  const { mutate: sendOtp, isLoading: isSendingOtp } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      messageApi.success(
        'We have sent you a new code. Please check your email'
      );
    },
    onError: (e) => {
      messageApi.error(e.message);
    }
  });

  const onFinish = ({ token }) => {
    if (token.includes(' ')) {
      messageApi.error('The code must be a 6-digit number');
      return;
    }

    if (Number.isInteger(Number.parseInt(token))) {
      mutate({ email, token });
    } else {
      messageApi.error('The code must be a 6-digit number');
    }
  };

  return (
    <SignInLayout>
      <div style={{ maxWidth: 388 }}>
        <Typography.Title className={signInCompanyStyles.whiteText} level={2}>
          Enter the 6-digit code we emailed you
        </Typography.Title>
        <Typography.Text className={signInCompanyStyles.descriptionText}>
          Verify your email{' '}
          <span style={{ fontWeight: 'bold', color: 'white' }}>{email}</span> so
          we can keep your account secure. Check your spam folder. The code will
          expire in 10 minutes.
        </Typography.Text>
        <Spacer size={40} />
        {contextHolder}
        <Form onFinish={onFinish} form={form} layout="vertical">
          <Form.Item
            rules={[{ required: true }]}
            label="Enter Code"
            name="token"
            className={styles.inputOtp}
          >
            <Input.OTP
              level={6}
              onChange={(value) => {
                if (value && value.length === 6) {
                  form.submit();
                }
              }}
            />
          </Form.Item>
          <Form.Item className="mb-xs">
            <SignInButton
              loading={isSendingOtp}
              onClick={() => sendOtp(email)}
              type="button"
            >
              Resend Code
            </SignInButton>
          </Form.Item>
        </Form>
        <Flex align="center" justify="center">
          <Flex direction="row" align="center">
            <Typography style={{ color: '#FFFFFF' }}>
              Did you use the correct email?{' '}
            </Typography>
            <Typography
              className={styles.goBackLink}
              onClick={() =>
                navigate(
                  `/signup/company?${redirect ? `redirect=${redirect}` : ''}`
                )
              }
            >
              Go Back
            </Typography>
          </Flex>
        </Flex>
      </div>
    </SignInLayout>
  );
}

export default SignUpOtp;
