import useMetaProperty from 'src/hooks/useMetaProperty';
import useNumericalPropertyRelationships from 'src/Query/metaProperty/useNumericalPropertyRelationships';
import usePropertyRelationships from 'src/Query/metaProperty/usePropertyRelationships';

export function useAvailableProperties(materialClass) {
  const propertyRelationships = usePropertyRelationships();
  const numericalPropertyRelationships = useNumericalPropertyRelationships();

  const materialClassPropertyOption = useMetaProperty(
    'material_class',
    materialClass
  );

  // Find the property relationships for the current material option
  const materialClassPropertyRelationships =
    propertyRelationships?.filter(
      (property) =>
        property.object_option.uuid === materialClassPropertyOption?.uuid
    ) ?? [];

  // Find the numerical property relationships for the current material option
  const materialClassPropertyNumericalRelationships =
    numericalPropertyRelationships?.filter(
      (property) =>
        property.object_option.uuid === materialClassPropertyOption?.uuid
    ) ?? [];

  return {
    availablePropertyCode: materialClassPropertyRelationships,
    availableNumericalPropertyForMaterialClass:
      materialClassPropertyNumericalRelationships
  };
}
