import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Col, Form, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getPopulatedNumericalPropertyGroups } from 'src/Query';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import { useAvailableUnitsForType } from 'src/utils/units';
import { useUser } from 'src/utils/authentication';
import { patchUser } from 'src/Mutation';
import useMetaNumericalPropertyTestConditions from 'src/hooks/useMetaNumericalPropertyTestConditions';
import { useKpiSummaryData } from 'src/components/project/explore/summary/helpers';
import { HistogramSlider } from 'src/components/project/explore/charts/MinimalHistogram';

export default function KpiFilter({ title, excludeKpis }) {
  const [filters, setFilters] = useConciergeContextState([
    'explore',
    'filters'
  ]);

  // New filter boxes with no KPI selected don't need to be restored, so we store
  // in local state only
  const [unspecifiedKpiFilters, setUnspecifiedKpiFilters] = useState([]);

  const allKpis = useMetaNumericalProperty();
  return (
    <div className="kpi-filter">
      <Row justify="space-between" align="middle" wrap={false}>
        <Col>
          <h3 className="m-0">{title || 'Specifications'}</h3>
        </Col>
        <Col>
          <Button
            onClick={() => {
              const tempId = `id${Math.random().toString(16).slice(2)}`;
              setUnspecifiedKpiFilters([...unspecifiedKpiFilters, tempId]);
            }}
            size="small"
          >
            <PlusOutlined style={{ color: '#2f54eb' }} />
          </Button>
        </Col>
      </Row>
      {filters?.kpis
        ? Object.keys(filters.kpis)
            .filter(
              excludeKpis
                ? (kpiCode) => !excludeKpis.includes(kpiCode)
                : () => true
            )
            .map((kpiCode) => (
              <SingleKpiFilter
                key={kpiCode}
                kpiCode={kpiCode}
                onClose={() => {
                  const kpiFiltersClone = {
                    ...(filters.kpis || {})
                  };
                  delete kpiFiltersClone[kpiCode];

                  setFilters({
                    ...filters,
                    kpis: kpiFiltersClone
                  });
                }}
              />
            ))
        : null}

      {unspecifiedKpiFilters.map((tempId) => (
        <ExplorePanel
          solid
          className="kpi-filter--single"
          key={tempId}
          pad={12}
        >
          <KpiSelect
            filter={(_group, { code: kpiCode }) =>
              !excludeKpis.includes(kpiCode)
            }
            onSelect={(kpiCode, i) => {
              const kpiObj = allKpis.find(({ code }) => code === kpiCode);

              const kpiInitialValue = {
                // absolute: kpiCode === 'PCRBV',
                units: kpiObj.units,
                unit_type: kpiObj.unit_type,
                // Primary KPIs are set directly in FiltersSidebar.
                // Any KPI selected through here is secondary.
                allow_null: true
              };

              // Add active KPI filter box
              const kpiFiltersClone = {
                ...(filters?.kpis || {})
              };
              kpiFiltersClone[kpiCode] = kpiInitialValue;

              setFilters({
                ...(filters || {}),
                kpis: kpiFiltersClone
              });

              // Remove KpiSelect box
              const unspecifiedKpiFiltersClone = [...unspecifiedKpiFilters];
              unspecifiedKpiFiltersClone.splice(i, 1);
              setUnspecifiedKpiFilters(unspecifiedKpiFiltersClone);
            }}
          />
        </ExplorePanel>
      ))}
    </div>
  );
}
KpiFilter.propTypes = {
  title: PropTypes.node,
  excludeKpis: PropTypes.arrayOf(PropTypes.string)
};

export const KPIS_WITH_TEST_CONDITIONS = ['MFI', 'T1', 'T2', 'T3', 'T4'];

export const numberInputFormatter = (val, { userTyping }) => {
  if (userTyping || !val || Number(val) % 1 === 0) return val;
  return Number(val).toFixed(3);
};

export function SingleKpiFilter({ kpiCode, onClose, isPrimary }) {
  const [kpiValues, setKpiValues] = useConciergeContextState([
    'explore',
    'filters',
    'kpis',
    kpiCode
  ]);

  const kpiObj = useMetaNumericalProperty(kpiCode);

  const kpiData = useKpiSummaryData({ kpiCode });

  const [form] = Form.useForm();

  // Update form values when another component changes them (switching projects, rubber band...)
  useEffect(() => {
    form.setFieldsValue({
      min: null,
      max: null,
      test_conditions: null,
      ...kpiValues
    });
  }, [kpiValues]);

  return (
    <ExplorePanel solid className="kpi-filter--single" pad={12}>
      {!isPrimary && (
        <button
          onClick={onClose}
          type="button"
          className="bare kpi-filter--remove"
        >
          <CloseCircleFilled />
        </button>
      )}
      <div className="kpi-filter--active">
        <Form
          onValuesChange={(_, values) => {
            setKpiValues({
              units: kpiObj?.units,
              ...values,
              unit_type: kpiObj?.unit_type,
              // absolute: kpiCode === 'PCRBV',
              allow_null: !isPrimary
            });
          }}
          form={form}
          initialValues={{
            min: null,
            max: null,
            test_conditions: null,
            units: kpiObj?.units,
            ...kpiValues
          }}
        >
          <Row wrap={false} gutter={[4, 4]}>
            <Col style={{ flexGrow: 1 }}>
              <KpiName codeOrUuid={kpiCode} />
            </Col>
            {!isPrimary && (
              <Col>
                <Form.Item
                  noStyle
                  getValueProps={(value) => ({ value: !value })}
                  getValueFromEvent={(checked) => !checked}
                  name="isToggledOff"
                >
                  <Switch size="small" />
                </Form.Item>
              </Col>
            )}
          </Row>
          {kpiValues && kpiData && (
            <div className="mt-xxs">
              <HistogramSlider specData={kpiData} kpiCode={kpiCode} />
            </div>
          )}
          <Row gutter={[4, 0]}>
            <Col className="minmax" span={9}>
              <div>Min</div>
              <Form.Item noStyle name="min">
                <InputNumber
                  value={kpiValues?.min}
                  size="small"
                  type="number"
                  placeholder="No min"
                  controls={false}
                  formatter={numberInputFormatter}
                />
              </Form.Item>
            </Col>
            <Col className="minmax" span={9}>
              <div>Max</div>
              <Form.Item noStyle name="max">
                <InputNumber
                  value={kpiValues?.max}
                  size="small"
                  type="number"
                  placeholder="No max"
                  controls={false}
                  formatter={numberInputFormatter}
                />
              </Form.Item>
            </Col>
            <Col className="minmax" span={6}>
              <div>Units</div>
              <Form.Item noStyle name="units">
                <KpiUnitSelect codeOrUuid={kpiCode} />
              </Form.Item>
            </Col>
            {KPIS_WITH_TEST_CONDITIONS.includes(kpiCode) && (
              <Col className="minmax" span={24}>
                <div>Test Conditions</div>
                <Form.Item noStyle name="test_conditions">
                  <KpiTestConditionsSelect kpiCode={kpiCode} />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </div>
    </ExplorePanel>
  );
}
SingleKpiFilter.propTypes = {
  kpiCode: PropTypes.string,
  isPrimary: PropTypes.bool,
  onClose: PropTypes.func
};

export function KpiSelect({
  filter = null,
  style = {},
  onSelect,
  selectProps = {}
}) {
  const { data: populatedNumericalPropertyGroups, isLoading } = useQuery(
    ['populated-numerical-property-groups'],
    () => getPopulatedNumericalPropertyGroups()
  );

  if (isLoading) return null;

  return (
    <div style={style}>
      <Select
        style={{ width: '100%' }}
        showSearch
        popupMatchSelectWidth={false}
        optionFilterProp="filterProp"
        options={(populatedNumericalPropertyGroups || [])
          .map((group) => {
            const options = (
              typeof filter === 'function'
                ? group.meta_numerical_properties.filter((kpi) =>
                    filter(group, kpi)
                  )
                : group.meta_numerical_properties
            ).map((mnp) => ({
              label: mnp.name,
              value: mnp.code,
              filterProp: `${group.name} ${mnp.name} ${mnp.code}`
            }));

            // Used to filter out groups without options
            if (options.length === 0) return null;

            return {
              label: group.name,
              options
            };
          })
          // Excluding groups with no options after filtering
          .filter((group) => group !== null)}
        onSelect={onSelect}
        placeholder="Select a spec..."
        {...selectProps}
      />
    </div>
  );
}
KpiSelect.propTypes = {
  filter: PropTypes.func,
  onSelect: PropTypes.func,
  style: PropTypes.object,
  selectProps: PropTypes.object
};

export function KpiName({ codeOrUuid }) {
  const kpiObj = useMetaNumericalProperty(codeOrUuid);

  return !kpiObj ? codeOrUuid : kpiObj.name;
}
KpiName.propTypes = {
  codeOrUuid: PropTypes.string
};

export function useKpiUnit(codeOrUuid) {
  const kpiObj = useMetaNumericalProperty(codeOrUuid);
  return !kpiObj ? null : kpiObj.units;
}
useKpiUnit.propTypes = {
  codeOrUuid: PropTypes.string
};

export function useKpiUnitType(codeOrUuid) {
  const kpiObj = useMetaNumericalProperty(codeOrUuid);
  return !kpiObj ? null : kpiObj.unit_type;
}

useKpiUnitType.propTypes = {
  codeOrUuid: PropTypes.string
};

export function KpiUnitSelect({ codeOrUuid, value, onChange }) {
  const {
    data: user,
    isRefetching: isLoadingUser,
    refetch: refetchUser
  } = useUser();

  const kpiObj = useMetaNumericalProperty(codeOrUuid);
  const availableUnits = useAvailableUnitsForType(kpiObj?.unit_type);

  const queryClient = useQueryClient();
  const { mutate, isMutating } = useMutation({
    mutationFn: patchUser,
    onSuccess: () => {
      refetchUser();
      queryClient.invalidateQueries({
        queryKey: ['numerical-properties']
      });
    },
    onError: (e) => {
      window.console.error('Error updating user', e);
    }
  });

  return (
    <Select
      removeIcon
      suffixIcon={null}
      dropdownStyle={{ width: 100 }}
      size="small"
      disabled={isMutating || isLoadingUser || !kpiObj || !availableUnits}
      value={value}
      onChange={(val) =>
        onChange(val) &&
        mutate({
          id: user.id,
          settings: {
            ...(user.settings || {}),
            unit_preferences: {
              ...(user.settings?.unit_preferences || {}),
              [kpiObj.unit_type]: val
            }
          }
        })
      }
      options={
        availableUnits?.map((unit) => ({
          label: unit,
          value: unit
        })) || []
      }
    />
  );
}
KpiUnitSelect.propTypes = {
  codeOrUuid: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export function KpiTestConditionsSelect({ kpiCode, ...props }) {
  const testConditions = useMetaNumericalPropertyTestConditions(kpiCode);

  return (
    <Select
      disabled={
        !testConditions.length || !KPIS_WITH_TEST_CONDITIONS.includes(kpiCode)
      }
      dropdownStyle={{ minWidth: 200 }}
      size="small"
      defaultValue=""
      options={[
        { label: 'Any', value: '' },
        ...(testConditions
          .filter((o) => o)
          .map((tc) => ({
            label: tc,
            value: tc
          })) || [])
      ]}
      {...props}
    />
  );
}
KpiTestConditionsSelect.propTypes = {
  kpiCode: PropTypes.string
};
