import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Form,
  DatePicker,
  ConfigProvider,
  Flex,
  Select,
  notification
} from 'antd';
import React, { useEffect, useState } from 'react';
import { DATETIME_FORMAT } from 'src/constants';
import classNames from 'classnames';
import LocationInput, {
  parseApiLocation,
  parseInternalLocation
} from 'src/components/form/LocationInput';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import PropertyMultiInput from 'src/components/form/PropertyMultiInput';
import {
  METADATA_PROPERTY_TYPE,
  PRICING_TERMS_OPTIONS
} from 'src/constants/project';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createOrUpdateProject } from 'src/Mutation';
import QuantityWithUnitsInput from 'src/components/form/QuantityWithUnitsInput';
import { SelectableCurrencyPerWeightUnitsInput } from 'src/components/form/SelectableCurrencyUnitsInput';
import { isNil, omitBy } from 'lodash';
import { KpiSelect } from 'src/components/project/explore/filters/KpiFilter';
import { useNavigate } from 'react-router-dom';
import parseApiError from 'src/utils/parseApiError';
import { PlusCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import useRenderKpiRow from 'src/hooks/project/useRenderKpiRow';
import { POSITIVE_NUMBER_REGEX } from 'src/utils/rules';
import DigitalRfqAntdProvider from 'src/pages/project/components/digital-rfq-page/DigitalRfqAntdProvider';
import styles from 'src/pages/project/styles/EditDigitalRfq.module.less';

export default function DigitalRfqPage({ project }) {
  const [form] = Form.useForm();
  const primaryKpis = usePrimaryKpis();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { renderKpiRow } = useRenderKpiRow();

  // We switch to use the new key 'digital_rfq_filter' so we need to check if the new key has value or not
  // If not, we will use the old key 'filters' instead
  const filterData = project.digital_rfq_filter
    ? project.digital_rfq_filter
    : project.filters;

  const [unspecifiedKpiFilters, setUnspecifiedKpiFilters] = useState([]);
  const [currentSecondaryKpis, setCurrentSecondaryKpiFilters] = useState([]);

  const { mutate: updateProject, isLoading: isUpdating } = useMutation({
    mutationFn: createOrUpdateProject,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['project', project.uuid]);
      await queryClient.invalidateQueries(['projects']);
      navigate(`/project/source/${project.uuid}/digital-rfq`);
    },
    onError: (e) => {
      parseApiError(e.detail, form);
      captureException(e);
    }
  });

  useEffect(() => {
    if (filterData?.kpis) {
      setCurrentSecondaryKpiFilters(
        Object.keys(filterData.kpis)
          .filter((kpiCode) => !primaryKpis.includes(kpiCode))
          .sort((a, b) => a.localeCompare(b))
      );
    }
  }, [filterData]);

  const handleUpdatingProject = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const {
        applications,
        certifications,
        color,
        idealSources,
        processingMethod,
        decision_date,
        locations,
        price,
        ...rest
      } = values;
      const updatedData = {
        ...rest,
        digital_rfq_filter: {
          ...rest.digital_rfq_filter,
          properties: {
            [METADATA_PROPERTY_TYPE.APPLICATION]: applications?.codes ?? [],
            [METADATA_PROPERTY_TYPE.CERTIFICATIONS]:
              certifications?.codes ?? [],
            [METADATA_PROPERTY_TYPE.COLOR]: color?.codes ?? [],
            [METADATA_PROPERTY_TYPE.SOURCE_TYPE]: idealSources?.codes ?? [],
            [METADATA_PROPERTY_TYPE.PROCESSING_METHOD]:
              processingMethod?.codes ?? []
          }
        },
        decision_date: decision_date
          ? decision_date.format(DATETIME_FORMAT.DATE)
          : undefined,
        ...(locations && locations?.country && !locations?.uuid
          ? { locations: [parseInternalLocation(locations)] }
          : {}),
        uuid: project.uuid,
        currency: price.currency,
        price: price.value,
        price_weight_units: price.units
      };
      updateProject(omitBy(updatedData, isNil));
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e?.errorFields?.at(0)?.errors
          ? e.errorFields.at(0).errors.at(0)
          : 'Something went wrong. Please try again.'
      });
    }
  };

  const onClickAddSpec = () => {
    const tempId = uuidv4();
    setUnspecifiedKpiFilters((prev) => [...prev, tempId]);
  };

  const onSelectKpi = (kpiCode, i) => {
    setCurrentSecondaryKpiFilters((prev) => [...prev, kpiCode]);
    const unspecifiedKpiFiltersClone = [...unspecifiedKpiFilters];
    unspecifiedKpiFiltersClone.splice(i, 1);
    setUnspecifiedKpiFilters(unspecifiedKpiFiltersClone);
  };

  return (
    <DigitalRfqAntdProvider>
      <div className="mb-2xxl">
        <Row className="mt-lg" justify="space-between" align="middle">
          <Col>
            <Typography.Title level={4} className="mb-xs">
              Edit Digital RFQ
            </Typography.Title>
            <Typography.Paragraph>
              Confirm what you want to take to market.
            </Typography.Paragraph>
          </Col>
          <Col>
            <Button
              onClick={handleUpdatingProject}
              type="primary"
              htmlType="button"
              disabled={isUpdating}
              loading={isUpdating}
            >
              Save Digital RFQ
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <div className="mt-lg">
              <Typography className={styles.generalInformation}>
                General Information
              </Typography>
              <Form
                className="mt-lg"
                form={form}
                initialValues={{
                  name: project.name,
                  description: project.description,
                  additional_shipping_details:
                    project.additional_shipping_details,
                  locations:
                    project.locations.length > 0
                      ? parseApiLocation(project.locations[0])
                      : undefined,
                  decision_date: project.decision_date
                    ? dayjs(project.decision_date)
                    : undefined,
                  pricing_terms: project.pricing_terms,
                  price: {
                    value: project.price,
                    currency: project.currency,
                    units: project.price_weight_units
                  },
                  capacity: project.capacity,
                  capacity_units: project.capacity_units,
                  certifications: {
                    codes:
                      filterData?.properties?.[
                        METADATA_PROPERTY_TYPE.CERTIFICATIONS
                      ],
                    propertyCode: METADATA_PROPERTY_TYPE.CERTIFICATIONS
                  },
                  color: {
                    codes:
                      filterData?.properties?.[METADATA_PROPERTY_TYPE.COLOR],
                    propertyCode: METADATA_PROPERTY_TYPE.COLOR
                  },
                  processingMethod: {
                    codes:
                      filterData?.properties?.[
                        METADATA_PROPERTY_TYPE.PROCESSING_METHOD
                      ],
                    propertyCode: METADATA_PROPERTY_TYPE.PROCESSING_METHOD
                  },
                  applications: {
                    codes:
                      filterData?.properties?.[
                        METADATA_PROPERTY_TYPE.APPLICATION
                      ],
                    propertyCode: METADATA_PROPERTY_TYPE.APPLICATION
                  },
                  idealSources: {
                    codes:
                      filterData?.properties?.[
                        METADATA_PROPERTY_TYPE.SOURCE_TYPE
                      ],
                    propertyCode: METADATA_PROPERTY_TYPE.SOURCE_TYPE
                  },
                  other_info: project.other_info,
                  digital_rfq_filter: filterData
                }}
                layout="vertical"
              >
                <Row gutter={[24, 0]}>
                  <Col span={16}>
                    <Form.Item name="name" label="Project Name">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="decision_date" label="RFQ Deadline">
                      <DatePicker
                        className="border-radius-lg"
                        format={DATETIME_FORMAT.DATE}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      className="m-0"
                      name="description"
                      label="Project Description"
                      rules={[{ max: 2000 }, { required: true }]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                    <Typography
                      className={classNames(styles.secondaryText, 'mb')}
                    >
                      The more details, the better.
                    </Typography>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      rules={[
                        { required: true },
                        {
                          validator: (_, value) => {
                            if (!value?.country) {
                              // eslint-disable-next-line prefer-promise-reject-errors
                              return Promise.reject('Please select a location');
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                      name="locations"
                      label="Primary Shipping Destination"
                    >
                      <LocationInput />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="capacity" label="Quantity">
                      <QuantityWithUnitsInput
                        className={styles.quantityInput}
                        unitsFieldName="capacity_units"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      className="m-0"
                      name="additional_shipping_details"
                      label="Additional Shipping Details"
                      rules={[{ max: 2000 }, { required: false }]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                    <Typography
                      className={classNames(styles.secondaryText, 'mb')}
                    >
                      Please list additional shipping locations and logistics
                      details that will help suppliers provide an accurate
                      quote.
                    </Typography>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value?.value) {
                              if (!POSITIVE_NUMBER_REGEX.test(value.value)) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject(
                                  'Please enter a valid price'
                                );
                              }
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                      name="price"
                      label="Price Target"
                    >
                      <SelectableCurrencyPerWeightUnitsInput
                        className={styles.inputPriceTarge}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="pricing_terms"
                      label="Pricing Terms (optional)"
                    >
                      <Select options={PRICING_TERMS_OPTIONS} />
                    </Form.Item>
                  </Col>
                  <ConfigProvider
                    theme={{
                      token: {
                        controlHeight: 32
                      },
                      components: {
                        Form: {
                          itemMarginBottom: 0
                        }
                      }
                    }}
                  >
                    {primaryKpis && primaryKpis.length > 0 ? (
                      <Col span={24}>
                        <Typography
                          className={classNames(
                            'mt-xxl text',
                            styles.primaryText
                          )}
                        >
                          Primary Specifications
                        </Typography>
                        <Typography className={styles.secondaryText}>
                          Imported from Explore
                        </Typography>
                        <Row className="mt-lg">
                          <Col span={9}>
                            <Typography className={styles.propertyTitle}>
                              Property
                            </Typography>
                          </Col>
                          <Col span={3}>
                            <Typography className={styles.propertyTitle}>
                              Min.
                            </Typography>
                          </Col>
                          <Col span={3}>
                            <Typography className={styles.propertyTitle}>
                              Max.
                            </Typography>
                          </Col>
                          <Col span={3}>
                            <Typography className={styles.propertyTitle}>
                              Units.
                            </Typography>
                          </Col>
                          <Col span={6}>
                            <Typography className={styles.propertyTitle}>
                              Testing Method
                            </Typography>
                          </Col>
                        </Row>
                        {primaryKpis.map((kpiCode) => renderKpiRow(kpiCode))}
                      </Col>
                    ) : null}
                    <Col span={24}>
                      <Flex align="end" justify="space-between">
                        <div>
                          <Typography
                            className={classNames(
                              'mt-xxl text',
                              styles.primaryText
                            )}
                          >
                            Secondary Specifications
                          </Typography>
                          <Typography className={styles.secondaryText}>
                            Imported from Explore
                          </Typography>
                        </div>
                      </Flex>
                      {currentSecondaryKpis?.map((kpiCode) =>
                        renderKpiRow(kpiCode, true)
                      )}
                      {unspecifiedKpiFilters.map((tempId) => (
                        <ExplorePanel
                          solid
                          className="kpi-filter--single mt"
                          key={tempId}
                          pad={12}
                        >
                          <KpiSelect
                            filter={(_group, { code: kpiCode }) =>
                              !primaryKpis.includes(kpiCode)
                            }
                            onSelect={onSelectKpi}
                          />
                        </ExplorePanel>
                      ))}
                      <Button
                        onClick={onClickAddSpec}
                        type="link"
                        className="mt-md"
                      >
                        <PlusCircleOutlined /> Add specification
                      </Button>
                    </Col>
                  </ConfigProvider>
                  <Col span={24}>
                    <Typography
                      className={classNames('mt-xxl text', styles.primaryText)}
                    >
                      Additional Information
                    </Typography>
                    <Form.Item
                      className="mt-lg"
                      label="Required Certifications"
                      name="certifications"
                    >
                      <PropertyMultiInput />
                    </Form.Item>
                    <Form.Item name="color" label="Color">
                      <PropertyMultiInput />
                    </Form.Item>
                    <Form.Item
                      name="processingMethod"
                      label="Processing Method"
                    >
                      <PropertyMultiInput />
                    </Form.Item>
                    <Form.Item name="applications" label="Application">
                      <PropertyMultiInput />
                    </Form.Item>
                    <Form.Item name="idealSources" label="Ideal Sources">
                      <PropertyMultiInput />
                    </Form.Item>
                    <Form.Item
                      rules={[{ max: 2000 }]}
                      name="other_info"
                      label="Other Info"
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </DigitalRfqAntdProvider>
  );
}

DigitalRfqPage.propTypes = {
  project: PropTypes.object.isRequired
};
