import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './SignUpButton.module.less';

function SignUpButton({ children, className, ...rest }) {
  return (
    <Button className={classNames(styles.button, className ?? '')} {...rest}>
      {children}
    </Button>
  );
}

SignUpButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string
};

export default SignUpButton;
