import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { MATERIAL_CLASS_MAPPED } from 'src/constants';

export default function useMaterialClass() {
  const [materialClass] = useConciergeContextState([
    'explore',
    'filters',
    'material_class'
  ]);
  const [formCode] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);

  // Some existing projects don't have material_class in their filters yet
  return materialClass || MATERIAL_CLASS_MAPPED[formCode];
}
