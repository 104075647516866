import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { CloudDownloadOutlined } from '@ant-design/icons';
/* eslint-disable import/no-unresolved */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
/* eslint-enable import/no-unresolved */

import { useMultipleMarketPriceSeries } from 'src/Query/marketPrice';
import AnalyticsTile from 'src/components/analytics/AnalyticsTile';
import { currencyValuePrice } from 'src/utils/currency';
import { sourcesListDetailsArray, sourcesListNamesArray } from './utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement
);

const colors = [
  '#D48806',
  '#0D33CC',
  '#13C2C2',
  '#EB2F96',
  '#2F54EB',
  '#08979C',
  '#EB2F96'
];

const lineChartOptions = {
  scales: {
    x: {
      type: 'time',
      ticks: {
        maxTicksLimit: 5
      },
      border: {
        dash: [2, 2]
      }
    },
    y: {
      position: 'right',
      ticks: {
        maxTicksLimit: 5
      },
      border: {
        dash: [2, 2]
      }
    }
  },
  elements: {
    line: {
      borderWidth: 3
    }
  },
  interaction: {
    intersect: false
  },
  plugins: {
    legend: {
      display: false
    }
  },
  onHover: (event, chartElements) => {
    // eslint-disable-next-line no-param-reassign
    event.native.target.style.cursor = chartElements.length
      ? 'pointer'
      : 'default';
  }
};

const dataSkeleton = {
  datasets: [
    {
      data: [
        { x: 0, y: 0 },
        { x: 1, y: 1 }
      ],
      fill: false,
      borderColor: '#eee',
      tension: 0.05
    }
  ]
};

const skeletonColor = '#e8e8e8';

const lineChartOptionsSkeleton = {
  // animations: false,
  scales: {
    x: {
      type: 'time',
      ticks: {
        maxTicksLimit: 5,
        display: false
      },
      grid: {
        display: false
      },
      border: {
        color: skeletonColor
      }
    },
    y: {
      ticks: {
        maxTicksLimit: 5,
        display: false
      },
      border: {
        color: skeletonColor
      }
    }
  },
  elements: {
    point: {
      pointStyle: false
    },
    line: {
      borderWidth: 1
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

const AVG_LINE_STEPS = 40;

export const priceMid = (price) =>
  (price && (Number(price.high) + Number(price.low)) / 2) || undefined;

const generateCsv = (seriesList, titleList) => {
  const descendingCurves = seriesList
    .filter((p) => !!p)
    .map((series) => [...series.curve].reverse());
  let results = `timestamp,${titleList.join(',')}\n`;
  const maxLength = Math.max(...descendingCurves.map((curve) => curve.length));
  if (maxLength) {
    const longestCurve = descendingCurves.find((p) => p.length === maxLength);
    longestCurve.forEach((point, line) => {
      results += `${point.timestamp},${descendingCurves
        .map((curve) => priceMid(curve[line]) || '')
        .join(',')}\n`;
    });
  }
  return new Blob([results], { type: 'text/csv;charset=utf-8' });
};

export default function AnalyticsTickerLineChart({
  tickers,
  colorSeed,
  tileTitle,
  allowDownload = true,
  highlightIndex = null,
  old = false
}) {
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const { isLoading, seriesList } = useMultipleMarketPriceSeries(tickers, old);

  const data = useMemo(() => {
    const datasets = seriesList.map((series, index) => ({
      data:
        series?.curve?.map((point) => ({
          x: dayjs(point.timestamp).unix() * 1000,
          y: priceMid(point)
        })) || [],
      fill: false,
      borderColor: tickers[index].color || colors[index + (colorSeed || 0)],
      label: tickers[index].title,
      borderDash: tickers[index].dashed ? [5, 5] : [],
      borderWidth: tickers[index].borderWidth || 1,
      tension: 0.05,
      pointBorderWidth: 0,
      pointHoverBorderWidth: 0,
      pointRadius: 3,
      pointBackgroundColor: 'transparent',
      pointHoverBackgroundColor: tickers[index].color,
      useInAverage: tickers[index].useInAverage
    }));

    // We don't want to average all the lines (eg circular cohorts)
    const tickersToAverage = datasets.filter((dataset) => dataset.useInAverage);

    if (tickersToAverage.length > 1) {
      // Split X values into bins and calculate avg for each bin
      const minX = Math.min(
        ...tickersToAverage.map((dataset) =>
          Math.min(...dataset.data.map((point) => point.x))
        )
      );
      const maxX = Math.max(
        ...tickersToAverage.map((dataset) =>
          Math.max(...dataset.data.map((point) => point.x))
        )
      );
      const binSize = (maxX - minX) / AVG_LINE_STEPS;
      const avgData = Array.from({ length: AVG_LINE_STEPS }, (_, i) => {
        const binStart = minX + i * binSize;
        const binEnd = binStart + binSize;

        let sumY = 0;
        let count = 0;

        tickersToAverage.forEach((dataset) => {
          dataset.data.forEach((point) => {
            if (point.x >= binStart && point.x < binEnd) {
              sumY += point.y;
              count += 1;
            }
          });
        });

        return count > 0
          ? { x: (binStart + binEnd) / 2, y: sumY / count }
          : null;
      }).filter((point) => point !== null); // Remove empty bins

      datasets.push({
        data: avgData,
        fill: false,
        borderColor: '#000',
        label: 'Average',
        borderDash: [5, 5],
        borderWidth: 1.5,
        tension: 0.05,
        pointBorderWidth: 0,
        pointHoverBorderWidth: 0,
        pointRadius: 3,
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: '#000'
      });
    }

    return {
      datasets
    };
  }, [seriesList, highlightIndex]);

  const currency = useMemo(() => seriesList[0]?.currency, [seriesList]);

  const extendedLineChartOptions = useMemo(
    () => ({
      ...lineChartOptions,
      plugins: {
        ...lineChartOptions.plugins,
        tooltip: {
          enabled: true,
          position: 'nearest',
          callbacks: {
            title: ([tooltipItem]) =>
              currencyValuePrice(tooltipItem.formattedValue, currency),
            label: (tooltipItem) => null,
            afterTitle: ([tooltipItem]) => tooltipItem.dataset.label,
            footer: ([tooltipItem]) =>
              dayjs(tooltipItem.label).format('MMMM D, YYYY')
          }
        }
      }
    }),
    [lineChartOptions, currency]
  );

  const seriesDates = useMemo(
    () =>
      seriesList
        .map((series) => {
          if (!series?.latest?.timestamp) return undefined;

          return new Date(series.latest.timestamp);
        })
        .filter((p) => !!p),
    [seriesList]
  );

  const latestDate = useMemo(
    () =>
      seriesDates.length > 0
        ? dayjs(Math.max(...seriesDates)).format('MMM DD, YYYY' || '')
        : undefined,
    [seriesDates]
  );

  const titles = tickers.map((ticker, index) => ({
    text: ticker.title,
    color: ticker.color || colors[index + (colorSeed || 0)]
  }));
  const tickersArray = seriesList?.map((p) => p?.tickers) || [];
  const sourceDetails = sourcesListDetailsArray(tickersArray);
  const source = sourcesListNamesArray(tickersArray);
  const download = async () => {
    try {
      const titleList = titles.map((t) => t.text);
      setDownloadingCsv(true);
      const blob = generateCsv(seriesList, titleList);
      saveAs(blob, `${titleList.join('--')} ${latestDate}.csv`);
    } finally {
      setDownloadingCsv(false);
    }
  };

  return (
    <AnalyticsTile
      tileTitle={tileTitle}
      source={source}
      sourceDetails={sourceDetails}
      latest={latestDate}
      titles={titles}
      action={
        allowDownload ? <CloudDownloadOutlined onClick={download} /> : null
      }
    >
      {isLoading ? (
        <Line data={dataSkeleton} options={lineChartOptionsSkeleton} />
      ) : (
        <Line data={data} options={extendedLineChartOptions} />
      )}
    </AnalyticsTile>
  );
}

AnalyticsTickerLineChart.propTypes = {
  tickers: PropTypes.arrayOf(PropTypes.object),
  colorSeed: PropTypes.number,
  tileTitle: PropTypes.string,
  allowDownload: PropTypes.bool,
  highlightIndex: PropTypes.number,
  old: PropTypes.bool
};
