import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Select } from 'antd';
import QuantityWithUnitsInput from 'src/components/form/QuantityWithUnitsInput';
import {
  currencySymbol,
  formatterInputCurrency,
  parserInputCurrency,
  supportCurrencyCodes
} from 'src/utils/currency';

const currencyOptions = supportCurrencyCodes.map((o) => ({
  label: o,
  value: o
}));

export default function PriceQuoteForm({
  onValuesChange,
  initialCurrencyValue
}) {
  const [form] = Form.useForm();

  const selectedUnits = Form.useWatch('units', form);
  const selectedCurrency = Form.useWatch('currency', form);
  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(_, values) => onValuesChange(values)}
    >
      <Form.Item label="Unit Price" name="price">
        <QuantityWithUnitsInput
          addonBefore={
            <Form.Item
              initialValue={initialCurrencyValue || 'USD'}
              name="currency"
              rules={[{ required: true }]}
              noStyle
            >
              <Select
                // style={{ width: 65 }}
                options={currencyOptions}
              />
            </Form.Item>
          }
          unitsFieldName="units"
          per
        />
      </Form.Item>
      <Form.Item label="Estimated Freight Cost" name="freight_per_unit">
        <InputNumber
          addonBefore={currencySymbol(selectedCurrency)}
          addonAfter={`/${selectedUnits}`}
          min={0}
          formatter={formatterInputCurrency}
          parser={parserInputCurrency}
        />
      </Form.Item>
      <Form.Item dependencies={['units']} label="Pricing Terms" name="terms">
        <Select
          options={[
            { label: 'EXW', value: 'EXW' },
            { label: 'FOB', value: 'FOB' },
            { label: 'FCA', value: 'FCA' },
            { label: 'CFR', value: 'CFR' },
            { label: 'CPT', value: 'CPT' },
            { label: 'DDP', value: 'DDP' },
            { label: 'DPU', value: 'DPU' },
            { label: 'FAS', value: 'FAS' },
            { label: 'CIF', value: 'CIF' },
            { label: "Don't know", value: '' }
          ]}
        />
      </Form.Item>
    </Form>
  );
}

PriceQuoteForm.propTypes = {
  onValuesChange: PropTypes.func
};
