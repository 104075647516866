import { useMemo } from 'react';

import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { useAvailableProperties } from './useAvailableProperties';
import usePrimaryKpis from './usePrimaryKpis';

// If all==true, will return all possible secondary KPIs
// Otherwise returns secondary KPIs currently used in filters
export const useSecondaryKpis = (all = false) => {
  const [materialClass] = useConciergeContextState([
    'explore',
    'filters',
    'material_class'
  ]);
  const [allKpiFilters] = useConciergeContextState([
    'explore',
    'filters',
    'kpis'
  ]);

  const { availableNumericalPropertyForMaterialClass } =
    useAvailableProperties(materialClass);
  const primaryKpis = usePrimaryKpis();

  const activeSecondaryKpis = useMemo(() => {
    if (!allKpiFilters) return [];

    return Object.keys(allKpiFilters).filter(
      (kpiCode) => !primaryKpis.includes(kpiCode)
    );
  }, [allKpiFilters, materialClass, primaryKpis]);

  const allPossibleSecondaryKpis = useMemo(() => {
    if (!availableNumericalPropertyForMaterialClass) return [];

    return availableNumericalPropertyForMaterialClass
      .map((property) => property.subject_property.code)
      .filter((property) => !primaryKpis.includes(property));
  }, [availableNumericalPropertyForMaterialClass, materialClass, primaryKpis]);

  if (all) return allPossibleSecondaryKpis;

  return activeSecondaryKpis;
};
