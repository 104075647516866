import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Alert,
  Row,
  Col,
  Popover,
  Typography,
  Flex,
  Steps,
  Skeleton,
  Form,
  Input,
  Divider,
  notification
} from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProject } from 'src/Query';
import { useUser } from 'src/utils/authentication';
import Logo from 'src/components/Logo';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { SUPPLIER_SUBMISSION_STEPS } from 'src/constants/supplier';
import useEditSupplierProfile from 'src/pages/supplier/useEditSupplierProfile';
import useManageTds from 'src/pages/supplier/useManageTds';
import ShowHide from 'src/components/utils/ShowHide';
import useMetaProperty from 'src/hooks/useMetaProperty';
import PriceQuoteForm from 'src/components/supplier/PriceQuoteForm';
import DigitalRfqSubmitted from 'src/pages/supplier/DigitalRfqSubmitted';
import QuoteDetailStep from './QuoteDetail';

import './DigitalRfqStepContainer.less';

const BUTTON_CONTENT_STEP = {
  0: 'Submit & Continue',
  1: 'Submit & Continue'
};

export default function DigitalRfqStepContainer() {
  const { data: user } = useUser();
  const { projectId } = useParams();

  const [supplierStep, setSupplierStep] = useState(
    SUPPLIER_SUBMISSION_STEPS.QUOTE_DETAILS
  );
  const [selectedTdsId, setSelectedTdsId] = useState();

  const { data: project, error } = useQuery(
    ['project', projectId],
    () => getProject(projectId),
    { enabled: !!projectId }
  );

  const nextStep = (data) => {
    if (data?.uuid) {
      setSelectedTdsId(data.uuid);
    }
    setSupplierStep((currentStep) => currentStep + 1);
  };

  const {
    form: supplierForm,
    FormComponent,
    ErrorAlert: SupplierErrorAlert,
    isMutating: supplierFormLoading
  } = useEditSupplierProfile({
    supplierId: user?.company?.uuid,
    hideTdsUpload: true,
    afterSubmit: nextStep
  });

  const materialType = useMetaProperty('type', project?.filters?.type_code__in);
  const materialForm = useMetaProperty('form', project?.filters?.form_code__in);
  const defaults = useMemo(
    () => ({
      material_properties_ids: {
        type: materialType?.uuid,
        form: materialForm?.uuid
      }
    }),
    [materialType?.uuid, materialForm?.uuid]
  );

  const [priceQuotes, setPriceQuotes] = useState();

  const parseTdsParams = useCallback(
    (values) => {
      const locationsWithPriceQuotes = values.locations.map((loc, i) => ({
        ...loc,
        price_logs:
          priceQuotes
            ?.filter(
              ({ locationIndex, price }) => price > 0 && locationIndex === i
            )
            // No need to send locaitonIndex to API
            .map(({ locationIndex, ...priceQuote }) => priceQuote) || []
      }));
      return {
        ...values,
        locations: locationsWithPriceQuotes
      };
    },
    [priceQuotes]
  );

  const {
    FormComponent: TdsFormComponent,
    form: tdsForm,
    ErrorAlert,
    isMutating: tdsFormLoading
  } = useManageTds({
    afterSubmit: nextStep,
    defaults,
    additionalTdsParams: {
      send_email: true,
      projects: [projectId]
    },
    parseTdsParams
  });

  const locations = Form.useWatch('locations', tdsForm);

  const loading = supplierFormLoading || tdsFormLoading;

  const onSubmit = () => {
    tdsForm.submit();
    supplierForm.submit();
  };

  const onChangeStep = async (step) => {
    if (supplierStep === SUPPLIER_SUBMISSION_STEPS.REVIEW_SUBMIT && !step) {
      onSubmit();
      return;
    }
    try {
      if (supplierStep === SUPPLIER_SUBMISSION_STEPS.QUOTE_DETAILS) {
        await tdsForm.validateFields();
        tdsForm.submit();
      } else if (supplierStep === SUPPLIER_SUBMISSION_STEPS.SUPPLIER_INFO) {
        await supplierForm.validateFields();
        supplierForm.submit();
      }
    } catch (e) {
      if (e?.errorFields?.length) {
        notification.error({
          message: 'Error',
          description: 'Please fill out all required fields.'
        });
      }
    }
  };

  const ContinueButton = useMemo(
    () =>
      BUTTON_CONTENT_STEP[supplierStep] ? (
        <Button
          onClick={() => onChangeStep()}
          type="primary"
          disabled={!project || loading}
          loading={loading}
        >
          {BUTTON_CONTENT_STEP[supplierStep]}
        </Button>
      ) : null,
    [BUTTON_CONTENT_STEP, supplierStep, project, loading, onChangeStep]
  );

  if (error)
    return (
      <Alert
        type="error"
        description="Error fetching project"
        action={
          <Link to="https://circular.co">
            <Button>Go to homepage</Button>
          </Link>
        }
      />
    );

  if (user && !user.company) {
    return (
      <div style={{ padding: 64 }} className="digital-rfq">
        Your user is incorrectly setup. Please contact us to resolve the
        problem.
      </div>
    );
  }

  if (!project) return <Skeleton />;
  return (
    <div className="digital-rfq">
      <Row
        className="digital-rfq--header"
        justify="space-between"
        align="middle"
      >
        <Col lg={8}>
          <Flex align="center" className="gap-xs">
            <Logo />
            <Popover
              title="How it works"
              content={
                <>
                  <Typography.Paragraph>
                    The information you provide in response to this request for
                    quote will be shared directly with the buyer. The price
                    quote you provide will be considered relevant only for this
                    specific opportunity.
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    In addition, Circular.co will use your product
                    specifications to match you to future opportunities with
                    buyers on our platform. The more information you provide
                    about the products you sell, the more likely you are to
                    surface in search results.
                  </Typography.Paragraph>
                </>
              }
            >
              <Button type="text">
                <Typography.Text strong type="secondary">
                  <QuestionCircleOutlined />
                  <span className="show-lg"> How does it work?</span>
                </Typography.Text>
              </Button>
            </Popover>
          </Flex>
        </Col>
        <Col xs={0} lg={8}>
          <Steps
            progressDot
            current={supplierStep}
            items={[
              {
                title: 'Quote Details'
              },
              {
                title: 'Supplier Info'
              },
              {
                title: 'Confirmation'
              }
            ]}
            direction="horizontal"
            className="supplier-rfq--steps"
          />
        </Col>
        <Col lg={8}>
          <Flex align="center" justify="end" className="gap">
            {ContinueButton}
          </Flex>
        </Col>
      </Row>
      <ShowHide hide={supplierStep !== SUPPLIER_SUBMISSION_STEPS.QUOTE_DETAILS}>
        <QuoteDetailStep project={project}>
          {ErrorAlert}
          {TdsFormComponent}
          <Typography.Title level={4}>Price Quote</Typography.Title>
          <Alert
            type="info"
            showIcon
            description="TDSs with a price quote are more likely to be selected."
            className="mb-sm"
          />
          {locations?.length > 1 && (
            <Alert
              type="info"
              showIcon
              description="Since you've specified multiple shipment origins, you may provide separate quotes for each location."
              className="mb-sm"
            />
          )}
          {locations?.length > 1 ? (
            locations?.map((loc, i) => (
              <React.Fragment
                key={`${loc.country}-${loc.state}-${loc.city}-${loc.zip}`}
              >
                <Form layout="vertical">
                  <Form.Item label="Shipment Origin">
                    <Input value={loc.address} readOnly disabled />
                  </Form.Item>
                </Form>
                <PriceQuoteForm
                  initialCurrencyValue={project?.currency}
                  onValuesChange={(values) =>
                    setPriceQuotes((existingQuotes) => {
                      const existingQuoteIndex = existingQuotes
                        ? existingQuotes.findIndex(
                            ({ locationIndex }) => locationIndex === i
                          )
                        : -1;

                      const newQuotes = [...(existingQuotes || [])];

                      if (existingQuoteIndex >= 0) {
                        newQuotes[existingQuoteIndex] = {
                          date: dayjs().format('YYYY-MM-DD'),
                          ...values,
                          locationIndex: i
                        };
                      } else {
                        newQuotes.push({
                          date: dayjs().format('YYYY-MM-DD'),
                          ...values,
                          locationIndex: i
                        });
                      }

                      return newQuotes;
                    })
                  }
                />
                {i < locations.length + 1 && <Divider />}
              </React.Fragment>
            ))
          ) : (
            <PriceQuoteForm
              initialCurrencyValue={project?.currency}
              onValuesChange={(values) =>
                setPriceQuotes([
                  {
                    date: dayjs().format('YYYY-MM-DD'),
                    ...values,
                    locationIndex: 0
                  }
                ])
              }
            />
          )}
          {ErrorAlert}
          <div className="hide-lg">{ContinueButton}</div>
        </QuoteDetailStep>
      </ShowHide>

      <ShowHide hide={supplierStep !== SUPPLIER_SUBMISSION_STEPS.SUPPLIER_INFO}>
        <Flex align="center" justify="center">
          <div className="supplier-rfq--containerInfo">
            <Typography.Title level={3}>Supplier Info</Typography.Title>
            <Typography.Paragraph color="secondary" className="mb-xxl">
              Add or confirm the information we have for your organization.
            </Typography.Paragraph>
            {SupplierErrorAlert}
            {FormComponent}
            {SupplierErrorAlert}
            <div className="hide-lg">{ContinueButton}</div>
          </div>
        </Flex>
      </ShowHide>

      <ShowHide hide={supplierStep !== SUPPLIER_SUBMISSION_STEPS.REVIEW_SUBMIT}>
        <DigitalRfqSubmitted tdsId={selectedTdsId} />
      </ShowHide>
    </div>
  );
}
