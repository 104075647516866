import { Alert, Button, Col, Row, Typography } from 'antd';
import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { useTdsTable } from 'src/components/project/source/TdsTable';
import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import SampleTestsIcon from 'src/components/icons/SampleTests';
import ComplianceIcon from 'src/components/icons/Compliance';
import ContractTermsIcon from 'src/components/icons/ContractTerms';
import { useTestingStepComplete } from 'src/pages/project/components/buyer-testing-setup/TestingStep';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import { useComplianceStepComplete } from 'src/pages/project/components/buyer-testing-setup/ComplianceStep';
import useGetProjectContractTerms from 'src/Query/useGetProjectContractTerms';

export default function ProcureOverview() {
  const { activeProject } = useSidebarContext();

  const {
    data: filteredRecords,
    isLoading: isLoadingFilteredRecords,
    queryKey
  } = useProcureTdss();

  const { data: projectContractTerms } = useGetProjectContractTerms(
    activeProject?.uuid
  );

  const queryClient = useQueryClient();
  const setQueryData = useCallback(
    (fn) => queryClient.setQueryData(queryKey, fn),
    [queryKey]
  );
  const invalidateQueryData = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey
      }),
    [queryKey]
  );

  const { TableComponent } = useTdsTable({
    tdss: filteredRecords?.results || [],
    project: filteredRecords?.meta_data?.project,
    showRfqRow: false,
    isLoading: isLoadingFilteredRecords,
    filterColumns: ['company_name', 'price.price', 'total_cost'],
    setQueryData,
    invalidateQueryData,
    extraColumns: [
      {
        title: 'Testing',
        key: 'testing_status',
        width: 100,
        render: () => (
          <div className="procure-column">
            <Typography.Text type="secondary">--</Typography.Text>
          </div>
        )
      },
      {
        title: 'Compliance',
        key: 'compliance_status',
        width: 100,
        render: () => (
          <div className="procure-column">
            <Typography.Text type="secondary">--</Typography.Text>
          </div>
        )
      },
      {
        title: 'Terms',
        key: 'terms_status',
        width: 100,
        render: () => (
          <div className="procure-column">
            <Typography.Text type="secondary">--</Typography.Text>
          </div>
        )
      }
    ],
    localSort: true
  });

  const testingStepComplete = useTestingStepComplete(activeProject);
  const complianceStepComplete = useComplianceStepComplete(activeProject);

  return (
    <div className="procure-overview">
      <Row className="mb-md" wrap={false} align="middle" gutter={8}>
        <Col flex="1">
          <Typography.Title level={4} className="mt-lg mb-sm">
            <Typography.Text type="secondary">Procure / </Typography.Text>
            Overview
          </Typography.Title>
        </Col>
      </Row>
      <Row className="mb-lg" gutter={16}>
        <Col span={8}>
          <Alert
            type="info"
            className="vertical"
            message={
              <>
                <SampleTestsIcon /> Sample Tests
              </>
            }
            description="Review specifications for sample testing and confirm shipping details for sample delivery."
            action={
              <Link to="./testing">
                {testingStepComplete ? (
                  <Button className="mt-lg" size="small">
                    View Testing Details
                  </Button>
                ) : (
                  <Button type="primary" className="mt-lg" size="small">
                    Confirm Testing Details
                  </Button>
                )}
              </Link>
            }
          />
        </Col>
        <Col span={8}>
          <Alert
            type="info"
            className="vertical"
            message={
              <>
                <ComplianceIcon /> Compliance
              </>
            }
            description="Upload all compliance documentation for suppliers to review and complete."
            action={
              <Link to="./compliance">
                {complianceStepComplete ? (
                  <Button className="mt-lg" size="small">
                    View Documents
                  </Button>
                ) : (
                  <Button type="primary" className="mt-lg" size="small">
                    Add Compliance Docs
                  </Button>
                )}
              </Link>
            }
          />
        </Col>
        <Col span={8}>
          <Alert
            type="info"
            className="vertical"
            message={
              <>
                <ContractTermsIcon /> Contract Terms
              </>
            }
            description="Confirm contract details, request pre-shipment QA, and determine price benchmarking."
            action={
              <Link to="./contract-terms">
                {projectContractTerms?.length ? (
                  <Button className="mt-lg" size="small">
                    Review Contract Terms
                  </Button>
                ) : (
                  <Button type="primary" className="mt-lg" size="small">
                    Request Contract Terms
                  </Button>
                )}
              </Link>
            }
          />
        </Col>
      </Row>
      {TableComponent}
    </div>
  );
}
