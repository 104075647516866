import { Row, Col, Button, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendEmails } from 'src/Mutation';
import 'src/pages/project/styles/ConfirmEmailSend.less';
import Spacer from 'src/components/common/Spacer';
import ReceiveEmailSupplierTable from './ReceiveEmailSupplierTable';

export function ConfirmEmailSend({
  project,
  drfqLocked,
  templatesLocked,
  emailsSent
}) {
  const [messageApi] = message.useMessage();
  const [sentStatus, setSentStatus] = useState(emailsSent);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { mutate: sendEmailsMutation, isLoading: updating } = useMutation({
    mutationFn: sendEmails,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project', projectId]);
      queryClient.invalidateQueries(['projects']);
      setSentStatus(true);
    },
    onError: (e) => {
      messageApi.error('Error sending emails');
    }
  });

  return (
    <div className="confirm-email-send">
      {sentStatus ? (
        <Row className="mt-lg" justify="space-between" align="middle">
          <Col>
            <div className="panel">
              <h1>Digital RFQ Sent</h1>
              <p>
                We have sent your Digital RFQ to prioritized supplier list.
                Continue to Manage Quotes to track progress, answer questions,
                and evaluate supplier quotes.
              </p>
              <Button
                onClick={() => navigate('../quotes')}
                type="primary"
                htmlType="button"
              >
                Continue to Manage Quotes
              </Button>
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="mt-lg" justify="space-between" align="middle">
          <Col>
            <div className="panel">
              <h1>Confirm Digital RFQ Send</h1>
              <p>
                {!drfqLocked || !templatesLocked
                  ? 'Approve the Digital RFQ and Email Templates before sending.'
                  : 'Click below to send the Digital RFQ to the prioritized supplier list.'}
              </p>
              <Button
                onClick={() =>
                  sendEmailsMutation({
                    project_uuid: project.uuid,
                    task: 'initial_outreach'
                  })
                }
                size="large"
                type="primary"
                htmlType="button"
                disabled={!drfqLocked || !templatesLocked || updating}
              >
                {updating ? 'Sending...' : 'Send Digital RFQ'}
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <Spacer size={24} />
      <ReceiveEmailSupplierTable />
    </div>
  );
}

ConfirmEmailSend.propTypes = {
  project: PropTypes.object,
  templatesLocked: PropTypes.bool,
  drfqLocked: PropTypes.bool,
  emailsSent: PropTypes.bool
};

export default ConfirmEmailSend;
