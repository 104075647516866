import { useMemo } from 'react';
import useGetRegions from './useGetRegions';

export default function useGetCountries() {
  const { data: regions } = useGetRegions();
  const countries = useMemo(
    () =>
      regions?.flatMap((r) =>
        r.countries.map((c) => ({
          code: c.code,
          name: c.name,
          region_code: r.code
        }))
      ),
    [regions]
  );
  return countries || [];
}

export function useGetCountriesCodeMap() {
  const countries = useGetCountries();
  const result = {};
  countries.forEach((c) => {
    result[c.code] = c;
  });
  return result;
}
