export const DATETIME_FORMAT = {
  DATE: 'YYYY-MM-DD',
  FULL_DATE: 'MMMM D, YYYY'
};

export const SUPPLIER_CONFIGURATION_STEPS = {
  TESTING: 0,
  COMPLIANCE: 1,
  CONTRACT_TERMS: 2,
  REVIEW_SEND: 3
};

export const MATERIAL_CLASS_MAPPED = {
  resin_pellets: 'plastic_resin',
  bales: 'plastic_bales',
  regrind_flakes: 'plastic_flake'
};

export const PROPERTY_RELATIONSHIP_TYPES = {
  APPLIES_TO: 'applies_to',
  IS_PRIMARY_PROPERTY: 'is_primary_property'
};
