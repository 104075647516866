export function getTdsName(tds) {
  return tds?.sku
    ? tds.sku
    : (tds?.tds_id ?? tds.uuid).slice(0, 8) ?? '(unknown)';
}

export function getLatestPriceLog(priceLogs) {
  if (!priceLogs) return null;
  return priceLogs.reduce((acc, current) => {
    if (!acc) return current;
    if (new Date(current.date) > new Date(acc.date)) return current;
    return acc;
  }, null);
}
