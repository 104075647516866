import { Alert, Button, Col, notification, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { currencySymbolMap } from 'src/utils/currency';
import pageStyles from 'src/pages/project/styles/EditDigitalRfq.module.less';
import classNames from 'classnames';
import { METADATA_PROPERTY_TYPE } from 'src/constants/project';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import {
  prettyNumberPrice,
  prettyNumberRound
} from 'src/components/utils/prettyNumber';
import { createOrUpdateProject } from 'src/Mutation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import SpecficationItem from 'src/pages/project/components/digital-rfq-page/SpecficationItem';
import styles from './DigitalRfqReview.module.less';
import useMetadataMap from './useMetadataMap';

function DigitalRfqReview({ project }) {
  const [sent, setSent] = useState(false);
  const primaryKpis = usePrimaryKpis();
  const metadata = useMetaNumericalProperty();

  const {
    certOptions,
    colorOptions,
    processingMethodOptions,
    applicationOptions,
    sourceTypeOptions
  } = useMetadataMap();

  const properties = project?.digital_rfq_filter?.properties ?? {};
  const mappedKpiCodeMetadata = new Map(
    (metadata ?? []).map((item) => [item.code, item])
  );
  const secondaryKpis = Object.keys(
    (project?.digital_rfq_filter ?? project?.filters)?.kpis
  )
    .filter((kpi) => !primaryKpis.includes(kpi))
    .sort((a, b) => a.localeCompare(b));

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { mutate: updateProject, isLoading: isUpdating } = useMutation({
    mutationFn: createOrUpdateProject,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['projects']);
      navigate(`/project/source/${project.uuid}/digital-rfq`);
      setSent(true);
    },
    onError: (e) => {
      captureException(e);
      notification.error({
        message: 'Error',
        description: e?.errorFields?.at(0)?.errors
          ? e.errorFields.at(0).errors.at(0)
          : 'Something went wrong. Please try again.'
      });
    }
  });

  const handleUpdatingProject = async () => {
    try {
      const digital_rfq_filter = {
        ...(project?.digital_rfq_filter || {}),
        approved: true
      };
      const updatedData = {
        uuid: project?.uuid,
        digital_rfq_filter
      };
      updateProject(updatedData);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e?.errorFields?.at(0)?.errors
          ? e.errorFields.at(0).errors.at(0)
          : 'Something went wrong. Please try again.'
      });
    }
  };

  return (
    <div>
      <div className="mt-lg mb-xl">
        <Row className="mt-lg" justify="space-between" align="middle">
          <Col>
            <Typography.Title level={4} className="mb-xs">
              Review Digital RFQ
            </Typography.Title>
          </Col>
          <Col>
            {!sent && (
              <Button
                onClick={handleUpdatingProject}
                type="primary"
                htmlType="button"
                disabled={isUpdating}
                loading={isUpdating}
              >
                Approve Digital RFQ
              </Button>
            )}
          </Col>
        </Row>
        {sent && (
          <Alert
            className="mt-sm"
            showIcon
            description="Your Digital RFQ has been approved."
          />
        )}
        <Row>
          <Col span={18}>
            <Typography className={styles.generalInformation}>
              General Information
            </Typography>
            <div>
              <Typography className={styles.title}>Project Name</Typography>
              <Typography className={styles.description}>
                {project.name}
              </Typography>
            </div>
            <div className="mt">
              <Typography className={styles.title}>RFQ Deadline</Typography>
              <Typography className={styles.description}>
                {project.decision_date ?? 'None'}
              </Typography>
            </div>
            <div className="mt">
              <Typography className={styles.title}>
                Project Description
              </Typography>
              <Typography className={styles.description}>
                {project.description ? project.description : 'None'}
              </Typography>
            </div>
            <div className="mt">
              <Typography className={styles.title}>
                Primary Shipping Destination
              </Typography>
              <Typography className={styles.description}>
                {project.locations && project.locations.length
                  ? project.locations.at(0)?.country
                  : 'None'}
              </Typography>
            </div>
            <div className="mt">
              <Typography className={styles.title}>
                Additional Shipping Details
              </Typography>
              <Typography className={styles.description}>
                {project.additional_shipping_details
                  ? project.additional_shipping_details
                  : 'None'}
              </Typography>
            </div>
            <div className="mt">
              <Typography className={styles.title}>Quantity</Typography>
              <Typography className={styles.description}>
                {project.capacity
                  ? `${prettyNumberRound(project.capacity)} ${
                      project.capacity_units
                    }`
                  : 'None'}
              </Typography>
            </div>
            <div className="mt">
              <Typography className={styles.title}>Pricing Target</Typography>
              <Typography className={styles.description}>
                {project.price
                  ? `${currencySymbolMap[project.currency]} ${prettyNumberPrice(
                      project.price
                    )} / ${project.price_weight_units}`
                  : 'None'}
              </Typography>
            </div>
            <div className="mt">
              <Typography className={styles.title}>Pricing Terms</Typography>
              <Typography className={styles.description}>
                {project.pricing_terms ?? 'None'}
              </Typography>
            </div>
            {primaryKpis.length > 0 ? (
              <div>
                <Typography
                  className={classNames('mt-xxl text', pageStyles.primaryText)}
                >
                  Primary Specifications
                </Typography>
                <Typography className={pageStyles.secondaryText}>
                  Imported from Explore
                </Typography>
                <Row className="mt-lg">
                  <Col span={9}>
                    <Typography className={pageStyles.propertyTitle}>
                      Property
                    </Typography>
                  </Col>
                  <Col span={3}>
                    <Typography className={pageStyles.propertyTitle}>
                      Min.
                    </Typography>
                  </Col>
                  <Col span={3}>
                    <Typography className={pageStyles.propertyTitle}>
                      Max.
                    </Typography>
                  </Col>
                  <Col span={3}>
                    <Typography className={pageStyles.propertyTitle}>
                      Units.
                    </Typography>
                  </Col>
                  <Col span={6}>
                    <Typography className={pageStyles.propertyTitle}>
                      Testing Method
                    </Typography>
                  </Col>
                </Row>
                {primaryKpis.map((kpi) => {
                  const kpiObj = project.digital_rfq_filter?.kpis[kpi];
                  const kpiMetadata = mappedKpiCodeMetadata.get(kpi);
                  if (!kpiObj) return null;
                  return (
                    <SpecficationItem
                      property={kpiMetadata?.name}
                      min={kpiObj?.min}
                      max={kpiObj?.max}
                      unit={kpiObj?.units}
                      method={kpiObj?.test_conditions}
                      key={kpi}
                    />
                  );
                })}
              </div>
            ) : null}
            {secondaryKpis.length > 0 ? (
              <>
                <Typography
                  className={classNames('mt-xxl text', pageStyles.primaryText)}
                >
                  Secondary Specifications
                </Typography>
                <Typography className={pageStyles.secondaryText}>
                  Imported from Explore
                </Typography>
                {secondaryKpis.map((kpi) => {
                  const kpiObj = project.digital_rfq_filter?.kpis[kpi];
                  const kpiMetadata = mappedKpiCodeMetadata.get(kpi);
                  if (!kpiObj) return null;
                  return (
                    <SpecficationItem
                      key={kpi}
                      property={kpiMetadata?.name}
                      min={kpiObj?.min}
                      max={kpiObj?.max}
                      unit={kpiObj?.units}
                      method={kpiObj?.test_conditions}
                    />
                  );
                })}
              </>
            ) : null}
            <Typography
              className={classNames('mt-xxl text mb-0', pageStyles.primaryText)}
            >
              Additional Information
            </Typography>
            <div className="mt-lg">
              <Typography className={styles.title}>
                Required Certifications
              </Typography>
              <Typography className={styles.description}>
                {properties[METADATA_PROPERTY_TYPE.CERTIFICATIONS] &&
                properties[METADATA_PROPERTY_TYPE.CERTIFICATIONS].length > 0
                  ? properties[METADATA_PROPERTY_TYPE.CERTIFICATIONS]
                      .map((code) => certOptions.get(code))
                      .join(', ')
                  : 'None'}{' '}
              </Typography>
            </div>
            <div className="mt-lg">
              <Typography className={styles.title}>Color</Typography>
              <Typography className={styles.description}>
                {properties[METADATA_PROPERTY_TYPE.COLOR] &&
                properties[METADATA_PROPERTY_TYPE.COLOR].length > 0
                  ? properties[METADATA_PROPERTY_TYPE.COLOR]
                      .map((code) => colorOptions.get(code))
                      .join(', ')
                  : 'None'}{' '}
              </Typography>
            </div>
            <div className="mt-lg">
              <Typography className={styles.title}>
                Processing Method
              </Typography>
              <Typography className={styles.description}>
                {properties[METADATA_PROPERTY_TYPE.PROCESSING_METHOD] &&
                properties[METADATA_PROPERTY_TYPE.PROCESSING_METHOD].length > 0
                  ? properties[METADATA_PROPERTY_TYPE.PROCESSING_METHOD]
                      .map((code) => processingMethodOptions.get(code))
                      .join(', ')
                  : 'None'}
              </Typography>
            </div>
            <div className="mt-lg">
              <Typography className={styles.title}>Application</Typography>
              <Typography className={styles.description}>
                {properties[METADATA_PROPERTY_TYPE.APPLICATION] &&
                properties[METADATA_PROPERTY_TYPE.APPLICATION].length > 0
                  ? properties[METADATA_PROPERTY_TYPE.APPLICATION]
                      .map((code) => applicationOptions.get(code))
                      .join(', ')
                  : 'None'}
              </Typography>
            </div>
            <div className="mt-lg">
              <Typography className={styles.title}>Ideal Sources</Typography>
              <Typography className={styles.description}>
                {properties[METADATA_PROPERTY_TYPE.SOURCE_TYPE] &&
                properties[METADATA_PROPERTY_TYPE.SOURCE_TYPE].length > 0
                  ? properties[METADATA_PROPERTY_TYPE.SOURCE_TYPE]
                      .map((code) => sourceTypeOptions.get(code))
                      .join(', ')
                  : 'None'}
              </Typography>
            </div>
            <div className="mt-lg">
              <Typography className={styles.title}>Other Info</Typography>
              <Typography className={styles.description}>
                {project.other_info ?? 'None'}
              </Typography>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

DigitalRfqReview.propTypes = {
  project: PropTypes.object.isRequired
};

export default DigitalRfqReview;
