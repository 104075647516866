import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Tooltip } from 'antd';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode
} from 'use-places-autocomplete';
import { formatAddressMultiline, formatAddressString } from 'src/utils/address';

const { Option } = AutoComplete;

// We have three data models for locations:
// 1. The google maps API data model
// 2. Our API data model
// 3. The model used by this control internally
// A significant part of this control is managing these
// data representations correctly

const getAddressComponent = (place, component, defaultToLongName) => {
  const comp = place?.address_components?.find(({ types }) =>
    types.some((t) => t === component)
  );
  return (
    (defaultToLongName
      ? comp?.long_name || comp?.short_name
      : comp?.short_name || comp?.long_name) || ''
  );
};

const getStreetAddress1 = (place) => {
  const streetAddress =
    getAddressComponent(place, 'street_address') ||
    getAddressComponent(place, 'street_one');
  if (streetAddress) return streetAddress;

  const street = getAddressComponent(place, 'route');
  const number = getAddressComponent(place, 'street_number');
  return street && `${number || ''} ${street}`.trim();
};

const getStreetAddress = (place) => {
  const streetAddress1 = getStreetAddress1(place);
  const streetAddress2 =
    getAddressComponent(place, 'street_two') ||
    getAddressComponent(place, 'administrative_area_level_3') ||
    getAddressComponent(place, 'sublocality');
  const parts = [];
  if (streetAddress1) parts.push(streetAddress1);
  if (streetAddress2) parts.push(streetAddress2);
  return parts.join(', ');
};

// Convert location object from Google API to Django structure
const parseInternalLocation = (internalLocation) => ({
  internal_location: internalLocation?.internalLocation,
  city: internalLocation?.city || internalLocation?.address,
  latitude: internalLocation?.lat,
  longitude: internalLocation?.lng,
  zip: internalLocation?.zip,
  country: internalLocation?.country,
  state: internalLocation?.state,
  street_one: internalLocation?.streetAddress
});

// Convert location object from Django structure to Google API
// we are not using street_two in the api model - we should remove that??
const parseApiLocation = (djangoLocation) => ({
  uuid: djangoLocation?.uuid,
  internalLocation: djangoLocation?.internal_location,
  address: djangoLocation?.city,
  lat: djangoLocation?.latitude,
  lng: djangoLocation?.longitude,
  zip: djangoLocation?.zip,
  country: djangoLocation?.country,
  state: djangoLocation?.state,
  city: djangoLocation?.city,
  streetAddress: djangoLocation?.street_one
    ? `${djangoLocation?.street_one}${
        (djangoLocation?.street_two && `, ${djangoLocation?.street_two}`) || ''
      }`
    : ''
});

const parseGoogleAddress = async (address, defaultToLongName) => {
  const geocode = await getGeocode({ address });
  const latlng = getLatLng(geocode[0]);
  const zip = getZipCode(geocode[0]);
  const placeId = geocode[0]?.place_id;
  const streetAddress = getStreetAddress(geocode[0]);
  const country = getAddressComponent(geocode[0], 'country', defaultToLongName);
  const state = getAddressComponent(geocode[0], 'administrative_area_level_1');
  const city =
    getAddressComponent(geocode[0], 'locality') ||
    getAddressComponent(geocode[0], 'administrative_area_level_2');
  return {
    address, // This needs to be raw address so we can display in input
    ...latlng,
    internalLocation: placeId,
    zip,
    country,
    streetAddress,
    state,
    placeId,
    city
  };
};

/**
 * Shape of the value of this component:
 * {
 *   address: <string of the address>
 *   lat: <latitude>
 *   lng: <longitude>
 *   streetAddress: <streetAddress>
 *   state: <state>
 *   city: <city>
 *   zip: <zipCode>
 *   country: <country>
 *   internalLocation: <google place id>
 * }
 */
function LocationInput({
  value: valueIn,
  onChange,
  placeholder,
  defaultToLongName,
  ...props
}) {
  const {
    suggestions: { data },
    setValue
  } = usePlacesAutocomplete({
    debounce: 300
  });

  const handleInput = (address) => {
    setValue(address);
    onChange({ address });
  };

  const handleSelect = async (address) => {
    try {
      const parsedAddress = await parseGoogleAddress(
        address,
        defaultToLongName
      );
      onChange(parsedAddress);
    } catch (ex) {
      window.console.error('Error calling google API');
      onChange({ address });
    }
  };

  return (
    <Tooltip title={formatAddressMultiline(valueIn)}>
      <AutoComplete
        placeholder={placeholder}
        value={formatAddressString(valueIn)}
        onChange={handleInput}
        onSelect={handleSelect}
        className="location-input"
        {...props}
      >
        {data.map((o) => (
          <Option key={o.place_id} value={o.description}>
            <span>{o.description}</span>
          </Option>
        ))}
      </AutoComplete>
    </Tooltip>
  );
}

LocationInput.propTypes = {
  value: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultToLongName: PropTypes.bool
};

export default LocationInput;
export {
  parseInternalLocation,
  parseApiLocation,
  getStreetAddress,
  getAddressComponent,
  parseGoogleAddress,
  formatAddressString as formatAddress,
  formatAddressMultiline
};
