import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

/* eslint-disable import/no-unresolved */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
/* eslint-enable import/no-unresolved */

import { ConditionalContent as ConditionalExploreContent } from 'src/components/project/explore/Content';

import 'src/pages/project/styles/Explore.less';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { useDebounce } from 'src/hooks/useDebounce';
import { MATERIAL_CLASS_MAPPED } from 'src/constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

ChartJS.defaults.font.family = 'Roboto';
ChartJS.defaults.font.size = 12;
ChartJS.defaults.color = '#63748D';
ChartJS.defaults.plugins.title.color = '#323640';
ChartJS.defaults.plugins.title.font.size = 18;
ChartJS.defaults.plugins.title.font.weight = '500';
ChartJS.defaults.plugins.title.align = 'start';
ChartJS.defaults.plugins.title.padding = 0;

export default function ProjectExplore() {
  const [exploreCtx, setExploreCtx] = useConciergeContextState(['explore']);

  const [searchParams, setSearchParams] = useSearchParams();

  const { activeProjectId: projectId, activeProject } = useSidebarContext();

  // Populate filters from activeProject
  useEffect(() => {
    if (activeProject) {
      const filters = {
        ...activeProject.filters,
        material_class: activeProject.filters?.material_class
          ? activeProject.filters.material_class
          : MATERIAL_CLASS_MAPPED[activeProject.filters?.form_code__in]
      };
      setExploreCtx({
        ...exploreCtx,
        filters,
        projectId: activeProject.uuid,
        projectSettings: activeProject.settings || {}
      });
    }
  }, [activeProject, projectId]);

  // Populate filters from URL
  const filtersFromURL = searchParams.get('filters');
  useEffect(() => {
    if (filtersFromURL) {
      const parsedFilters = JSON.parse(filtersFromURL);
      const filters = {
        ...parsedFilters,
        material_class: parsedFilters?.material_class
          ? parsedFilters?.material_class
          : MATERIAL_CLASS_MAPPED[parsedFilters?.form_code__in]
      };
      if (Object.keys(parsedFilters).length > 0)
        setExploreCtx({
          ...exploreCtx,
          filters
        });
    }
  }, []);

  // Store filters in URL for easy sharing
  const debouncedFilters = useDebounce(exploreCtx?.filters, 2000);

  useEffect(() => {
    if (debouncedFilters && Object.keys(debouncedFilters).length > 0) {
      setSearchParams((params) => {
        if (debouncedFilters)
          params.set('filters', JSON.stringify(debouncedFilters));

        return params;
      });
    }
  }, [debouncedFilters]);

  return (
    <div className="explore-page">
      <ConditionalExploreContent />
    </div>
  );
}
