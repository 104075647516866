import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import SidebarCascade, {
  ProjectStep,
  ProjectSubStep
} from 'src/components/layout/sidebar/SidebarCascade';
import ProjectSourceCascade from 'src/components/layout/sidebar/ProjectSourceCascade';
import { projectSteps } from 'src/components/project/source/helpers';

export default function ProjectSourceWorkflow({
  projectId: projectIdOrPlayground,
  project
}) {
  const { pushCascade, setActiveProjectId } = useSidebarContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const projectId =
    projectIdOrPlayground === 'playground' ? undefined : projectIdOrPlayground;

  const steps = useMemo(() => projectSteps(project), [project]);

  const selectItem = (item) => {
    navigate(`/project/source/${projectId}/${item.key}`);
  };

  const cascadeItems = useMemo(() => {
    const processSteps = (steps2, parentPath = '', isChild = false) =>
      steps2.map((s) => {
        const currentPath = `${parentPath}/${s.key}`;

        // Process child steps recursively if they exist
        const childSteps = s.steps
          ? processSteps(s.steps, parentPath, true)
          : null;

        return {
          component: isChild ? (
            <ProjectSubStep step={s} />
          ) : (
            <ProjectStep step={s} />
          ),
          key: s.key,
          active: pathname.includes(currentPath),
          next: true,
          steps: childSteps
        };
      });

    // Start processing the steps from the top level
    return processSteps(steps, `/${projectId}`, false);
  }, [steps, projectId, pathname]);

  useEffect(() => {
    setActiveProjectId(projectId);
  }, [projectId]);

  return (
    <SidebarCascade
      light
      title={project?.name}
      items={cascadeItems}
      selectItem={selectItem}
      back={{
        label: 'All Source Projects',
        action: () => pushCascade(<ProjectSourceCascade />, 'source')
      }}
    />
  );
}
ProjectSourceWorkflow.propTypes = {
  projectId: PropTypes.string
};
