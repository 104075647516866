import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Typography,
  Row,
  Col,
  Image,
  Flex,
  Carousel,
  Empty
} from 'antd';
import Spacer from 'src/components/common/Spacer';
import { ACCEPTED_EVENT_TYPES, SHIPMENT_STATUS } from 'src/constants/shipments';
import { DATETIME_FORMAT } from 'src/constants';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { updateShipment } from 'src/Mutation/shippment';
import styles from './ReviewShipmentModal.module.less';
import InitialSpotBuySection from './InitialSpotBuySection';
import RequestNewQASection from './RequestNewQASection';
import ShipmentStatusBadged from './ShipmentStatusBadged';

const DECLINED_TYPES = {
  INITIAL_A_SPOT_BUY: 'Initiate a Spot Buy',
  REQUEST_NEW_QA: 'Request New QA'
};

function ReviewShipmentModal({ open, onClose, refetchShipment, shipment }) {
  const [isDeclined, setIsDeclined] = useState(false);
  const [declinedType, setDeclinedType] = useState();
  const [isIncludingShipping, setIsIncludingShipping] = useState(false);

  const { mutate: updateShipmentData, isLoading } = useMutation({
    mutationFn: async (data) =>
      updateShipment(data.id, {
        event_type: data.eventType,
        event_date: data.eventDate
      }),
    onSuccess: async (resp) => {
      if (resp && resp.event_type === SHIPMENT_STATUS.DECLINED) {
        setIsDeclined(true);
      }
      await refetchShipment();
    }
  });

  const handleUpdateShipment = async (eventType) => {
    if (!ACCEPTED_EVENT_TYPES.includes(eventType)) return;
    await updateShipmentData({
      id: shipment.material_shipment.uuid,
      eventType,
      eventDate: dayjs().format(DATETIME_FORMAT.DATE)
    });
  };

  const onClickDecline = async () => {
    if (isDeclined) {
      setDeclinedType(DECLINED_TYPES.INITIAL_A_SPOT_BUY);
    }
    await handleUpdateShipment(SHIPMENT_STATUS.DECLINED);
  };

  const onClickApprove = async () => {
    if (isDeclined) {
      setDeclinedType(DECLINED_TYPES.REQUEST_NEW_QA);
      return;
    }

    await handleUpdateShipment(SHIPMENT_STATUS.APPROVED);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsDeclined(false);
    setDeclinedType();
    onClose();
    setIsIncludingShipping(false);
  };

  const onInitialSpotBuy = () => {
    handleCloseModal();
    refetchShipment();
  };

  const onRequestNewQA = () => {};

  const modalTitle = useMemo(() => {
    if (!isDeclined) {
      return 'Approve Shipment QA';
    }

    if (declinedType === DECLINED_TYPES.INITIAL_A_SPOT_BUY) {
      return isIncludingShipping
        ? 'Include Shipping Details'
        : 'Select a Supplier for a Spot Buy';
    }

    if (declinedType === DECLINED_TYPES.REQUEST_NEW_QA) {
      return 'Request New QA';
    }

    return 'How would you like to move forward with this shipment?';
  }, [isDeclined, declinedType, isIncludingShipping]);

  const isChangingStatus = isLoading && !isDeclined;

  return (
    <Modal
      title={modalTitle}
      open={open}
      onCancel={handleCloseModal}
      footer={false}
      width={535}
    >
      <Flex align="center" className="gap">
        <Typography.Text type="secondary">
          {shipment?.material_shipment?.name}
        </Typography.Text>
        {isDeclined && (
          <ShipmentStatusBadged status={SHIPMENT_STATUS.DECLINED} />
        )}
      </Flex>

      <Spacer size={24} />
      {!declinedType ? (
        <>
          {!isDeclined && (
            <>
              {shipment?.material_shipment?.qa_artifacts ? (
                <Carousel dots={{ className: styles.carouselDots }}>
                  {shipment?.material_shipment?.qa_artifacts?.map(
                    (artifact, index) => (
                      <div key={index}>
                        <Image
                          src={artifact?.url}
                          width="100%"
                          preview={false}
                        />
                      </div>
                    )
                  )}
                </Carousel>
              ) : (
                <Empty />
              )}
              <Spacer size={47} />
            </>
          )}
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Button
                loading={isChangingStatus}
                disabled={isChangingStatus}
                className={styles.manageButton}
                onClick={onClickDecline}
              >
                {isDeclined ? DECLINED_TYPES.INITIAL_A_SPOT_BUY : 'Decline'}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className={styles.manageButton}
                type="primary"
                onClick={onClickApprove}
                loading={isChangingStatus}
                disabled={isChangingStatus}
              >
                {isDeclined ? DECLINED_TYPES.REQUEST_NEW_QA : 'Approve'}
              </Button>
            </Col>
          </Row>
        </>
      ) : declinedType === DECLINED_TYPES.INITIAL_A_SPOT_BUY ? (
        <InitialSpotBuySection
          isIncludingShipping={isIncludingShipping}
          onChangeStep={() => setIsIncludingShipping((prev) => !prev)}
          onSubmit={onInitialSpotBuy}
          shipment={shipment}
        />
      ) : (
        <RequestNewQASection onSubmit={onRequestNewQA} />
      )}
    </Modal>
  );
}

ReviewShipmentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchShipment: PropTypes.func.isRequired,
  shipment: PropTypes.object
};

export default ReviewShipmentModal;
