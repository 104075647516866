export const SHIPMENT_STATUS = {
  APPROVED: 'approved',
  DECLINED: 'declined',
  REVIEW: 'review'
};

export const ACCEPTED_EVENT_TYPES = [
  SHIPMENT_STATUS.APPROVED,
  SHIPMENT_STATUS.DECLINED
];
