export const sourcesListNames = (tickers) =>
  [...new Set(tickers?.map((t) => t.source))].filter((t) => t).join(', ');

const sourceNamesMap = {
  opis: 'OPIS',
  spg: 'SPG',
  argus: 'Argus'
};

export const sourcesListNamesArray = (tickersArray) =>
  [
    ...new Set(
      tickersArray
        .map((t) => t?.map((s) => sourceNamesMap[s.source] || s.source))
        .filter((t) => t)
        .flat()
    )
  ].join(', ');

export const sourcesListDetails = (tickers) =>
  tickers
    .map((t) =>
      t
        ? `${t.source}: ${t.source_name} (${
            t.location?.city || t.location?.country
          })`
        : undefined
    )
    .filter((t) => t)
    .join('\n');

export const sourcesListDetailsArray = (tickersArray) =>
  tickersArray
    .map((p) => sourcesListDetails(p || []))
    .filter((t) => t)
    .join('\n---\n');

export const lineSpecs = [
  {
    type: 'pcrResin',
    title: (source) => `PCR Resin (${source})`,
    name: 'PCR Resin',
    color: '#722ED1',
    solid: true,
    useInAverage: true,
    filters: {
      form_code__in: ['resin_pellets'],
      virgin: false,
      circular: false
    },
    relevantFilters: [
      'type_code__in',
      'region_code__in',
      'grade_code__in',
      'country_code__in',
      'color_code__in'
    ],
    borderWidth: 1
  },
  {
    type: 'cohort',
    title: (source) => `Circular`,
    name: 'Circular',
    color: '#2F54EB',
    filters: { circular: true, form_code__in: ['resin_pellets'] },
    relevantFilters: ['type_code__in', 'region_code__in', 'grade_code__in'],
    solid: true,
    useInAverage: false,
    borderWidth: 1
  },
  {
    type: 'virgin',
    title: (source) => `Virgin (${source})`,
    name: 'Virgin',
    color: '#FA8C16',
    filters: { virgin: true, circular: false },
    relevantFilters: ['type_code__in', 'region_code__in', 'grade_code__in'],
    solid: false,
    useInAverage: true,
    borderWidth: 1
  },
  {
    type: 'flakes',
    title: (source) => `PCR Flakes (${source})`,
    name: 'PCR Flake',
    color: '#389E0D',
    filters: {
      form_code__in: ['regrind_flakes'],
      virgin: false,
      circular: false
    },
    relevantFilters: ['type_code__in', 'region_code__in', 'grade_code__in'],
    solid: false,
    useInAverage: true,
    borderWidth: 1
  },
  {
    type: 'bales',
    title: (source) => `Bales (${source})`,
    name: 'Bales',
    color: '#EB2F96',
    filters: { form_code__in: ['bales'], virgin: false, circular: false },
    relevantFilters: ['type_code__in', 'region_code__in', 'grade_code__in'],
    solid: false,
    useInAverage: true,
    borderWidth: 1
  },
  {
    type: 'tds',
    title: (source) => `Your Price`,
    name: 'Your Price',
    color: '#2F54EB',
    solid: false,
    borderWidth: 1
  }
];
