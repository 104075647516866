import React from 'react';

import SearchContent from 'src/components/project/search/Content';

import 'src/pages/project/styles/Search.less';

function ProjectQuotes() {
  return (
    <div className="search-page">
      <div className="content search-content">
        <SearchContent />
      </div>
    </div>
  );
}

export default ProjectQuotes;
