import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProjectManage from './ProjectManage';

export default function ManageRoutes() {
  return (
    <Routes>
      <Route path="/:projectId" element={<ProjectManage />} />
    </Routes>
  );
}
