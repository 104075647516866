import { Radio } from 'antd';
import React, { useMemo } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { MATERIAL_CLASS_MAPPED } from 'src/constants';
import { useFiltersPermissions } from 'src/utils/authentication';

const options = [
  {
    value: 'resin_pellets',
    label: 'Resin / Pellets'
  },
  { value: 'bales', label: 'Bales' },
  { value: 'regrind_flakes', label: 'Flakes' }
];

export default function FormFilter() {
  const [exploreFilters, setExploreFilters] = useConciergeContextState([
    'explore',
    'filters'
  ]);
  const filters = useFiltersPermissions('form_code__in');

  const isDisabled = (attribute) =>
    filters ? !filters.find((o) => o === attribute) : false;

  const filteredOptions = useMemo(
    () => options.map((o) => ({ ...o, disabled: isDisabled(o.value) })),
    [filters]
  );

  const onSelectMaterial = (value) => {
    const formValue = value.target.value;
    const correspondingMaterialClass = MATERIAL_CLASS_MAPPED[formValue];
    const newFilters = {
      ...exploreFilters,
      form_code__in: formValue,
      material_class: correspondingMaterialClass
    };
    setExploreFilters(newFilters);
  };

  return (
    <Radio.Group
      value={exploreFilters?.form_code__in}
      onChange={(value) => onSelectMaterial(value)}
      size="small"
      className="form-filter"
    >
      {filteredOptions.map((o) => (
        <Radio.Button disabled={o.disabled} key={o.value} value={o.value}>
          {o.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}
