import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LockFilled } from '@ant-design/icons';
import { PROJECT_STAGE } from 'src/constants/project';
import { useSidebarContext } from './SidebarContext';
import SidebarCascade from './SidebarCascade';

export default function ProjectManageCascade() {
  const { projects, clearCascades, setActiveProjectId } = useSidebarContext();

  const sourceProjects = useMemo(
    () =>
      (projects || [])
        .filter((p) => [PROJECT_STAGE.MANAGE].includes(p.stage))
        .map((p) => ({
          key: p.uuid,
          uuid: p.uuid,
          name: p.name || 'Project',
          icon: p.stage !== PROJECT_STAGE.MANAGE ? <LockFilled /> : undefined
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [projects]
  );

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onProjectSelectItem = (p) => {
    setActiveProjectId(p.key);
    navigate(`/project/manage/${p.key}`);
    clearCascades();
  };

  const cascadeItems = useMemo(
    () =>
      sourceProjects.map((project) => ({
        label: project.name,
        key: project.uuid || 'playground',
        active: pathname.includes(project.uuid || 'playground'),
        icon: project.icon,
        project,
        next: true
      })),
    [sourceProjects]
  );

  return (
    <SidebarCascade
      key="manage"
      title="Manage"
      items={cascadeItems}
      selectItem={onProjectSelectItem}
      allowSearch
    />
  );
}
