import React from 'react';
import Typography from 'antd/es/typography/Typography';
import Spacer from 'src/components/common/Spacer';
import { Form, Input } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { resetPassword } from 'src/Mutation';
import { handleTokenPair } from 'src/utils/authentication';
import { DASHBOARD_URL } from 'src/pages/auth/SignIn';
import parseApiError from 'src/utils/parseApiError';
import SignInLayout from './SignUpLayout';
import styles from './SignUpCompany.module.less';
import SignInButton from './components/SignUpButton';

function SignUpCreatePassword() {
  const [form] = Form.useForm();
  const [params] = useSearchParams();
  const token = params.get('token');
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: resetPassword,
    onSuccess: (response) => {
      handleTokenPair(response);
      const redirectLink = params.get('redirect');
      if (redirectLink) {
        navigate(redirectLink);
        return;
      }
      navigate(DASHBOARD_URL, { state: { reset: true } });
    },
    onError: (e) => {
      parseApiError(e, form);
    }
  });

  const onSubmit = (values) => {
    mutate({ token, password: values.password });
  };

  return (
    <SignInLayout>
      <div>
        <Typography.Title className={styles.whiteText} level={2}>
          Create a Password
        </Typography.Title>
        <Typography.Text className={styles.descriptionText}>
          Protect your account by creating a strong password{' '}
        </Typography.Text>
        <Spacer size={40} />
        <Form onFinish={onSubmit} form={form} layout="vertical">
          <Form.Item
            rules={[
              { required: true },
              { min: 8, message: 'Password must be at least 8 characters' },
              {
                validator: (_, value) =>
                  value && /\s/.test(value)
                    ? Promise.reject(
                        new Error('Password cannot contain whitespace')
                      )
                    : Promise.resolve()
              }
            ]}
            label="New Password"
            name="password"
            className="mb-0"
          >
            <Input.Password
              className={styles.companyInput}
              placeholder="New Password"
            />
          </Form.Item>
          <Spacer size={32} />
          <Form.Item>
            <SignInButton loading={isLoading} htmlType="submit">
              Set Password
            </SignInButton>
          </Form.Item>
        </Form>
      </div>
    </SignInLayout>
  );
}

export default SignUpCreatePassword;
