import dayjs from 'dayjs';

import prettyNumber from 'src/components/utils/prettyNumber';

export const compareProperties = (propertyValue1, propertyValue2) => {
  const property1 = propertyValue1?.property || propertyValue1?.meta_property;
  const property2 = propertyValue2?.property || propertyValue2?.meta_property;
  if (property1 && !property2) return -1;
  if (!property1 && property2) return 1;
  if (!property1 && !property2) return 0;
  if (property1.order && !property2.order) return -1;
  if (!property1.order && property2.order) return 1;
  if (!property1.order && !property2.order) return 0;
  return property1.order - property2.order;
};

export function formatQuantity(quantity, quantity_units, duration_months) {
  const quantityString = `${prettyNumber(quantity)} ${quantity_units}`;
  if (!duration_months) return quantityString;
  if (duration_months < 12) {
    const today = dayjs();
    const future = today.add(duration_months || 0, 'month');
    return `${quantityString} over ${today.to(future, true)}`;
  }
  const annualizedQuantity = (quantity / duration_months) * 12;
  return `${prettyNumber(annualizedQuantity)} ${quantity_units} / year`;
}
