const formTypeRegionKeys = [
  'form_code__in',
  'type_code__in',
  'region_code__in'
];

function filterFilters(filters, keysToKeep) {
  return Object.keys(filters)
    .filter((key) => keysToKeep.includes(key))
    .reduce((filteredObj, key) => {
      filteredObj[key] = filters[key]; // eslint-disable-line no-param-reassign
      return filteredObj;
    }, {});
}

export function filterToFormTypeRegionOnly(filters) {
  return filterFilters(filters, formTypeRegionKeys);
}

export function convertTdsToFilters(tds) {
  if (!tds) return {};
  const properties = (tds?.material_properties || []).reduce(
    (acc, property) => {
      const currentKey = property.meta_property.code;
      const currentValue = acc.value;
      if (currentValue) {
        acc[currentKey] = [...currentValue, property.value];
      } else {
        acc[currentKey] = [property.value];
      }
      return acc;
    },
    {}
  );

  const kpis = (tds?.material_numerical_properties || []).reduce((acc, kpi) => {
    acc[kpi.property.code] = {
      min: kpi.min,
      max: kpi.max
    };
    return acc;
  }, {});

  return {
    form_code__in: tds.form_code,
    type_code__in: tds.type_code,
    region_code__in: tds.region_code,
    properties,
    kpis
  };
}
