export const COMPONENT_TYPES = {
  TABS: 'TABS',
  SINGLE: 'SINGLE'
};
export const COMPONENT_KEYS = {
  LOCATIONS: 'locations',
  SPECIFICATIONS: 'specifications',
  SUPPLIERS: 'suppliers',
  MARKET: 'market'
};

// This configuration is used to determine the layout of the Explore page based on the material class. We will save it on the server for future use.
export const MaterialClassLayoutConfiguration = {
  regrind_flakes: {
    type: COMPONENT_TYPES.TABS,
    component: COMPONENT_KEYS.TABS,
    components: [
      COMPONENT_KEYS.SPECIFICATIONS,
      COMPONENT_KEYS.SUPPLIERS,
      COMPONENT_KEYS.MARKET
    ]
  },
  resin_pellets: {
    type: COMPONENT_TYPES.TABS,
    components: [
      COMPONENT_KEYS.SPECIFICATIONS,
      COMPONENT_KEYS.SUPPLIERS,
      COMPONENT_KEYS.MARKET
    ]
  },
  bales: {
    type: COMPONENT_TYPES.SINGLE,
    components: [COMPONENT_KEYS.LOCATIONS]
  }
};
