import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import Private from 'src/pages/auth/Private';
import Header from 'src/pages/home/Header';
import TechnicalDataSheetView from 'src/pages/tds';
import useIsConcierge from 'src/hooks/useIsConcierge';
import useIsDealPitch from 'src/hooks/useIsDealPitch';
import ConciergeContextProvider from 'src/components/concierge/ConciergeContext';
import SearchPage from 'src/pages/search/SearchPage';
import SearchProgressPage from 'src/pages/search/progress';
import SupplierExperience from 'src/pages/supplier/SupplierExperience';
import CircularIndices from 'src/pages/marketPrice/CircularIndices';
import CohortPrices from 'src/pages/marketPrice/CohortPrices';

import IntroduceContainer from 'src/pages/supplier/IntroducePage';
import DigitalRfqStepContainer from 'src/pages/supplier/components/digital-rfq-submit/DigitalRfqStepContainer';
import DigitalRfqEmailSummary from 'src/pages/supplier/digital-rfq-email-summary/DigitalRfqEmailSummary';
import MarketsPlayground from 'src/pages/insights/market/playground';
import AppLayoutSidebar from './AppLayoutSidebar';

function AppLayout() {
  const isConcierge = useIsConcierge();
  const isDealPitch = useIsDealPitch();

  useEffect(() => {
    if (isConcierge) document.body.classList.add('is-concierge');
    else document.body.classList.remove('is-concierge');

    if (isDealPitch) document.body.classList.add('is-deal-pitch');
    else document.body.classList.remove('is-deal-pitch');

    return () =>
      document.body.classList.remove('is-concierge', 'is-deal-pitch');
  }, [isConcierge, isDealPitch]);

  return (
    <Private>
      <ConciergeContextProvider>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Roboto'
            },
            components: {
              Slider: {
                railSize: 2,
                handleSize: 8,
                handleSizeHover: 10,
                handleLineWidthHover: 2
              }
            }
          }}
        >
          <Routes>
            <Route path="/project/*" element={<AppLayoutSidebar />} />
            <Route path="/supplier/*" element={<SupplierExperience />} />
            <Route
              path="/digital-rfq/:projectId/introduce"
              element={<IntroduceContainer />}
            />
            <Route
              path="/digital-rfq/:projectId/submit"
              element={<DigitalRfqStepContainer />}
            />
            <Route
              path="/digital-rfq/:projectId/:tdsId/email-summary"
              element={<DigitalRfqEmailSummary />}
            />
            <Route path="/marketprice/circular" element={<CircularIndices />} />
            <Route
              path="/concierge/markets-playground"
              element={<MarketsPlayground />}
            />
            <Route path="/marketprice/cohort" element={<CohortPrices />} />
            <Route path="/search/progress" element={<SearchProgressPage />} />
            <Route exact path="/search" element={<SearchPage />} />
            <Route
              path="*"
              element={
                <div className="app-layout">
                  <Header />
                  <div className="content">
                    <Routes>
                      <Route
                        path="/technical-data-sheet/:id"
                        element={<TechnicalDataSheetView />}
                      />
                    </Routes>
                  </div>
                </div>
              }
            />
          </Routes>
        </ConfigProvider>
      </ConciergeContextProvider>
    </Private>
  );
}

export default AppLayout;
