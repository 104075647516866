import React, { useMemo, useState } from 'react';
import {
  Button,
  Col,
  Empty,
  Flex,
  Input,
  Row,
  Skeleton,
  Table,
  Typography
} from 'antd';
import Spacer from 'src/components/common/Spacer';
import { SearchOutlined } from '@ant-design/icons';
import ChatIcon from 'src/components/icons/Chat';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getProject } from 'src/Query';
import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import { PROJECT_STAGE } from 'src/constants/project';
import useGetProjectShipment from 'src/Query/useGetProjectShipments';
import { ACCEPTED_EVENT_TYPES } from 'src/constants/shipments';
import { ChatBuilder } from 'src/components/project/source/ChatBuilder';
import { sortableColumnTitle } from 'src/components/SortableColumnTitle';
import styles from './ProjectManage.module.less';
import ReviewShipmentModal from './ReviewShipmentModal';
import ShipmentStatusBadged from './ShipmentStatusBadged';
import SupplierViewContactCard from './SupplierViewContactCard';

function ProjectManage() {
  const { projectId } = useParams();

  const [selectedShipment, setSelectedShipment] = useState();
  const [searchPreShipment, setSearchPreShipment] = useState('');
  const [searchPastShipment, setSearchPastShipment] = useState('');
  const [selectedConversationId, setSelectedConversationId] = useState();

  const { data: project, isLoading } = useQuery(
    ['project', projectId],
    () => getProject(projectId),
    { enabled: !!projectId }
  );

  const {
    data: projectShipments,
    isLoading: isLoadingShipments,
    refetch
  } = useGetProjectShipment(projectId);

  const { data: { results: projectTdss } = {} } = useGetWarehouseTdss({
    project: {
      id: projectId,
      status: [PROJECT_STAGE.MANAGE]
    },
    fields: ['company_name', 'region_code', 'region', 'tds_id', 'company_id']
  });

  const mappingProjectTdsWithTdsId = new Map(
    (projectTdss ?? []).map((projectTds) => [projectTds.tds_id, projectTds])
  );

  const getLatestEventType = (events = []) => {
    const latestEvent = events.sort((a, b) =>
      b.created_at.localeCompare(a.created_at)
    )[0];
    return latestEvent?.event_type || '';
  };

  const handleClickChatIcon = (tdsId) => {
    if (!tdsId) return;

    const intercomConversationId =
      mappingProjectTdsWithTdsId.get(tdsId)?.project_intercom_conversation_id;
    if (!intercomConversationId) return;

    setSelectedConversationId(intercomConversationId);
  };

  const { pastShipments, preShipments } = useMemo(() => {
    if (!projectShipments) return { pastShipments: [], preShipments: [] };

    const preShipmentData = projectShipments.filter((s) => {
      const latestEventType = getLatestEventType(s.material_shipment.events);
      return !ACCEPTED_EVENT_TYPES.includes(latestEventType);
    });
    const pastShipmentData = projectShipments.filter((s) => {
      const latestEventType = getLatestEventType(s.material_shipment.events);
      return ACCEPTED_EVENT_TYPES.includes(latestEventType);
    });
    return { pastShipments: pastShipmentData, preShipments: preShipmentData };
  }, [projectShipments]);

  const preShipmentColumns = [
    {
      title: sortableColumnTitle('Shipment', 'material_shipment.name'),
      key: 'shipment',
      render: (shipment) => (
        <Flex align="center" justify="space-between">
          <Typography>{shipment.material_shipment.name}</Typography>
          {(shipment.material_shipment.qa_artifacts &&
            shipment.material_shipment.qa_artifacts.length && (
              <Button
                onClick={() => setSelectedShipment(shipment)}
                type="primary"
              >
                Review
              </Button>
            )) ||
            undefined}
        </Flex>
      ),
      className: styles.shipmentColumn,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) =>
        a.material_shipment.name.localeCompare(b.material_shipment.name),
      width: '30%'
    }
  ];

  const pastShipmentColumns = [
    {
      title: sortableColumnTitle('Shipment', 'material_shipment.name'),
      key: 'shipment',
      render: (shipment) => (
        <Flex align="center" justify="space-between">
          <Typography>{shipment.material_shipment.name}</Typography>
          <ShipmentStatusBadged
            status={getLatestEventType(shipment.material_shipment.events)}
          />
        </Flex>
      ),
      className: styles.shipmentColumn,
      width: '30%',
      sorter: (a, b) =>
        a.material_shipment.name.localeCompare(b.material_shipment.name)
    }
  ];

  const columns = [
    {
      title: sortableColumnTitle(
        'Supplier',
        'material_shipment.tds.company.name'
      ),
      key: 'supplier',
      render: (shipment) =>
        mappingProjectTdsWithTdsId.get(shipment.material_shipment.tds)
          ?.company_name,
      sorter: (a, b) =>
        mappingProjectTdsWithTdsId
          .get(a.material_shipment.tds)
          ?.company_name.localeCompare(
            mappingProjectTdsWithTdsId.get(b.material_shipment.tds)
              ?.company_name
          ),
      width: '20%'
    },
    {
      title: sortableColumnTitle(
        'Planned Delivery Date',
        'planned_delivery_date'
      ),
      key: 'planned_delivery_date',
      sorter: (a, b) =>
        a.material_shipment.planned_delivery_date.localeCompare(
          b.material_shipment.planned_delivery_date
        ),
      render: (shipment) => shipment.material_shipment.planned_delivery_date,
      width: '30%'
    },
    {
      title: sortableColumnTitle('Approval Date', 'supplier'),
      key: 'supplier',
      sorter: (a, b) =>
        a.material_shipment.events[0]?.event_date.localeCompare(
          b.material_shipment.events[0]?.event_date
        ),
      render: (shipment) => shipment.material_shipment.events[0]?.event_date,
      width: '30%'
    },
    {
      title: '',
      render: (shipment) => (
        <Flex className="gap" justify="end">
          <Button
            onClick={() =>
              handleClickChatIcon(shipment?.material_shipment?.tds)
            }
            icon={<ChatIcon />}
          />
        </Flex>
      )
    }
  ];

  const pagination = {
    pageSize: 10,
    showSizeChanger: false
  };

  const uniqueProjectTds = useMemo(() => {
    if (!projectTdss || !projectTdss.length) return [];
    const includedCompanyId = new Map();
    return projectTdss.filter((tds) => {
      if (includedCompanyId.has(tds.company_id)) {
        return false;
      }
      includedCompanyId.set(tds.company_id, true);
      return true;
    });
  }, [projectTdss]);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <div className={styles.projectManageContainer}>
      <Typography.Title level={4}>Manage</Typography.Title>
      <Spacer size={37} />
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <div className={styles.cardWrapper}>
            <div className={styles.card}>
              <Typography.Text className="text-sm" type="secondary">
                Supplier
              </Typography.Text>
              <Spacer size={16} />
              <Flex className="gap" vertical>
                {uniqueProjectTds.length > 0 ? (
                  uniqueProjectTds.map((tds) => (
                    <SupplierViewContactCard
                      key={tds.tds_id}
                      projectTds={tds}
                    />
                  ))
                ) : (
                  <Empty />
                )}
              </Flex>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.cardWrapper}>
            <div className={styles.card}>
              <Typography.Text className="text-sm" type="secondary">
                Destination
              </Typography.Text>
              <Spacer size={16} />
              <Typography.Title level={2}>{project?.name}</Typography.Title>
              <Typography.Text>
                {`${project?.locations?.[0]?.street_one ?? ''} ${
                  project?.locations?.[0]?.city ?? ''
                } ${project?.locations?.[0]?.state ?? ''}, ${
                  project?.locations?.[0]?.country ?? ''
                }`}
              </Typography.Text>
            </div>
          </div>
        </Col>
      </Row>
      <Spacer size={37} />
      <Flex align="center" justify="space-between">
        <Typography.Title level={5}>Pre-Shipment Approvals</Typography.Title>
        <Flex align="center" className="gap-xs">
          <Input
            addonAfter={<SearchOutlined />}
            placeholder="Search Shipments"
            className={styles.searchShipments}
            onChange={(e) => setSearchPreShipment(e.target.value)}
            allowClear
          />
        </Flex>
      </Flex>
      <Spacer size={20} />
      <Table
        columns={[...preShipmentColumns, ...columns]}
        dataSource={preShipments.filter((s) =>
          s.material_shipment.name
            .toLowerCase()
            .includes(searchPreShipment.toLowerCase())
        )}
        rowSelection={{}}
        pagination={preShipments.length > 10 ? pagination : false}
        loading={isLoadingShipments}
        rowKey="uuid"
      />
      <Spacer size={37} />
      <Flex align="center" justify="space-between">
        <Typography.Title level={5}>Past Shipments</Typography.Title>
        <Flex align="center" className="gap-xs">
          <Input
            addonAfter={<SearchOutlined />}
            placeholder="Search Shipments"
            className={styles.searchShipments}
            onChange={(e) => setSearchPastShipment(e.target.value)}
            allowClear
          />
        </Flex>
      </Flex>
      <Spacer size={20} />
      <Table
        columns={[...pastShipmentColumns, ...columns]}
        dataSource={pastShipments.filter((s) =>
          s.material_shipment.name
            .toLowerCase()
            .includes(searchPastShipment.toLowerCase())
        )}
        rowSelection={{}}
        loading={isLoadingShipments}
        pagination={pastShipments.length > 10 ? pagination : false}
        rowKey="uuid"
      />
      <ReviewShipmentModal
        open={!!selectedShipment}
        onClose={() => setSelectedShipment(undefined)}
        shipment={selectedShipment}
        refetchShipment={refetch}
      />
      <Spacer size={200} />
      <ChatBuilder
        onClose={() => {
          setSelectedConversationId();
        }}
        conversationId={selectedConversationId}
        isOpen={!!selectedConversationId}
      />
    </div>
  );
}

export default ProjectManage;
