import { useQuery } from '@tanstack/react-query';
import { Descriptions, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import { getCompany } from 'src/Query';
import { formatAddressMultiline } from 'src/utils/address';

export default function SupplierDetails() {
  const { projectTdsId } = useParams();
  const {
    data: filteredRecords,
    isLoading: isLoadingFilteredRecords,
    queryKey
  } = useProcureTdss();

  const projectTds = useMemo(
    () =>
      filteredRecords?.results?.find(
        (tds) => tds.project_tds_id === Number(projectTdsId)
      ),
    [filteredRecords, projectTdsId]
  );
  const { data: company } = useQuery({
    queryFn: () => getCompany(projectTds?.company_id),
    queryKey: ['company', projectTds?.company_id]
  });
  return (
    <div className="supplier-details">
      <Typography.Paragraph className="lg heavy mb-md mt-0">
        Supplier Details
      </Typography.Paragraph>
      <Descriptions
        colon={false}
        layout="vertical"
        labelStyle={{ paddingBottom: 0 }}
        column={1}
        items={[
          {
            key: 'contact_name',
            label: 'Primary Contact',
            children: company?.contact_name
          },
          {
            key: 'email',
            label: 'Email',
            children: company?.email
          },
          {
            key: 'website',
            label: 'Website',
            children: (
              <a
                target="_blank"
                href="https://example.com"
                title="Supplier website"
              >
                {company?.website}
              </a>
            )
          },
          {
            key: 'phone',
            label: 'Phone',
            children: company?.phone_number
          },
          {
            key: 'address',
            label: 'Address',
            children: formatAddressMultiline(company?.locations[0])
          }
        ]}
      />
    </div>
  );
}
