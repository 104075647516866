import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { SHIPMENT_STATUS } from 'src/constants/shipments';
import styles from './ShipmentStatusBadged.module.less';

function ShipmentStatusBadged({ status }) {
  const statusClass = classNames(styles.badge, {
    [styles.approved]: status === SHIPMENT_STATUS.APPROVED,
    [styles.declined]: status === SHIPMENT_STATUS.DECLINED
  });

  return <div className={statusClass}>{status}</div>;
}

ShipmentStatusBadged.propTypes = {
  status: PropTypes.oneOf(Object.values(SHIPMENT_STATUS)).isRequired
};

export default ShipmentStatusBadged;
