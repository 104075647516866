import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getProjectContractTerms, getProjectTdsContractTerms } from '.';

export default function useGetProjectContractTerms(id) {
  return useQuery({
    queryKey: ['projectContractTerm', id],
    queryFn: () => getProjectContractTerms(id),
    enabled: !!id
  });
}

export function useGetProjectTdsContractTerms(project_tds_id) {
  return useQuery({
    queryKey: ['projectTdsContractTerm', project_tds_id],
    queryFn: () => getProjectTdsContractTerms(project_tds_id),
    enabled: !!project_tds_id
  });
}

export function useInvalidateGetProjectContractTerms(id) {
  const queryClient = useQueryClient();
  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ['projectContractTerm', id]
      }),
    [id]
  );
}
