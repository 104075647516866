import { Row, Col, Typography, Button, Alert } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParentSize } from '@visx/responsive';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProject } from 'src/Mutation';
import TemplateEditor from 'src/pages/project/TemplateEditor';
import 'src/pages/project/styles/TemplateEditor.less';

export default function EmailTemplatesRfq({ project, locked }) {
  const [lockedStatus, setLockedStatus] = useState(locked);
  const { parentRef, width } = useParentSize({
    initialSize: { width: 750 }
  });

  const queryClient = useQueryClient();
  const { mutate: updateProjectMutation, isLoading: updating } = useMutation({
    mutationFn: updateProject,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);
      setLockedStatus(true);
    },
    onError: (e) => {
      // TODO: handle this
    }
  });

  return (
    <div className="buyer-digital-rfq">
      <Row className="mt-lg" justify="space-between" align="middle">
        <Col>
          <Typography.Title level={4} className="mb-xs">
            Edit Email Templates
          </Typography.Title>
          <Typography.Paragraph>
            Confirm the email templates that will be used.
          </Typography.Paragraph>
        </Col>
        {!lockedStatus ? (
          <Col>
            <Button
              onClick={() =>
                updateProjectMutation({
                  uuid: project.uuid,
                  email_templates_approved: true
                })
              }
              type="primary"
              htmlType="button"
              disabled={updating}
            >
              {updating ? 'Approving...' : 'Approve email templates'}
            </Button>
          </Col>
        ) : null}
      </Row>
      <Row className="mb-lg" justify="space-between" align="middle">
        {lockedStatus ? (
          <Col span={24}>
            <Alert
              showIcon
              description="The email templates are approved and locked."
            />
          </Col>
        ) : null}
      </Row>
      {Object.keys(project.email_templates || {}).map((templateKey, index) => (
        <div className="template-container" ref={parentRef} key={index}>
          <Typography.Title level={5} className="mb-xs">
            {templateKey.toUpperCase()}
          </Typography.Title>
          <TemplateEditor
            project={project}
            templateKey={templateKey}
            locked={lockedStatus}
          ></TemplateEditor>
        </div>
      ))}
    </div>
  );
}
EmailTemplatesRfq.propTypes = {
  project: PropTypes.object,
  locked: PropTypes.bool
};
