import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignUpCompany from './SignUpCompany';
import SignUpOtp from './SignUpOtpCode';
import SignUpCreatePassword from './SignUpCreatePassword';

function SignInRoutes() {
  return (
    <Routes>
      <Route path="/company" element={<SignUpCompany />} />
      <Route path="/otp-code" element={<SignUpOtp />} />
      <Route path="/create-password" element={<SignUpCreatePassword />} />
    </Routes>
  );
}

export default SignInRoutes;
