import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Col, Flex, Form, Space, Row, Typography } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { createOrUpdateTdsTest } from 'src/Mutation';
import ContractTermsIcon from 'src/components/icons/ContractTerms';
import QuantityWithUnitsInput from 'src/components/form/QuantityWithUnitsInput';

import StickyHeader from 'src/components/layout/StickyHeader';
import { useInvalidateGetProjectTdsTest } from 'src/Query/useGetProjectTdsTest';
import useRenderKpiRow from 'src/hooks/project/useRenderKpiRow';
import { getProjectTdsTdsTest } from 'src/Query';
import { ALL_PRIMARY_KPI_KEYS } from 'src/components/project/hooks/usePrimaryKpis';
import styles from './TestTds.module.less';

function TdsTestSupplier({ project }) {
  const { projectTdsId, projectId } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { renderKpiRow, mappedKpiCodeMetadata } = useRenderKpiRow('uuid');

  const { data: existingProjectTdsTest } = useQuery(
    ['project-tds-tests', projectTdsId],
    () => getProjectTdsTdsTest(projectTdsId)
  );

  const supplierName = existingProjectTdsTest?.tds?.company?.name;

  // When existing test loads, update form
  useEffect(() => {
    if (existingProjectTdsTest) {
      const formattedNumericalPropertyTests =
        existingProjectTdsTest.numerical_property_tests.reduce(
          (agg, testData) => ({
            ...agg,
            [testData.property.code]: {
              ...testData,
              min: testData.min_expected,
              max: testData.max_expected,
              methods: testData.test_standards
            }
          }),
          {}
        );
      form.setFieldsValue({
        ...existingProjectTdsTest,
        digital_rfq_filter: {
          kpis: {
            ...formattedNumericalPropertyTests
          }
        }
      });
    }
  }, [existingProjectTdsTest, form]);

  const invalidateParent = useInvalidateGetProjectTdsTest(projectId);
  const { mutateAsync: createTestTds, isLoading } = useMutation({
    mutationFn: (data) => createOrUpdateTdsTest(data),
    onSuccess: () => {
      navigate('..', { relative: 'path' });
      invalidateParent();
      queryClient.invalidateQueries(['project-tds-tests', projectTdsId]);
    }
  });
  const kpiKeys = Object.keys(
    (project?.digital_rfq_filter ?? project?.filters)?.kpis ?? {}
  );
  const secondaryKpis = kpiKeys
    .filter((kpi) => !ALL_PRIMARY_KPI_KEYS.includes(kpi))
    .sort((a, b) => a.localeCompare(b));

  const primaryKpis = kpiKeys
    .filter((kpi) => ALL_PRIMARY_KPI_KEYS.includes(kpi))
    .sort((a, b) => a.localeCompare(b));

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();

    const currentKpis = Object.keys(values?.digital_rfq_filter?.kpis ?? {});
    const numericalPropertyTests = currentKpis.map((kpiCode) => {
      const kpiData = values?.digital_rfq_filter?.kpis[kpiCode];
      const kpiMetadata = mappedKpiCodeMetadata.get(kpiCode);
      return {
        ...kpiData,
        property_id: kpiMetadata.uuid,
        min_expected: kpiData.min ?? null,
        max_expected: kpiData.max ?? null,
        units: kpiData.units ?? null,
        test_standards: kpiData.methods ?? null
      };
    });

    const updatedTestingData = {
      uuid: existingProjectTdsTest?.uuid,
      project_tds_id: projectTdsId,
      volume: values.volume,
      volume_units: values.volume_units,
      numerical_property_tests: numericalPropertyTests
    };

    return createTestTds(updatedTestingData);
  }, [projectTdsId, form, mappedKpiCodeMetadata, existingProjectTdsTest]);

  const handleCancel = () => {
    navigate('..', { relative: 'path' });
  };
  const digitalRfqDefaultValues = useMemo(
    () => project?.digital_rfq_filter ?? {},
    [project]
  );

  return (
    <>
      <StickyHeader height={48} style={{ margin: '0 -24px' }}>
        <Flex justify="space-between">
          <div>
            <ContractTermsIcon style={{ verticalAlign: '-0.4em' }} />{' '}
            <Typography.Text className="heavy poppins font-size-4 ml-xs">
              Test Details {supplierName ? `for ${supplierName}` : ''}
            </Typography.Text>
          </div>
          <div>
            <Space>
              <Button loading={isLoading} onClick={handleCancel}>
                Cancel
              </Button>
              <Button loading={isLoading} type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Space>
          </div>
        </Flex>
      </StickyHeader>
      <div className={styles.testTdsSupplier}>
        <Typography.Title className="mt-lg" level={4}>
          Testing Volume
        </Typography.Title>
        <Form
          form={form}
          initialValues={{
            digital_rfq_filter: digitalRfqDefaultValues
          }}
          layout="vertical"
        >
          <Flex className="gap-lg mt-lg" align="center">
            <Form.Item
              required
              rules={[
                {
                  validator: (_, value) =>
                    value && value.toString().trim() !== ''
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error('Please enter Lab Test Volume')
                        )
                }
              ]}
              name="volume"
              className="mb-0"
              label="Lab Test Volume"
            >
              <QuantityWithUnitsInput
                className={styles.quantityInput}
                unitsFieldName="volume_units"
              />
            </Form.Item>
          </Flex>
          {primaryKpis && primaryKpis.length ? (
            <>
              <Typography.Title level={5} className="mt-40">
                Primary Specifications
              </Typography.Title>
              <Typography.Text type="secondary">
                Imported from Explore
              </Typography.Text>
              <Row className="mt-lg">
                <Col span={9}>
                  <Typography className={styles.propertyTitle}>
                    Property
                  </Typography>
                </Col>
                <Col span={3}>
                  <Typography className={styles.propertyTitle}>Min.</Typography>
                </Col>
                <Col span={3}>
                  <Typography className={styles.propertyTitle}>Max.</Typography>
                </Col>
                <Col span={3}>
                  <Typography className={styles.propertyTitle}>
                    Units.
                  </Typography>
                </Col>
                <Col span={6}>
                  <Typography className={styles.propertyTitle}>
                    Testing Method
                  </Typography>
                </Col>
              </Row>
              {primaryKpis.map((kpiCode) => renderKpiRow(kpiCode))}
            </>
          ) : null}
          {secondaryKpis && secondaryKpis.length > 0 ? (
            <>
              <Typography.Title level={5} className="mt-40">
                Secondary Specifications
              </Typography.Title>
              <Typography.Text type="secondary">
                Imported from RFQ
              </Typography.Text>
              {secondaryKpis.map((kpiCode) => renderKpiRow(kpiCode, true))}
            </>
          ) : null}
        </Form>
      </div>
    </>
  );
}

export default TdsTestSupplier;
