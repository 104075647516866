import { METADATA_PROPERTY_TYPE } from 'src/constants/project';
import useMetaProperty from 'src/hooks/useMetaProperty';

export default function useMetadataMap() {
  const metaProperties = useMetaProperty();
  const certOptions = new Map(
    metaProperties
      .find((item) => item.code === METADATA_PROPERTY_TYPE.CERTIFICATIONS)
      ?.options.map((item) => [item.code, item.value]) ?? []
  );

  const colorOptions = new Map(
    metaProperties
      .find((item) => item.code === METADATA_PROPERTY_TYPE.COLOR)
      ?.options.map((item) => [item.code, item.value]) ?? []
  );

  const processingMethodOptions = new Map(
    metaProperties
      .find((item) => item.code === METADATA_PROPERTY_TYPE.PROCESSING_METHOD)
      ?.options.map((item) => [item.code, item.value]) ?? []
  );

  const applicationOptions = new Map(
    metaProperties
      .find((item) => item.code === METADATA_PROPERTY_TYPE.APPLICATION)
      ?.options.map((item) => [item.code, item.value]) ?? []
  );
  const sourceTypeOptions = new Map(
    metaProperties
      .find((item) => item.code === METADATA_PROPERTY_TYPE.SOURCE_TYPE)
      ?.options.map((item) => [item.code, item.value]) ?? []
  );

  return {
    certOptions,
    colorOptions,
    processingMethodOptions,
    applicationOptions,
    sourceTypeOptions
  };
}
