import {
  CaretDownFilled,
  CaretUpFilled,
  CheckCircleFilled,
  MoreOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Col,
  Dropdown,
  Empty,
  Flex,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography
} from 'antd';
import React, { useMemo, useState } from 'react';

import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import { useManageTdsModal } from 'src/pages/supplier/useManageTds';
import useIsCompanyMember from 'src/hooks/useIsCompanyMember';
import { deleteTds } from 'src/Mutation';
import emptyTable from 'src/images/emptyTable.png';
import useIsConcierge from 'src/hooks/useIsConcierge';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { getMarketPriceLatestPost } from 'src/Query/marketPrice';
import { sortableColumnTitle } from 'src/components/SortableColumnTitle';
import { currencyValuePrice } from 'src/utils/currency';
import { LogPricingModalTrigger } from 'src/components/supplier/LogPricingModal';
import { getTdsName } from './utils';
import styles from './SupplierTdss.module.less';

export default function SupplierTdss({ companyId }) {
  const isCompanyMember = useIsCompanyMember(companyId);
  const isConcierge = useIsConcierge();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [search, setSearch] = useState();
  const [orderBy, setOrderBy] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10
  });

  const { data, isLoading } = useGetWarehouseTdss({
    company_or_distributor_id__in: [companyId],
    status__not_in: 'closed',
    properties: { grade: [], color: [] },
    search,
    is_capability: false,
    source__in: ['supplier_direct'],
    fields: [
      'sku',
      'tds_id',
      'type',
      'type_code',
      'form',
      'form_code',
      'location_id',
      'city',
      'state',
      'country',
      'capacity_lbs',
      'supply_capacity_lbs',
      'region_code',
      'region',
      'price_per_lb'
    ],
    agg_fields: {
      document: ['url']
    },
    augment_prices: {},
    kpis: {
      D: { allow_null: true },
      MFI: { allow_null: true }
    },
    ...pagination,
    ...(orderBy ? { order_by: orderBy } : {})
  });

  const [tdsBeingEdited, setTdsBeingEdited] = useState();
  const { ManageTdsModal, openFunc: openManageTdsModal } = useManageTdsModal({
    additionalTdsParams: {
      send_email: true
    },
    tdsId: tdsBeingEdited
  });

  const priceFilters = useMemo(() => {
    if (!data?.results) return [];
    const tdsData = data.results;

    // Filter out duplicate TDS by region, form, and type
    const filteredTdss = tdsData.reduce((acc, current) => {
      const existsTds = acc.find(
        (tds) =>
          tds.region_code === current.region_code &&
          acc.form_code === current.form_code &&
          acc.type_code === current.type_code
      );
      if (existsTds) {
        return acc;
      }
      return [...acc, current];
    }, []);

    return filteredTdss.map((tds) => ({
      type_code__in: [tds.type_code],
      form_code: tds.form_code,
      region_code__in: [tds.region_code],
      virgin: false,
      circular: true
    }));
  }, [data]);

  const { data: latestPrices = [] } = useQuery(
    ['market-price-latest', priceFilters],
    () => getMarketPriceLatestPost(priceFilters),
    {
      enabled: !!priceFilters.length
    }
  );
  const latestPricesMapped = new Map(
    latestPrices.map((price) => [
      `${price.type_code}-${price.form_code}-${price.region_code}`,
      price
    ])
  );

  const { mutate: deleteTdsMutation, isLoading: deletingTds } = useMutation({
    mutationFn: deleteTds,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['technical-data-sheet']
      });
      queryClient.invalidateQueries({
        queryKey: ['warehouse', 'technical-data-sheet']
      });
      queryClient.invalidateQueries({
        queryKey: ['price-log', { tds__company: companyId }]
      });
    }
  });

  const onClickTds = (tds) => {
    const filters = {
      form_code__in: tds.form_code,
      type_code__in: tds.type_code,
      region_code__in: tds.region_code
    };
    const urlParams = new URLSearchParams({
      filters: JSON.stringify(filters),
      tdsId: tds.tds_id,
      locationId: tds.location_id
    });
    navigate(`?${urlParams.toString()}`);
  };

  const calculatePriceDelta = (latestPrice, locationPriceLog) => {
    if (!latestPrice || !locationPriceLog?.price) return 0;
    const midPrice = (latestPrice.high + latestPrice.low) / 2;
    const locationPrice = Number.parseFloat(locationPriceLog.price);
    const percent = (midPrice / locationPrice - 1) * 100;
    // eslint-disable-next-line consistent-return
    return Number.parseInt(percent);
  };

  return (
    <>
      {ManageTdsModal}
      <Row
        justify="space-between"
        className="supplier-profile--materials-table--controls"
      >
        <Col>
          <Input
            placeholder="Search Product SKUs"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            addonAfter={<SearchOutlined />}
            className={styles.searchInput}
          />
        </Col>
        <Col>
          <Space direction="horizontal" size={8}></Space>
        </Col>
      </Row>
      <Table
        className="supplier-profile--materials-table"
        loading={isLoading}
        rowKey="id"
        columns={[
          {
            title: sortableColumnTitle('Name', 'sku'),
            sorter: true,
            dataIndex: 'sku',
            render: (_, tds) => {
              const validDocs = tds.document?.filter(
                (document) => !!document.url
              );
              return (
                <Row justify="space-between">
                  <Col>
                    <Typography.Text type="strong">
                      {getTdsName(tds)}
                    </Typography.Text>
                  </Col>
                  {isCompanyMember ? (
                    <Col className="ml-md">
                      <Dropdown
                        trigger="hover"
                        menu={{
                          items: [
                            {
                              key: 'view',
                              label: (
                                <button
                                  className="bare"
                                  type="button"
                                  onClick={() => onClickTds(tds)}
                                >
                                  View Product SKU
                                </button>
                              )
                            },
                            {
                              key: 'edit',
                              label: (
                                <button
                                  className="bare"
                                  type="button"
                                  onClick={() => {
                                    setTdsBeingEdited(tds.tds_id);
                                    openManageTdsModal();
                                  }}
                                >
                                  Edit Product SKU
                                </button>
                              )
                            },
                            {
                              key: 'pricing',
                              label: (
                                <LogPricingModalTrigger
                                  tdsLocationId={tds.location_id}
                                >
                                  Add Pricing Change
                                </LogPricingModalTrigger>
                              )
                            },
                            {
                              key: 'documents',
                              label: (
                                <a
                                  title="View Attached TDS"
                                  target="_blank"
                                  href={
                                    validDocs?.length
                                      ? `https://django-image-upload.s3.amazonaws.com/${validDocs[0].url}`
                                      : '#'
                                  }
                                >
                                  <button className="bare" type="button">
                                    View Attached TDS
                                  </button>
                                </a>
                              ),
                              disabled: !validDocs?.length
                            },
                            {
                              key: 'remove',
                              label: (
                                <Popconfirm
                                  title="Remove TDS"
                                  description={
                                    <Typography.Text>
                                      Are you sure you want to remove this TDS{' '}
                                      <Typography.Text strong>
                                        {getTdsName(tds)}
                                      </Typography.Text>{' '}
                                      from your profile?
                                    </Typography.Text>
                                  }
                                  onConfirm={() =>
                                    deleteTdsMutation(tds.tds_id)
                                  }
                                  okText="Remove TDS"
                                  okButtonProps={{ danger: true }}
                                  cancelText="Cancel"
                                >
                                  <button className="bare" type="button">
                                    Remove
                                  </button>
                                </Popconfirm>
                              ),
                              danger: true
                            }
                          ],
                          onClick: ({ domEvent }) => domEvent.stopPropagation()
                        }}
                      >
                        <MoreOutlined />
                      </Dropdown>
                    </Col>
                  ) : null}
                </Row>
              );
            },
            className: styles.firstColumn
          },
          {
            title: sortableColumnTitle('Material', 'type_code'),
            sorter: true,
            dataIndex: 'type',
            render: (typeCode) => typeCode || '(...processing TDS)'
          },

          {
            title: sortableColumnTitle('Form', 'form_code'),
            sorter: true,
            dataIndex: 'form'
          },
          {
            title: 'Food Grade',
            dataIndex: 'property_grade_code',
            render: (codes) =>
              (codes || []).find((code) => code === 'food_grade') ? (
                <CheckCircleFilled size={16} className={styles.foodGradeIcon} />
              ) : (
                ''
              ),
            align: 'center'
          },
          {
            title: 'Color',
            dataIndex: 'property_color_value',
            render: (color) => (color[0] ? color[0] : 'N/A')
          },
          {
            title: 'Price vs Cohort Avg.',
            render: (tds) => {
              const key = `${tds.type_code}-${tds.form_code}-${tds.region_code}`;
              const latestPrice = latestPricesMapped.get(key);
              const tdsPrice = tds.price;
              const updownPercent = calculatePriceDelta(latestPrice, tdsPrice);
              const isPositive = updownPercent >= 0;
              return tdsPrice?.price ? (
                <Flex className="gap">
                  <Typography>
                    {currencyValuePrice(
                      tdsPrice?.price,
                      tdsPrice?.currency,
                      tdsPrice?.units
                    )}
                  </Typography>
                  {Math.abs(updownPercent) ? (
                    <Typography
                      style={{ color: isPositive ? 'green' : 'red' }}
                      type="secondary"
                    >
                      {isPositive ? <CaretDownFilled /> : <CaretUpFilled />}{' '}
                      {`${Math.abs(updownPercent)}%`}
                    </Typography>
                  ) : null}
                </Flex>
              ) : (
                'N/A'
              );
            }
          }
        ]}
        dataSource={data?.results}
        pagination={{
          current: pagination.page,
          pageSize: pagination.page_size,
          total: data?.count,
          onChange: (page, page_size) => setPagination({ page, page_size }),
          showSizeChanger: true,
          pageSizeOptions: [10, 25],
          showTotal: (total, [from, to]) =>
            `Displaying items ${from} - ${to} of ${data?.count} total`
        }}
        onRow={(tds) => ({ onClick: () => onClickTds(tds) })}
        onChange={(_pagination, _filters, sorter) => {
          if (
            !['ascend', 'descend'].includes(sorter?.order) ||
            (!sorter.column.sortKey &&
              typeof sorter.column.dataIndex !== 'string')
          ) {
            setOrderBy(null);
          } else {
            setOrderBy(
              `${sorter.order === 'descend' ? '-' : ''}${
                sorter.column.sortKey || sorter.column.dataIndex
              }`
            );
          }
        }}
        rowSelection={false}
        locale={{
          emptyText: (
            <Empty
              className="material-table-empty"
              image={emptyTable}
              description=""
            >
              <Typography.Title level={4}>No Material Data</Typography.Title>
              <Typography.Paragraph>
                Add material data to appear in search and compare pricing with
                other suppliers.
              </Typography.Paragraph>
            </Empty>
          )
        }}
      />
    </>
  );
}

SupplierTdss.propTypes = {
  companyId: PropTypes.string
};
