import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import styles from './ReviewShipmentModal.module.less';

function RequestNewQASection({ onSubmit }) {
  const [selectedSupplier, setSelectedSupplier] = useState();

  return (
    <Button
      disabled={!selectedSupplier}
      className={styles.manageButton}
      onClick={onSubmit}
      type="primary"
    >
      Request New QA
    </Button>
  );
}

RequestNewQASection.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default RequestNewQASection;
