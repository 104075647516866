import { useMemo } from 'react';
import useMaterialClass from 'src/components/project/explore/hooks/useMaterialClass';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import { useSecondaryKpis } from 'src/components/project/hooks/useSecondaryKpis';

const DEFAULT_VALUE = {};
const EMPTY_ARRAY = [];

const materialClassFiltersMap = {
  plastic_resin: {
    filterList: [
      {
        primary: 'all',
        secondary: '2'
      },
      {
        primary: 'all',
        secondary: '1'
      },
      {
        primary: 'all',
        secondary: '0',
        overwrites: { capacity__gte: undefined }
      },
      {
        primary: '1',
        secondary: '2'
      },
      {
        primary: '1',
        secondary: '1'
      },
      {
        primary: '1',
        secondary: '0',
        overwrites: { capacity__gte: undefined }
      },
      {
        primary: '0',
        secondary: '2'
      },
      {
        primary: undefined,
        secondary: '1'
      }
    ],
    resultDetails: [
      {
        primary: 'full',
        secondary: 'full',
        verified: true
      },
      {
        primary: 'full',
        secondary: 'partial',
        verified: true
      },
      {
        primary: 'full',
        secondary: 'none',
        verified: true
      },
      {
        primary: 'partial',
        secondary: 'full',
        verified: true
      },
      {
        primary: 'partial',
        secondary: 'partial',
        verified: true
      },
      {
        primary: 'partial',
        secondary: 'none',
        verified: true
      },
      {
        primary: 'none',
        secondary: 'full',
        verified: true
      },
      {
        primary: 'none',
        secondary: 'partial',
        verified: true
      }
    ]
  },
  plastic_bales: {
    filterList: [
      {
        primary: '0',
        secondary: '3'
      },
      {
        primary: '0',
        secondary: '2'
      },
      {
        primary: '0',
        secondary: '1'
      },
      {
        primary: '0',
        secondary: '0',
        overwrites: { capacity__gte: undefined }
      },
      {
        primary: '0',
        secondary: '0',
        overwrites: { is_capability: true, capacity__gte: undefined }
      }
    ],
    resultDetails: [
      { verified: true },
      { verified: true },
      { verified: true },
      { verified: true },
      { verified: false }
    ]
  },
  plastic_flake: {
    filterList: [
      {
        primary: 'all',
        secondary: '2'
      },
      {
        primary: 'all',
        secondary: '1'
      },
      {
        primary: 'all',
        secondary: '0',
        overwrites: { capacity__gte: undefined }
      },
      {
        primary: '1',
        secondary: '2'
      },
      {
        primary: '1',
        secondary: '1'
      },
      {
        primary: '1',
        secondary: '0',
        overwrites: { capacity__gte: undefined }
      },
      {
        primary: '0',
        secondary: '2'
      },
      {
        primary: undefined,
        secondary: '1'
      }
    ],
    resultDetails: [
      {
        primary: 'full',
        secondary: 'full',
        verified: true
      },
      {
        primary: 'full',
        secondary: 'partial',
        verified: true
      },
      {
        primary: 'full',
        secondary: 'none',
        verified: true
      },
      {
        primary: 'partial',
        secondary: 'full',
        verified: true
      },
      {
        primary: 'partial',
        secondary: 'partial',
        verified: true
      },
      {
        primary: 'partial',
        secondary: 'none',
        verified: true
      },
      {
        primary: 'none',
        secondary: 'full',
        verified: true
      },
      {
        primary: 'none',
        secondary: 'partial',
        verified: true
      }
    ]
  }
};

const filtersAreEqual = (objToNullify, objToCompareAgainst) =>
  objToNullify &&
  objToCompareAgainst &&
  JSON.stringify(objToNullify) === JSON.stringify(objToCompareAgainst);

const removeDuplicateFilters = (list) => {
  list?.forEach((filter, index) => {
    if (
      list.slice(0, index).some((ancestor) => filtersAreEqual(filter, ancestor))
    ) {
      list[index] = null; // eslint-disable-line no-param-reassign
    }
  });
  return list;
};

/* eslint-disable no-param-reassign */
export default function useExpandedFiltersForSupplierList() {
  const materialClass = useMaterialClass();
  const filtersMap = materialClassFiltersMap[materialClass];

  const filters = useRelevantFiltersForForm() || DEFAULT_VALUE;
  const kpiFilters = filters.kpis || DEFAULT_VALUE;
  const propertyFilters = filters.properties || DEFAULT_VALUE;

  const primaryKpis = usePrimaryKpis();
  const secondaryKpis = useSecondaryKpis();

  const parsedFilterList = useMemo(
    () =>
      filtersMap?.filterList?.map(({ primary, secondary, overwrites }) => {
        const minPrimaryMatches =
          primary === 'all'
            ? primaryKpis.length
            : Math.min(Number(primary), primaryKpis.length);

        const minSecondaryMatches =
          secondary === 'all'
            ? secondaryKpis.length + Object.keys(propertyFilters).length
            : Math.min(
                Number(secondary),
                secondaryKpis.length + Object.keys(propertyFilters).length
              );

        return {
          ...(filters || {}),
          capacity__gte: filters?.capacity__gte,
          kpis: undefined,
          properties: undefined,
          kpis_properties: {
            kpis:
              Object.keys(kpiFilters).length === 0
                ? {}
                : {
                    ...primaryKpis.reduce((obj, kpiCode) => {
                      obj[kpiCode] = {
                        ...kpiFilters[kpiCode],
                        allow_null: false,
                        or_group:
                          primary !== undefined ? 'primary' : 'secondary'
                      };
                      return obj;
                    }, {}),

                    ...secondaryKpis.reduce((obj, kpiCode) => {
                      obj[kpiCode] = {
                        ...kpiFilters[kpiCode],
                        allow_null: false,
                        or_group: 'secondary'
                      };
                      return obj;
                    }, {})
                  },

            properties: Object.keys(propertyFilters).reduce(
              (obj, propertyCode) => {
                obj[propertyCode] = {
                  values: propertyFilters[propertyCode],
                  or_group: 'secondary'
                };
                return obj;
              },
              {}
            ),
            or_groups: {
              primary: {
                min_match: minPrimaryMatches
              },
              secondary: {
                min_match: minSecondaryMatches
              }
            }
          },
          kpi_operator: 'and',
          property_operator: 'and',
          ...overwrites
        };
      }),
    [
      filtersMap,
      filters,
      primaryKpis,
      secondaryKpis,
      kpiFilters,
      propertyFilters
    ]
  );

  return {
    filterList: removeDuplicateFilters(parsedFilterList) || EMPTY_ARRAY,
    resultDetails: filtersMap?.resultDetails
  };
}
