import { Flex, Popover, Typography } from 'antd';
import React, { useMemo } from 'react';
import useMetaPropertyOptionNameFromCode from 'src/hooks/useMetaPropertyOptionNameFromCode';
import { REGIONS } from 'src/pages/insights/dimensions';
import ProjectIcon from 'src/components/icons/Project';
import SafetyIcon from 'src/components/icons/Safety';
import { GlobalOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import FormIcon from 'src/components/icons/FormIcon';
import PropTypes from 'prop-types';
import TruckIcon from 'src/components/icons/TruckIcon';
import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import styles from './CohortSummaryRow.module.less';

export default function CohortSummaryRow({ cohort }) {
  const {
    food_grade: isFoodGrade,
    form: cohortForm,
    type: cohortType,
    regions,
    filters
  } = cohort ?? {};

  const { data } = useGetWarehouseTdss(
    {
      ...filters,
      all: 'true'
    },
    {}
  );

  const supplierCount = useMemo(() => {
    if (!data) return 0;
    const supplierIds = new Set(data.map((rTds) => rTds.company_id));
    return supplierIds.size;
  }, [data]);

  const type = useMetaPropertyOptionNameFromCode([cohortType]);
  const form = useMetaPropertyOptionNameFromCode([cohortForm]);
  const locations = REGIONS[regions]?.label ?? regions ?? '';

  return (
    <div className="cohort-description display mt-xl">
      <Flex justify="space-between" align="center">
        <Flex align="center">
          <Typography.Text className="heavy">Cohort:</Typography.Text>
          <Flex align="center" className="gap-xxs ml">
            <ProjectIcon className={styles.iconSize} />
            <Typography className={styles.iconText}>{type}</Typography>
          </Flex>
          <Flex align="center" className="gap-xxs ml">
            <GlobalOutlined className={styles.iconSize} />
            <Typography className={styles.iconText}>{locations}</Typography>
          </Flex>
          <Flex align="center" className="gap-xxs ml">
            <FormIcon className={styles.iconSize} />
            <Typography className={styles.iconText}>{form}</Typography>
          </Flex>
          <Flex align="center" className="gap-xxs ml">
            <SafetyIcon className={styles.iconSize} />
            <Typography className={styles.iconText}>
              {isFoodGrade ? 'Food Grade' : 'Non-Food Grade'}
            </Typography>
          </Flex>
          <Flex align="center" className="gap-xxs ml">
            <TruckIcon className={styles.iconSize} />
            <Typography className={styles.iconText}>
              {supplierCount} Suppliers
            </Typography>
          </Flex>
        </Flex>
        <Typography.Text className="how-does-it-work">
          <Popover
            content={
              <Typography.Text>
                The information you provide in response to this request for
                quote will be shared directly with the buyer. The price quote
                you provide will be considered relevant only for this specific
                opportunity.
                <br />
                <br />
                In addition, Circular.co will use your product specifications to
                match you to future opportunities with buyers on our platform.
                The more information you provide about the products you sell,
                the more likely you are to surface in search results.
              </Typography.Text>
            }
            title="How it works"
          >
            <QuestionCircleOutlined className="mr-xxs" />
            How does it work?
          </Popover>
        </Typography.Text>
      </Flex>
    </div>
  );
}

CohortSummaryRow.propTypes = {
  cohort: PropTypes.object
};
